import React, { useState, useEffect } from 'react';
import { Table, Select, Empty } from 'antd';
import { formatCurrency } from '../../utils/Utils'; // Ensure the import path is correct

const { Option } = Select;

interface MaterialData {
    materialID: string;
    materialName: string;
    allocatedBudget: number;
    spent: number;
    overrun_percentage: number;
    category: number;
}

interface BudgetOverRunData {
    siteName: string;
    siteID: string;
    currency: string;
    data: MaterialData[];
}

const BudgetOverRunWidget: React.FC = () => {
    const [data] = useState<BudgetOverRunData>({
        siteName: "DEF Site",
        siteID: "SITE123",
        currency: "INR",
        data: [
            { materialID: "1", materialName: "10mm TMT Rebars", allocatedBudget: 200000, spent: 210000, overrun_percentage: 5, category: 1 },
            { materialID: "2", materialName: "12mm TMT Rebars", allocatedBudget: 180000, spent: 198000, overrun_percentage: 10, category: 1 },
            { materialID: "3", materialName: "16mm TMT Rebars", allocatedBudget: 220000, spent: 242000, overrun_percentage: 10, category: 1 },
            { materialID: "4", materialName: "20mm TMT Rebars", allocatedBudget: 150000, spent: 165000, overrun_percentage: 10, category: 1 },
            { materialID: "5", materialName: "25mm TMT Rebars", allocatedBudget: 250000, spent: 275000, overrun_percentage: 10, category: 1 },
            { materialID: "6", materialName: "OPC 43 Grade Cement", allocatedBudget: 300000, spent: 330000, overrun_percentage: 10, category: 2 },
            { materialID: "7", materialName: "OPC 53 Grade Cement", allocatedBudget: 280000, spent: 308000, overrun_percentage: 10, category: 2 },
            { materialID: "8", materialName: "PPC (Portland Pozzolana Cement)", allocatedBudget: 260000, spent: 286000, overrun_percentage: 10, category: 2 },
            { materialID: "9", materialName: "PSC (Portland Slag Cement)", allocatedBudget: 240000, spent: 264000, overrun_percentage: 10, category: 2 },
            { materialID: "10", materialName: "White Cement", allocatedBudget: 220000, spent: 242000, overrun_percentage: 10, category: 2 },
            { materialID: "11", materialName: "1.5 sq mm Copper Wires", allocatedBudget: 100000, spent: 110000, overrun_percentage: 10, category: 3 },
            { materialID: "12", materialName: "2.5 sq mm Copper Wires", allocatedBudget: 120000, spent: 132000, overrun_percentage: 10, category: 3 },
            { materialID: "13", materialName: "4 sq mm Copper Wires", allocatedBudget: 140000, spent: 154000, overrun_percentage: 10, category: 3 },
            { materialID: "14", materialName: "6 sq mm Copper Wires", allocatedBudget: 160000, spent: 176000, overrun_percentage: 10, category: 3 },
            { materialID: "15", materialName: "16A Switches", allocatedBudget: 180000, spent: 198000, overrun_percentage: 10, category: 3 }
        ]
    });

    const [selectedCategory, setSelectedCategory] = useState<number>(1);

    useEffect(() => {
        // Set the first category as the default on initial load
        if (!selectedCategory) {
            setSelectedCategory(1); // Assuming the first category is "Steel" with ID 1
        }
    }, [selectedCategory]);

    // Filter data based on the selected category
    const filteredData = data.data.filter(item => item.category === selectedCategory);

    const columns = [
        {
            title: 'Material',
            dataIndex: 'materialName',
            key: 'materialName',
        },
        {
            title: 'Allocated Budget',
            dataIndex: 'allocatedBudget',
            key: 'allocatedBudget',
            render: (value: number) => formatCurrency(value, data.currency),
        },
        {
            title: 'Spent Value',
            dataIndex: 'spent',
            key: 'spent',
            render: (value: number) => formatCurrency(value, data.currency),
        },
        {
            title: 'Budget Overrun (%)',
            dataIndex: 'overrun_percentage',
            key: 'overrun_percentage',
            render: (value: number) => `${value}%`,
        },
    ];

    const handleCategoryChange = (value: number) => {
        setSelectedCategory(value);
    };

    return (
        <div>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Budget Overrun by Material</h6>
                    <p>Insights on materials exceeding their budget allocation. <a href='#'>View All</a></p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 8 }}>Filter by:</span>
                    <Select
                        placeholder="Select Category"
                        style={{ width: 200 }}
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        allowClear
                    >
                        <Option value={1}>Steel</Option>
                        <Option value={2}>Cement</Option>
                        <Option value={3}>Electricals</Option>
                        <Option value={4}>Test</Option>
                    </Select>
                </div>
            </div>
            {filteredData.length > 0 ? (
                <div className='table_large'>
                    <Table
                        columns={columns}
                        dataSource={filteredData}
                        rowKey="materialID"
                        pagination={false}

                        rowClassName={(record, index) => (index % 2 === 0 ? '' : 'striped-row')}
                    />
                </div>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <Empty description="No Data Available for the Selected Category" />
                </div>
            )}
        </div>
    );
};

export default BudgetOverRunWidget;
