import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
  Tooltip,
  Drawer,
  Modal,
  Form,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import statusMapper from "./StatusMapper";
import dispatchApis from "../../api/dispatch/dispatch";
import DispatchAside from "./paymentsNav";
import paymentApis from "../../api/payments/payments";
import { formatCurrency, formatCurrency2 } from "../../utils/Utils";
import usersApis from "../../api/master/users";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

const PendingPaymentApprovals: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [widgetData, setWidgetData] = useState<WidgetData[]>([
    {
      title: "Pending",
      count: 0,
      progress: 0,
      color: "#OO65BD",
    },
    {
      title: "Changes Requested",
      count: 0,
      progress: 0,
      color: "#20639B",
    },
    {
      title: "Buyer Accepted Changes",
      count: 0,
      progress: 0,
      color: "#FA991C",
    },
    {
      title: "Changes from Buyer",
      count: 0,
      progress: 0,
      color: "#F6D55C",
    },
    {
      title: "To be Invoiced",
      count: 0,
      progress: 0,
      color: "#OOCC99",
    },
  ]);
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState<any>(null);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [approverIds, setApproverIds] = useState<any>([]);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    viewChange();
    fetchCurrentUser();
    fetchData();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const columns: any = [
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Payment #",
      dataIndex: "paymentNumber",
      key: "PaymentNumber",
      width: 100,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 100,
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 180,
    },
    {
      title: "Payable Amt",
      dataIndex: "payableAmount",
      key: "payableAmount",
      width: 140,
      render: (text: any, record: any) => {
        return (
          <span>
            {formatCurrency2(Number(text), "INR")}
            <Tooltip title={formatCurrency(Number(text), "INR")}>
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 150,
      fixed: "right",
      render: (status: any) => {
        const _status = statusMapper[status]?.buyer;
        return (
          <div className="justify-start align-center">
            <h6
              className={`tb-status ms-1`}
              style={{
                color: `${_status?.color}`,
                fontSize: "14px",
              }}
            >
              {_status?.icon} {_status?.title || status}
            </h6>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 100,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setViewDrawer(true);
              fetchPaymentDetails(record?.paymentNumber);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];

  const fetchPaymentDetails = async (paymentNumber: any) => {
    try {
      const res = await paymentApis.fetchPayment(paymentNumber);
      const { data, status, message } = res;
      if (status) {
        setDrawerData(data);
        setApproverIds(
          data?.paymentApprovals
            ?.filter((item: any) => item?.status == "Pending")
            ?.map((item: any) => item?.userId)
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const drawerColumns: any = [
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 120,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 100,
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 200,
    },
    {
      title: "Payable Amt",
      dataIndex: "payableAmount",
      key: "payableAmount",
      width: 140,
      render: (text: any, record: any) => {
        return (
          <span>
            {formatCurrency2(Number(text), "INR")}
            <Tooltip title={formatCurrency(Number(text), "INR")}>
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
  ];

  const fetchData = async () => {
    try {
      const res = await paymentApis.fetchAllPendingApprovalPayments();
      const { data, status, message } = res;
      if (status) {
        setOriginalData(data);
        setFilteredData(data);
        setWidgetData((widgetArray: any) => {
          return widgetArray.map((widget: any) => {
            const statusCount = data.filter((item: any) => {
              const mappedStatus = statusMapper[item.orderStatus]?.buyer?.title;
              if (!mappedStatus) {
                return false;
              }
              return (
                mappedStatus?.toLowerCase() ==
                widget?.title?.trim()?.toLowerCase()
              );
            }).length;
            return {
              ...widget,
              count: statusCount,
              progress: statusCount
                ? ((statusCount / data?.length) * 100).toFixed(0)
                : 0,
            };
          });
        });
      } else {
        Swal.fire("Error", message, "error");
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const fetchCurrentUser = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        setCurrentUserId(data?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const approvePayment = async () => {
    try {
      const res = await paymentApis.approvePayment(drawerData?.paymentNumber);
      const { status, message } = res;
      if (status) {
        Swal.fire("Success", message, "success");
        fetchData();
      }
    } catch (error) {}
  };

  const disapprovePayment = async (reason: any) => {
    try {
      const res = await paymentApis.disapprovePayment(
        drawerData?.paymentNumber,
        reason
      );
      const { status, message } = res;
      if (status) {
        Swal.fire("Success", message, "success");
        fetchData();
      }
    } catch (error) {}
  };

  return (
    <>
      <Head title="Pending Payment Approvals - View" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <DispatchAside updateSm={setSm} sm={sm} />
            </div>
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">
                        Pending Payment Approvals
                      </BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>
                {originalData.length == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Empty description="No Pending Payment Approvals" />
                  </div>
                ) : (
                  <div>
                    {filteredData.length == 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "200px",
                        }}
                      >
                        <Empty description="No Data for selected filters" />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: screenSize - 330 + "px",
                          minHeight: "200px",
                          marginTop: "20px",
                        }}
                      >
                        <Table
                          className="customTable"
                          columns={columns.map((item: any) => ({
                            ...item,
                          }))}
                          dataSource={filteredData}
                          pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "20", "30", "50"],
                          }}
                          bordered
                          size="middle"
                          scroll={{ x: 1400 }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </Card>
      </Content>
      <Drawer
        title={
          <div className="d-flex justify-content-between align-items-center">
            <strong>Invoice Payables</strong>
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  setViewDrawer(false);
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Approve it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      approvePayment();
                    }
                  });
                }}
                color="primary"
                className="m-3 mt-0 mb-0"
              >
                Approve
              </Button>
              <Button
                onClick={() => {
                  setViewDrawer(false);
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Reject it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setShowRejectModal(true);
                    }
                  });
                }}
                color="light"
                className="m-3 mt-0 mb-0"
              >
                Reject
              </Button>
            </div>
          </div>
        }
        placement="right"
        onClose={() => setViewDrawer(false)}
        open={viewDrawer}
        width="85vw"
        style={{
          position: "absolute",
          zIndex: 1100,
        }}
        styles={{ body: { paddingBottom: 80 } }}
      >
        {drawerData ? (
          <>
            <div className="d-flex justify-content-between p-3 pb-0 pt-0">
              <div>
                <strong>Remark</strong>
                <p>{drawerData?.remarks}</p>
              </div>
              <div>
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <div className="justify-end">
                      <strong>Payable Amount Total &nbsp; &nbsp; </strong>
                    </div>
                    <strong className="fw-bold">
                      {formatCurrency(
                        Number(drawerData?.payableAmount || 0),
                        drawerData?.currencyCode || "INR"
                      )}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive poTable">
              <div
                style={{
                  width: screenSize - 300 + "px",
                  margin: "0px auto 15px auto",
                }}
              >
                <Table
                  className="customTable"
                  columns={drawerColumns}
                  dataSource={drawerData?.paymentLines}
                  scroll={{ x: 1000 }}
                  bordered
                  size="middle"
                  pagination={false}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Flex align="center" gap="middle" className="pageLoader">
              <Spin size="large" className="m-auto" />
            </Flex>
          </>
        )}
      </Drawer>
      <DisapprovePayment
        isModalVisible={showRejectModal}
        setIsModalVisible={setShowRejectModal}
        reject={disapprovePayment}
      />
    </>
  );
};

const DisapprovePayment = ({
  isModalVisible,
  setIsModalVisible,
  reject,
}: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        reject(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title="Reason for Rejection"
      open={isModalVisible}
      footer={null} // Remove the default footer buttons
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="rejectForm"
        requiredMark={false}
      >
        <Form.Item
          name="reason"
          label={undefined}
          rules={[{ required: true, message: "Please input your reason!" }]}
        >
          <Input.TextArea placeholder="Type your reason here..." />
        </Form.Item>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" onClick={handleOk}>
                Submit
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={handleCancel}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </Modal>
  );
};
export default PendingPaymentApprovals;
