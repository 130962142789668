import React, { useEffect, useState } from "react";
import { useFormContext, SubmitHandler } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import AddNewMaterial from './AddNewMaterial';
import { Table, Space } from 'antd';
import { EditOutlined, DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Icon } from "../../../components/Component";
import moment from "moment";
import { useFormData } from './FormDataContext'; // Import your context hook
import { Material, SelectOptions } from './types'; // Import your types
import { nanoid } from "nanoid";

const BudgetMaterials: React.FC<{ next: () => void, prev: () => void }> = (props: any) => {
  const { handleSubmit } = useFormContext();
  const { formData, updateFormData } = useFormData(); // Use the context to access formData and updateFormData
  const [dataSource, setDataSource] = useState<Material[]>(formData.materialsData || []); // Initialize with context data
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentMaterial, setCurrentMaterial] = useState<Material | null>(null);
  const [budgetHeader, setBudgetHeader] = useState<any>();

  const columns = [
    {
      title: 'Budget Line',
      dataIndex: 'budgetLineName',
      key: 'budgetLineName',
      fixed: 'left' as const,
      width: 120,
    },
    {
      title: 'Budget Line Description',
      dataIndex: 'lineDescription',
      key: 'lineDescription',
      // fixed: 'left' as const,
      width: 200,
    },
    {
      title: 'Budget Value',
      dataIndex: 'budgetValue',
      key: 'budgetValue',
      width: 150,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 200,
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Bill Of Material (BOM)',
      dataIndex: 'bom',
      key: 'bom',
    },
    {
      title: 'BOM Allocation',
      dataIndex: 'allocation',
      key: 'allocation',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right' as const,
      width: 100,
      render: (_: any, record: Material) => (
        <Space size="middle">
          <a onClick={() => openModal(record)}><EditOutlined /></a>
          <a onClick={() => handleDelete(record.key)} className="delete"><DeleteOutlined /></a>
        </Space>
      ),
    },
  ];

  const submitForm: SubmitHandler<any> = data => {
    // const submissionData = {
    //   ...formData,
    //   budgetLines: dataSource.map((material, index) => ({
    //     budgetLineName: formData.materialsData.budgetLineName,
    //     lineDescription: formData.materialsData.lineDescription,
    //     budgetValue:formData.materialsData.budgetValue,
    //     currencyCode: formData.materialsData.currencyCode,
    //     startDate: formData.materialsData.startDate,
    //     endDate: formData.materialsData.endDate,
    //     bom: formData.materialsData.bom,
    //     allocation: formData.materialsData.allocation,
    //     budgetId: nanoid(4),
    //     budgetLineId: formData.materialsData.budgetLineId, // Added delivery address ID
    //     bomLines: formData.materialsData.bomLines, // Added delivery address name
    //   })),
    //   docKeys: dataSource.map((material, index) => ({
    //     fileIndex: index + 1,
    //     module: "Module", // Placeholder, modify as needed
    //     moduleFileType: "FileType", // Placeholder, modify as needed
    //     level: "LINE_LEVEL",
    //     file: material.file, // Include the file itself
    //     fileName: material.fileName // Include the file name
    //   })),
    // };

    // updateFormData('budgetLines', submissionData.budgetLines);
    // updateFormData('docKeys', submissionData.docKeys);

    console.log("Materials Data:", formData);
    // return
    props.next();
  };

  useEffect(() => {
    console.log('Previous Data:', formData);
    setBudgetHeader(formData.budgetHeader);
  }, [formData]);

  useEffect(() => {
    setDataSource(formData.materialsData || []);
  }, [formData.materialsData]);

  const openModal = (material?: Material) => {
    setCurrentMaterial(material ?? null);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setCurrentMaterial(null); // Reset currentMaterial to null
    setIsModalVisible(false);
  };

  const handleAddOrEditMaterial = (material: Material) => {
    console.log('Added/Edited Material:', material);
    let updatedData;
    if (currentMaterial) {
      // Edit mode: Update existing material
      updatedData = dataSource.map(item => item.key === currentMaterial.key ? { ...material, key: currentMaterial.key } : item);
    } else {
      // Add mode: Add new material
      updatedData = [...dataSource, { ...material, key: `${Date.now()}` }];
    }
    setDataSource(updatedData);
    updateFormData('materialsData', updatedData); // Update context data
    closeModal();
  };

  const handleDelete = (key: string) => {
    const updatedData = dataSource.filter(item => item.key !== key);
    setDataSource(updatedData);
    updateFormData('materialsData', updatedData); // Update context data
  };

  const getFileIconType = (fileName: string) => {
    const extension = fileName.split('.').pop();
    switch (extension) {
      case 'pdf':
        return 'file-pdf';
      case 'doc':
      case 'docx':
        return 'file-word';
      case 'xls':
      case 'xlsx':
        return 'file-excel';
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'file-image';
      default:
        return 'file';
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <div style={{ overflow: 'auto' }}>
          <Table
            columns={columns}
            dataSource={dataSource}
            scroll={{ x: 'max-content' }} // Adjust this as necessary
            expandable={{
              expandedRowRender: (record: any) => (
                <div className="expandableBody">
                  <div style={{ flex: 1, textAlign: 'left' }}>
                    <p style={{ margin: 0 }}>{record.description}</p>
                    {record.fileName && (
                      <div>
                        <PaperClipOutlined /> &nbsp;
                        <a href={URL.createObjectURL(record.file)} download={record.fileName}>
                          {record.fileName}
                        </a>
                      </div>
                    )}
                  </div>
                  {budgetHeader?.deliveryLevelType === 'LINE_LEVEL' && record.deliveryAddressDetails && (
                    <div className="lineAddress" style={{ flex: 1 }}>
                      <h6>Delivery Address</h6>
                      <ul>
                        <li>{record.deliveryAddressDetails.addressLine1}</li>
                        <li>{record.deliveryAddressDetails.addressLine2}</li>
                        <li>{record.deliveryAddressDetails.city}, {record.deliveryAddressDetails.state}, {record.deliveryAddressDetails.country} - {record.deliveryAddressDetails.pincode}</li>
                      </ul>
                    </div>
                  )}
                </div>
              ),
            }}
            bordered
            size="small" // Making the table compact
            pagination={false}
            locale={{ emptyText: "Please add materials" }}
          />
          <span onClick={() => { openModal() }} style={{ cursor: 'pointer', marginTop: 10, display: 'block', fontSize: 14 }}>
            <Icon name="plus-round"></Icon> &nbsp; Add New Material
          </span>
        </div>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
            <li>
              <Button color="primary" onClick={props.prev} className="btn-dim btn btn-secondary">
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
      <AddNewMaterial
        modal={isModalVisible}
        closeModal={closeModal}
        addRow={handleAddOrEditMaterial}
        currentMaterial={currentMaterial}
        deliveryAddressLevel={budgetHeader?.deliveryLevelType}
      />
    </>
  );
};

export default React.memo(BudgetMaterials);
