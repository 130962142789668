import React, { useEffect, FC, useState, useCallback } from "react";
import { Modal, ModalBody, Form, Alert } from "reactstrap";
import { Icon, Button, RSelect } from "../../../components/Component";
import { Row, Col } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import supplierApis from "../../../api/master/supplier";
import FormSelect from "../../form-components/SelectComponent";
import FormInput from "../../form-components/InputComponent";
import FormTextArea from "../../form-components/TextAreaComponent";

const EditModal: FC<any> = ({
  modal,
  closeModal,
  onSubmit,
  formData,
  setFormData,
  filterStatus,
  alertInfo,
}) => {
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = methods;

  const [industryTypes, setIndustryTypes] = useState([]);

  const getContructionsMaterialTypes = useCallback(async () => {
    try {
      const matCats = await supplierApis.getContructionsMaterialTypes();
      console.log(matCats);
      if (matCats.status && matCats.data.length > 0) {
        const transformedData = matCats.data.map((item: any) => ({
          value: item.material_id.toString(),
          label: item.name,
        }));
        setIndustryTypes(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  }, []);

  // useEffect(() => {
  //   reset(formData);
  //   getContructionsMaterialTypes();
  //   console.log("Initial formData:", formData);
  // }, [formData, reset, getContructionsMaterialTypes]);

  const handleFormSubmit = (data: any) => {
    console.log("Form data on submit:", data);
    onSubmit(data);
  };
  useEffect(() => {
    console.log("formDataEDittt",formData)
    reset(formData);
    getContructionsMaterialTypes();
    setValue("referenceID", formData?.referenceID);
    setValue("materialID", formData?.materialID);
    setValue("category", formData?.category);
    setValue("description", formData?.description);
  }, [formData, reset, getContructionsMaterialTypes]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
      size="lg"
      backdrop="static"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Update Supplier Category</h5>
          {alertInfo.message && (
            <div className="mb-3 mt-1">
              <Alert
                color={alertInfo.type === "error" ? "danger" : "success"}
                className="alert-icon"
              >
                <strong>{alertInfo.message}</strong>
                <Icon
                  name={
                    alertInfo.type === "error" ? "alert-circle" : "check-circle"
                  }
                />
                {alertInfo.errors.length > 0 ? (
                  <ul>
                    {alertInfo.errors.map((err: any, index: any) => (
                      <li key={index}>{err}</li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </Alert>
            </div>
          )}
          <div className="mt-4">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col lg="4">
                    <FormInput
                      name="referenceID"
                      label="Internal ID"
                      required={true}
                      type="text"
                      placeholder="Enter Internal ID"
                      // disabled={action === "view"}
                    />
                  </Col>
                  <Col lg="4">
                    <FormSelect
                      options={industryTypes}
                      name="materialID"
                      label="Material Type"
                      required={true}
                      placeholder="Enter Material Type"
                      // type="text"
                      // disabled={action === "view"}
                    />
                  </Col>
                  <Col lg="4">
                    <FormInput
                      name="category"
                      label="Category Name"
                      required={true}
                      placeholder="Enter Category Name"
                      type="text"
                      // disabled={action === "view"}
                    />
                  </Col>
                  <Col md="12" className="mt-2">
                    <FormTextArea
                      name="description"
                      label="Description"
                      required={true}
                      maxLength={50}
                      placeholder="Enter Description"
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col size="12">
                    {" "}
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="md"
                          type="submit"
                          // onClick={() => handleFormSubmit(formData)}
                        >
                          Update
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            closeModal();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
                {/* )} */}
              </form>
            </FormProvider>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EditModal;