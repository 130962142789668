import React, { FC, useEffect, useState } from "react";
import { Row, Col, Button, Modal, ModalBody, Label } from "reactstrap";
import { Controller, useFormContext } from "react-hook-form";
import FormInput from "../form-components/InputComponent";
import FormSelect from "../form-components/SelectComponent";
import { Icon, TooltipComponent } from "../../components/Component";
import supplierApis from "../../api/master/supplier";
import costCenterApis from "../../api/master/costCenter";
import sites from "../../api/master/sites";
import FormDatePicker from "../form-components/DatePicker";
import GeneralLedgerApis from "../../api/master/generalLedger";
import departments from "../../api/master/departments";
import { Divider, Form, Select, Space, Popover } from "antd";
import DeliveryAddressMaster from "../masters/delivery-address/DeliveryAddressMaster";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import { EditOutlined } from "@ant-design/icons";
import QuotationApprovalDrawer from "./QuotationApprovalDrawer";
import rolesApi from "../../api/master/roles";
import usersApis from "../../api/master/users";
import budgetApis from "../../api/master/budget";
import dayjs from "dayjs";
interface SelectOptions {
  label: string;
  value: string;
}

const PurchaseOrder_GeneralDetails: FC = (props: any) => {
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();
  const [supplierOptions, setSupplierOptions] = useState<SelectOptions[]>([]);
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
    []
  );
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [generalLedgerOptions, setGeneralLedgerOptions] = useState<
    SelectOptions[]
  >([]);
  const [departmentOptions, setDepartmentOptions] = useState<SelectOptions[]>(
    []
  );
  const [budgetOptions, setBudgetOptions] = useState<SelectOptions[]>([]);
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const [popUpContent, setPopUpContent] = useState<JSX.Element | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [approvals, setApprovals] = useState({
    open: false,
    data: [],
  });
  const AllFormDetails = watch();
  const needByDateType = watch("needByDateType");
  const deliveryAddressId = watch("deliveryAddressId");
  const billingAddressId = watch("billingAddressId");
  const approvalRequired = watch("approvalRequired");
  const siteIdWatch = watch("siteId");

  useEffect(() => {
    fetchBudgetDetails(siteIdWatch);
  }, [siteIdWatch]);

  const submitForm = (data: any) => {
    setValue("approvalsData", JSON.stringify(approvals));
    if (approvalRequired == "true" && approvals?.data?.length === 0) {
      return;
    }
    console.log("data", data);
    props?.next();
  };

  const fetchSupplier = async () => {
    try {
      const res = await supplierApis.fetchSupplier();
      const { data, status } = res;
      if (status) {
        let supplierOptions = data.map((item: any) => ({
          label: item.supplierName,
          value: item.supplierOrgID,
        }));
        setSupplierOptions(supplierOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBudgetDetails = async (siteId:any) => {
    try {
      const {data,status} = await budgetApis.getBudgetBySite(siteId);
      
      
      if (status) {
        
        let budgetOptions = data.map((item: any) => ({
          label: item.budgetName,
          value: item.headerId,
        }));
        setBudgetOptions(budgetOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCostCenter = async () => {
    try {
      const { data, status } = await costCenterApis.fetchCostCenter();
      if (status) {
        let costCenterOptions = data.map((item: any) => ({
          label: item.costCenterName,
          value: item.id.toString(),
        }));
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser()
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGeneralLedger = async () => {
    try {
      const { data, status } = await GeneralLedgerApis.fetchLedger();
      if (status) {
        let generalLedgerOptions = data.map((item: any) => ({
          label: item.ledgerName,
          value: item.ledgerID.toString(),
        }));
        setGeneralLedgerOptions(generalLedgerOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDepartment = async () => {
    try {
      const { data, status } = await departments.fetchDeptData();
      if (status) {
        let departmentOptions = data.map((item: any) => ({
          label: item.deptName,
          value: item.deptID.toString(),
        }));
        setDepartmentOptions(departmentOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDeliveryAddress = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        let deliveryAddressOptions = data.map((item: any) => ({
          label: item.deliveryLocationName,
          value: item.id.toString(),
        }));
        setDeliveryAddressOptions(deliveryAddressOptions);
        setDeliveryAddressList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSupplier();
    // fetchBudgetDetails()
    fetchCostCenter();
    fetchSites();
    fetchGeneralLedger();
    fetchDepartment();
    fetchDeliveryAddress();
    const { approvalsData,autoPoDoc,needByDateType,deliveryAddressType,inwardRequired } = AllFormDetails;
    if (approvalsData) {
      setApprovals(JSON.parse(approvalsData));
    }
    if(autoPoDoc){
      setValue("autoPoDoc",autoPoDoc)
    }else{
      setValue("autoPoDoc","true")
    }
    if(needByDateType){
      setValue("needByDateType",needByDateType)
    }else{
      setValue("needByDateType","Order Level")
    }
    if(deliveryAddressType){
      setValue("deliveryAddressType",deliveryAddressType)
    }else{
      setValue("deliveryAddressType","Order Level")
    }
    if(inwardRequired){
      setValue("inwardRequired",inwardRequired)
    }else{
      setValue("inwardRequired","true")
    }
  }, []);

  const handleModelPopUp = (id?: any, type?: string) => {
    const content = (
      <DeliveryAddressMaster
        formAction={type}
        id={id}
        toggleModelPopUp={() => {
          setIsOpen(false);
          fetchDeliveryAddress();
        }}
      />
    );
    setPopUpContent(content);
    setIsOpen(true);
  };

  const ShowAddressPopOver = ({ id, text, options, matchKey }: any) => {
    if (!id) {
      return <></>;
    }

    let address = options?.find((item: any) => item[matchKey] == id);

    if (!address) {
      return <></>;
    }
    return (
      <Popover
        content={
          <ul>
            <li>
              {address.addressLine1} {address.addressLine2}
            </li>
            <li>
              {address.city},{address?.districtName}, {address?.stateName},
              {address?.countryName} - {address.pincode}
            </li>
          </ul>
        }
        title="Selected Address"
      >
        <a>{text}</a>
      </Popover>
    );
  };

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormSelect
              name="supplierId"
              label={`Supplier/Vendor${
                supplierOptions.length == 0 ? "( Empty Supplier List)" : ""
              }`}
              options={supplierOptions}
              required={true}
              placeholder="-Select-"
              tooltipText="Select the supplier or vendor for the purchase order."
            />
          </Col>
          <Col lg="8">
            <FormInput
              name="description"
              label="PO Description"
              required={true}
              type="text"
              minLength={2}
              maxLength={200}
              placeholder="Enter Description"
              tooltipText="Enter a brief description of the purchase order."
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col lg="4">
            <FormSelect
              name="costCenterId"
              label={`Cost Center${
                costCenterOptions.length == 0 ? "( Empty Cost Center List)" : ""
              }`}
              options={costCenterOptions}
              placeholder="-Select-"
              tooltipText="Select the cost center associated with this purchase order."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="siteId"
              label={`Construction Site${
                sitesOptions?.length == 0
                  ? "( Empty Construction Site List)"
                  : ""
              }`}
              options={sitesOptions}
              required={true}
              placeholder="-Select-"
              tooltipText="Select the construction site where the materials will be used."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="ledgerId"
              label={`General Ledger${
                generalLedgerOptions.length == 0
                  ? "( Empty General Ledger List)"
                  : ""
              }`}
              options={generalLedgerOptions}
              placeholder="-Select-"
              tooltipText="Select the general ledger account for financial tracking."
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col lg="4">
            <FormSelect
              name="departmentId"
              label={`Department`}
              options={departmentOptions}
              required={true}
              placeholder="-Select-"
              tooltipText="Select the department responsible for the purchase order."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="budgetId"
              label={`Budget Category`}
              options={budgetOptions}
              required={true}
              placeholder="-Select-"
              tooltipText="Select the budget category for this purchase order."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="inwardRequired"
              label="Inward Required ?"
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              required={true}
              placeholder="-Select-"
              tooltipText=" If GRN (Inward Process) is required for the line items then you can select 'Yes' otherwise select 'No'."
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col lg="4" style={{display:"none"}}>
            <FormSelect
              name="autoRelease"
              label="Auto Release ?"
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              // required={true}
              placeholder="-Select-"
              tooltipText="If you select Yes then, PO will be sent to the suppliers directly after approvals. If you select no then, PO will come back to the creator and the creator should release it manually to the supplier."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="autoPoDoc"
              label="Auto PO Document ?"
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              required={true}
              placeholder="-Select-"
              tooltipText='If you select "Yes" then, system-generated PO document will be sent to the supplier. But, if you select "No" then, you have to manually attach your  PO document.'
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="needByDateType"
              label="Multiple Delivery Dates ?"
              options={[
                { label: "Yes", value: "Line Level" },
                { label: "No", value: "Order Level" },
              ]}
              placeholder="-Select-"
              tooltipText="delivery date will be based on the line level. If you select 'No' then delivery date will be based on the Order Level."
              required={true}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="approvalRequired"
              label="Approval Required ?"
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              placeholder="Select"
              required={true}
            />
            <div style={{ display: "none" }}>
              <FormInput name="approvalsData" label="" type="text" />
            </div>
            {approvalRequired == "true" && (
              <Popover
                content="Click here to set required approvals"
                title="Approval Required"
              >
                <a
                  href="#"
                  className="success"
                  onClick={(e) => {
                    e.preventDefault();
                    setApprovals((pre) => {
                      return {
                        ...pre,
                        open: true,
                      };
                    });
                  }}
                >
                  <EditOutlined /> Set Approvals &nbsp;
                  {approvals?.data?.length == 0 ? (
                    <span className="mandatory">Please set approvals</span>
                  ) : (
                    <></>
                  )}
                </a>
              </Popover>
            )}
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          {needByDateType === "Order Level" && (
            <Col lg="4">
              <FormDatePicker
                name="needByDate"
                label="Need By Date"
                required={true}
                minDate={new Date()}
              />
            </Col>
          )}
          <Col lg="4">
            <FormSelect
              name="billingAddressId"
              label={`Billing Address${
                deliveryAddressOptions.length == 0
                  ? "( Empty Billing Address List)"
                  : ""
              }`}
              options={deliveryAddressOptions}
              popOverOptions={
                <ShowAddressPopOver
                  id={billingAddressId}
                  matchKey="id"
                  options={deliveryAddressList}
                  text="View Selected Billing Address"
                />
              }
              required={true}
              placeholder="-Select-"
              tooltipText="Select the billing address for the purchase order."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="deliveryAddressType"
              label={`Delivery Address Type`}
              options={[
                { label: "Order Level", value: "Order Level" },
                { label: "Line Level", value: "Line Level" },
              ]}
              required={true}
              placeholder="-Select-"
              tooltipText="Select the delivery address type for the purchase order."
            />
          </Col>
          {watch("deliveryAddressType") === "Order Level" && (
            <Col lg="4">
              <FormSelect
                name="deliveryAddressId"
                label="Delivery Address"
                options={deliveryAddressOptions}
                required={true}
                placeholder="-Select-"
                tooltipText="Select the delivery address for the purchase order."
                popOverOptions={
                  <ShowAddressPopOver
                    id={deliveryAddressId}
                    matchKey="id"
                    options={deliveryAddressList}
                    text="View Selected Delivery Address"
                  />
                }
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Button
                        type="button"
                        color="primary"
                        size="small"
                        onClick={() => {
                          handleModelPopUp(null, "");
                        }}
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <span>Add Address</span>
                      </Button>
                    </Space>
                  </>
                )}
              />
            </Col>
          )}
          <Col lg="4">
            <FormSelect
              name="paymentTerms"
              label="Payment Terms"
              options={[
                { label: "Cash & Carry", value: "Cash and Carry" },
                { label: "Advance", value: "Advance" },
                { label: "Credit", value: "Credit" },
              ]}
              required={true}
              placeholder="Select Payment Terms"
              tooltipText="Select the payment terms for the purchase order."
            />
          </Col>
          {watch("paymentTerms") === "Advance" && (
            <>
              <Col lg="4">
                <FormInput
                  name="advancePercentage"
                  label="Advance Percentage"
                  required={true}
                  type="number"
                  placeholder="Enter Advance Percentage"
                  tooltipText="Enter the advance percentage for the purchase order."
                  pattern={/^(0|[1-9][0-9]?|100)$/}
                  patternErrorMessage="Advance percentage should be between 1 and 100"
                />
              </Col>
              {watch("advancePercentage") < 100 && (
                <Col lg="4">
                  <FormInput
                    name="creditDays"
                    label="Credit Days"
                    required={true}
                    type="number"
                    placeholder="Enter Credit Days"
                    tooltipText="Enter the credit days for the purchase order."
                    pattern={/^[1-9][0-9]*$/}
                  />
                </Col>
              )}
            </>
          )}
          {watch("paymentTerms") === "Credit" && (
            <Col lg="4">
              <FormInput
                name="creditDays"
                label="Credit Days"
                required={true}
                type="number"
                placeholder="Enter Credit Days"
                tooltipText="Enter the credit days for the purchase order."
                pattern={/^[1-9][0-9]*$/}
              />
            </Col>
          )}
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
      {approvals.open && <QuotationApprovalDrawer
        open={approvals.open}
        siteId={siteIdWatch}
        onClose={() => {
          setApprovals((pre) => {
            return {
              ...pre,
              open: false,
            };
          });
        }}
        onSubmit={(data: any) => {
          setApprovals({
            open: false,
            data: data,
          });
        }}
        setApprovalSubmissionStatus={(status: boolean) => {}}
        approvalData={approvals.data} // Pass the current approval data
      />}
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        className="modal-dialog-centered"
        size="xl"
        backdrop="static"
      >
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setIsOpen(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <ModalBody>{popUpContent}</ModalBody>
      </Modal>
    </>
  );
};

export default PurchaseOrder_GeneralDetails;
