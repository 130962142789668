import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Badge, Card, Button as BButton } from "reactstrap";
import Head from "../../../layout/head/Head";
import DatePicker from "react-datepicker";
import { Modal, ModalBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  RSelect,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
// import { countryOptions, userData } from "./UserData";
import { getDateStructured } from "../../../utils/Utils";
import OrgProfileAside from "./OrgProfileAside";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import orgsApis from "../../../api/master/org";
import { FormProvider, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import misc from "../../../api/misc/dropDowns";
import CropperComponent from "../../components/CropperComponent";

const ViewOrgPage = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [data, setData] = useState<any>();
  const [modalTab, setModalTab] = useState("1");
  const [userInfo, setUserInfo]: any = useState("");
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    category: "",
  });
  const methods = useForm<any>({
    mode: "onChange",
    defaultValues: {
      name: "",
      type: "",
    },
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
  } = methods;
  const [docs, setDocs]: any = useState([]);
  const [modal, setModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [signatureModal, setSignatureModal] = useState(false);
  const toggleModal = () => setSignatureModal(!signatureModal);
  function base64ToFile(base64String: string, fileName: string) {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }
  const handleImageSave = async (image: string) => {
    try {
      const formData = new FormData();
      const deletedFileData: any = [];
      const imageFile = base64ToFile(image, "orgLogo.png");
      formData.append("files", imageFile);
      const previousDoc = docs.filter(
        (document: any) => document.moduleFileType == "org logo"
      );
      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: [
            {
              indexOfFileLocated: 0,
              module: "orgs",
              purpose: "org logo",
              level: "Header Level",
              uniqueId: "",
              action: "Create",
            },
          ],
          deletedFileData: deletedFileData,
          updatedFileData: [],
        })
      );
      formData.append("orgData", JSON.stringify({ name: data?.name }));

      toggleModal();
      const { status, message } = await orgsApis.updateOrgById(
        data.orgID,
        formData
      );
      if (status) {
        Swal.fire(
          "Updated!",
          "Organisation Details has been updated",
          "success"
        );
        setModal(false);
        fetchOrgData();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error: any) {
      Swal.fire("Failed!", error.response.data.message, "error");
    }
  };

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  const [selectedOrgType, setSelectedOrgType] = useState<any>({
    label: "-Select",
    value: "-Select",
  });

  const [orgTypeOptions, setOrgTypeOptions] = useState([]);

  const fetchOrgData = async () => {
    try {
      const res = await orgsApis.fetchOrg();
      const { data, status, message } = res;
      if (status) {
        if (!data) {
          Swal.fire("Error", "No Data Found", "error");
          return;
        }
        const newData = data;
        const logo = data?.docs?.filter(
          (document: any) => document.moduleFileType == "org logo"
        );
        console.log(logo);
        setCroppedImage(logo[0]?.filePath);
        setDocs(newData.docs);
        setData(newData);
        setFormData({
          name: newData.name,
          type: newData.type,
          category: newData.category,
        });
        setValue("name", newData.name);
        setValue("type", newData.type);
        setValue("category", newData.category?.id);
      } else {
        Swal.fire("Error", "Something went wrong", "error");
      }
    } catch (err) {
      Swal.fire("Error", "Something went wrong", "error");
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    fetchOrgData();
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  const openEditModal = () => {
    setModal(true);
    getOrgTypes();
  };

  const onSubmit = async (submitedData: any) => {
    delete submitedData.orgLogo;

    const formData = new FormData();
    formData.append("files", JSON.stringify([]));
    formData.append(
      "filesData",
      JSON.stringify({
        newfiledata: [],
        deletedFileData: [],
        updatedFileData: [],
      })
    );
    formData.append("orgData", JSON.stringify(submitedData));
    try {
      const { status, message } = await orgsApis.updateOrgById(
        data.orgID,
        formData
      );
      if (status) {
        Swal.fire(
          "Updated!",
          "Organisation Details has been updated",
          "success"
        );
        setModal(false);
        fetchOrgData();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error: any) {
      Swal.fire("Failed!", error.response.data.message, "error");
    }
  };

  const getOrgTypes = async () => {
    try {
      const orgTypes = await misc.getOygTypes();
      if (orgTypes.status && orgTypes.data.length > 0) {
        const transformedData = orgTypes.data.map((item: any) => ({
          value: item.id,
          label: item.orgtype,
        }));
        setOrgTypeOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  };

  return (
    <React.Fragment>
      <Head title="Org Profile"></Head>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <OrgProfileAside updateSm={updateSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => updateSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">General Information</BlockTitle>
                    <BlockDes>
                      <p>
                        Basic Organisation info...
                      </p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                      style={{ marginRight: "10px" }}
                      onClick={() => openEditModal()}
                    >
                      <Icon name="edit-alt-fill"></Icon>
                      <span>Edit</span>
                    </Button>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                {data && (
                  <>
                    <div className="nk-data data-list">
                      <div className="data-head">
                        <h6 className="overline-title">Basics</h6>
                      </div>
                      <div className="data-item">
                        <div className="data-col">
                          <span className="data-label">Organisation Name</span>
                          <span className="data-value">{data.name}</span>
                        </div>
                      </div>
                      <div className="data-item">
                        <div className="data-col">
                          <span className="data-label">Registered As</span>
                          <Badge color="primary" className="badge-sm badge">
                            {data.type}
                          </Badge>
                        </div>
                      </div>
                      <div className="data-item">
                        <div className="data-col">
                          <span className="data-label">Organisation Type</span>
                          <span className="data-value">
                            {data?.category?.name || "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className="data-item">
                        <div className="data-col">
                          <span className="data-label">Logo</span>
                          <span className="data-value">
                            <div className="nk-data data-list">
                              {!croppedImage && (
                                <BButton
                                  color="btn-dim btn"
                                  onClick={toggleModal}
                                >
                                  <span>Upload Logo </span>
                                  <Icon name="upload-cloud" />
                                </BButton>
                              )}
                              {croppedImage && (
                                <div className="mt-3 ">
                                  <div
                                    className="d-flex align-items-center"  
                                    style={{
                                      marginLeft: "25px",
                                      height: "60px",
                                      overflow: "hidden",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <img
                                      src={croppedImage}
                                      alt="Cropped"
                                      style={{ height: "60px" }}
                                    />
                                  </div>
                                  <div className="d-flex mt-3">
                                    <BButton
                                      color="btn-dim btn"
                                      onClick={toggleModal}
                                    >
                                      <span>Change</span>
                                      <Icon
                                        name="upload-cloud"
                                        className="pointer"
                                        onClick={toggleModal}
                                      />
                                    </BButton>
                                    {/* <BButton
                                color="btn-dim btn"
                                onClick={() => {
                                  setCroppedImage(null);
                                }}
                              >
                                <span>Cancel</span>
                                <Icon
                                  name="cross"
                                  className="pointer"
                                  onClick={() => {
                                    setCroppedImage(null);
                                  }}
                                />
                              </BButton> */}
                                  </div>
                                </div>
                              )}
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Block>
              <CropperComponent
                modal={signatureModal}
                toggle={toggleModal}
                onSave={handleImageSave}
              />

              <Modal
                isOpen={modal}
                className="modal-dialog-centered"
                size="lg"
                toggle={() => setModal(false)}
              >
                <a
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModal(false);
                  }}
                  className="close"
                >
                  <Icon name="cross-sm"></Icon>
                </a>
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="p-2">
                        <h5 className="title">Edit General Information</h5>

                        <div className="tab-content">
                          <div className="p-2">
                            <Row className="gy-4">
                              <Col md="6">
                                <div className="form-group">
                                  <FormInput
                                    name="name"
                                    label="Organisation Name"
                                    required={true}
                                    type="text"
                                    // defaultValue={data?.name}
                                    minLength={2}
                                    maxLength={30}
                                    placeholder="Enter Organisation name"
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="form-group">
                                  <FormSelect
                                    name="category"
                                    label="Organisation Type"
                                    options={orgTypeOptions}
                                    // defaultValue={orgTypeOptions.find(
                                    //   (o: any) => o.value == data?.category?.id
                                    // )}
                                    required={false}
                                    placeholder="-Select-"
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  {selectedOrgType && (
                                    <FormInput
                                      name="type"
                                      label="Registered As"
                                      required={true}
                                      disabled
                                      placeholder="-Select-"
                                    />
                                  )}
                                </div>
                              </Col>

                              <Row className="gy-4">
                                <Col size="12">
                                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                      <Button color="primary" size="lg">
                                        Update
                                      </Button>
                                    </li>
                                    <li>
                                      <a
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setModal(false);
                                        }}
                                        className="link link-light"
                                      >
                                        Cancel
                                      </a>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default ViewOrgPage;
