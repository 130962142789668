import makeApiCall from "./axiosIndex";
const budgetApis = {
  budgetCreationMethod: (data: any) =>
    makeApiCall({
      url: "/budget/createBudget",
      method: "post",
      data,
    }),
  getBudgetBySite: (siteId: number): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/budget/getbySite`,
      params: { site: siteId },
    }),
  fetchBudgetAll: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/budget/getAll",
    }),
  fetchBudgetById: (id: string): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/budget/id/${id}`,
    }),
    pendingApprovals: (): Promise<any> => makeApiCall<any>({
        method: 'get',
        url: `/budget/pendingApprovals`,
        // params: { status }
    }),
    approveBudget: (headerId: any): Promise<any> => makeApiCall<any>({
        method: 'patch',
        url: `/budget/approveBudget`,
        params: { headerId }
    }),
    rejectBudget: (headerId: any,data:any): Promise<any> => makeApiCall<any>({
        method: 'put',
        url: `/budget/rejectBudget`,
        data,
        params: { headerId }

    })
 
};

export default budgetApis;
