import axios from "axios";
import Swal from "sweetalert2";

const API_URL = process.env.REACT_APP_API_LOGIN_URL;
console.log(API_URL);

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.data && config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("Error intercepted:", error);
    if (error.response.status === 401) {
      window.location.href = "/";
      return error;
    } else if (error.response.status === 409) {
      return error.response;
    } else if (error.response.status === 400) {
      return error.response;
    }
    throw error?.response?.data;
  }
);

export default axiosInstance;
