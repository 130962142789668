import makeApiCall from "./axiosIndex";
const quotationApis = {
  quotationsCreationMethod: (data: any) =>
    makeApiCall({
      url: "/quotation-buyer/new",
      method: "post",
      data,
    }),
    newVersion: (data: any) =>
      makeApiCall({
        url: "/quotation-buyer/newVersion",
        method: "post",
        data,
      }),
  fetchList: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-buyer/list`,
    }),
  getById: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-buyer/getById`,
      params: { quotationId }
    }),
  fetchCounts: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-buyer/getCounts`,
    }),
  fetchPendingApprovals: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-buyer/pendingApprovals`,
    }),
  approve: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/quotation-buyer/approve`,
      params: { quotationId },
    }),
  reject: (quotationId: any, reason: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/quotation-buyer/reject`,
      params: { quotationId, reason },
    }),
  Summary: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/funnel`,
      params: { quotationId },
    }),
  versionGraph: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/versionGraph`,
      params: { quotationId },
    }),
  approvalDetilsEventChain: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-buyer/approvalsByQuotationId`,
      params: { quotationId },
    }),
  quoteComparision: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/quoteComparision`,
      params: { quotationId },
    }),
  addScenarioBuilder: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/quotation-buyer/addScenarioBuilder`,
      data,
    }),
  getScenarioBuilder: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-buyer/getScenarioBuilder`,
      params: { quotationId },
    }),
  dynamicRankings: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/dynamicRankings`,
      params: { quotationId },
    }),
  shortListSuppliers: (quotationId: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/quotation-seller/shortListSuppliers`,
      params: { quotationId },
      data,
    }),
  getShortListed: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/getShortListed`,
      params: { quotationId },
    }),
  sendToQCApproval: (data: any, quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/quotation-seller/sendToQCApproval`,
      params: { quotationId },
      data
    }),
  getQCList: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/getQCList`,
    }),
  getByQCId: (qcId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/getByQCId`,
      params: { qcId }
    }),
  qcApprove: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/quotation-seller/approveQC`,
      data
    }),
  qcReject: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/quotation-seller/rejectQC`,
      data
    }),
  editShortListed: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/quotation-seller/editShortListed`,
      data
    }),
  removeShortListed: (quotationId: any,id:any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/quotation-seller/removeShortListed`,
      params: { quotationId,id }
    }),
  getQCApprovedList: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/getQCApprovedList`,
    }),
  getQCApprovedById: (qcId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/getQCApprovedById`,
      params: { qcId }
    }),
  addTargetPrice: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/quotation-buyer/addTargetPrice`,
      data
    }),
  quotationTimeLine: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/quotationTimeLine`,
      params: { quotationId },
    }),
  latestByQuotationId: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/latestByQuotationId`,
      params: { quotationId },
    }),
  submitSupplierQuote: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/quotation-seller/quote",
      method: "post",
      data: data,
    }),
    fetchShortListBySupplierId: (quotationId: any,supplierId:any): Promise<any> =>
      makeApiCall<any>({
        method: "get",
        url: `/quotation-seller/fetchShortListBySupplierId`,
        params: { quotationId,supplierId }
      }),
      quotationDashboardCounts: (data: any): Promise<any> =>
        makeApiCall<any>({
          url: "/quotation-buyer/quotationDashboardCounts",
          method: "post",
          data: data,
        }),
        latestBySupplierQuotationId: (quotationId: any,supplierId:any): Promise<any> =>
          makeApiCall<any>({
            method: "get",
            url: `/quotation-seller/latestBySupplierQuotationId`,
            params: { quotationId,supplierId }
          }),
};

export default quotationApis;
