import React, { useState } from 'react';
import { Table, Avatar, Select, Empty } from 'antd'; // Added Empty import
import { getUserLabel } from '../../utils/Utils'; // Make sure the import path is correct

const { Option } = Select;

interface SupplierSavingsData {
    supplierID: string;
    supplierName: string;
    quoted: number;
    percentageOfSavings: number;
    category: string;
}

const TopSupplierSavingsWidget: React.FC = () => {
    const [data] = useState<SupplierSavingsData[]>([
        {
            supplierID: '1',
            supplierName: 'ABC Enterprises',
            quoted: 100,
            percentageOfSavings: 12,
            category: 'cat001'
        },
        {
            supplierID: '2',
            supplierName: 'DEF Enterprises',
            quoted: 20,
            percentageOfSavings: 12,
            category: 'cat001'
        },
        {
            supplierID: '3',
            supplierName: 'GHI Supplies',
            quoted: 150,
            percentageOfSavings: 10,
            category: 'cat002'
        },
        {
            supplierID: '4',
            supplierName: 'JKL Industries',
            quoted: 80,
            percentageOfSavings: 15,
            category: 'cat003'
        },
        {
            supplierID: '5',
            supplierName: 'MNO Traders',
            quoted: 200,
            percentageOfSavings: 8,
            category: 'cat004'
        }
    ]);

    const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);

    // Filtered data based on the selected category
    const filteredData = selectedCategory
        ? data.filter(item => item.category === selectedCategory)
        : data;

    // Define the columns for the table
    const columns = [
        {
            title: 'Supplier',
            dataIndex: 'supplierName',
            key: 'supplierName',
            render: (text: string) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        style={{
                            backgroundColor: '#E5E9F2',
                            color: '#526484',
                            marginRight: 8,
                            height: 40,
                            width: 40,
                            fontWeight: 'bold'
                        }}
                    >
                        {getUserLabel(text)}
                    </Avatar>
                    {text}
                </div>
            ),
        },
        {
            title: '# Quoted',
            dataIndex: 'quoted',
            key: 'quoted',
        },
        {
            title: 'Ang. Savings (%)',
            dataIndex: 'percentageOfSavings',
            key: 'percentageOfSavings',
            render: (text: number) => `${text}%`, // Append percentage symbol
        },
    ];

    const handleCategoryChange = (value: string) => {
        setSelectedCategory(value);
    };

    return (
        <div>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Top Supplier Savings</h6>
                    <p>Insights on savings by suppliers. <a href='#'>View All</a></p>
                </div>
                <div>
                    <span style={{ fontWeight: 'bold' }}>Filter by: </span>
                    <Select
                        placeholder="Select Category"
                        style={{ width: 200 }}
                        onChange={handleCategoryChange}
                        allowClear
                    >
                        {Array.from(new Set(data.map(item => item.category))).map(category => (
                            <Option key={category} value={category}>
                                {category}
                            </Option>
                            
                        ))}
                    </Select>
                </div>
            </div>
            {filteredData.length > 0 ? (
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    rowKey="supplierID"
                    pagination={false}
                    size='small'
                    rowClassName={(record, index) => index % 2 === 0 ? '' : 'striped-row'}
                />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <Empty description="No Data Available for the Selected Category" />
                </div>
            )}
        </div>
    );
};

export default TopSupplierSavingsWidget;
