import React from 'react';
import { Progress } from 'antd';
import { formatCurrency, colors } from '../../utils/Utils';
import * as echarts from 'echarts';
interface CategoryData {
    categoryID: string;
    category: string;
    amountSpent: number;
}

interface DataState {
    siteID: string;
    currency: string;
    data: CategoryData[];
}

const TopPurchaseCategoriesWidget: React.FC = () => {
    const [data, setData] = React.useState<DataState>({
        siteID: 'SITE123',
        currency: 'INR',
        data: [

            { categoryID: 'cat001', category: 'Steel', amountSpent: 125000 },
            { categoryID: 'cat002', category: 'Cement', amountSpent: 85000 },
            { categoryID: 'cat003', category: 'Concrete', amountSpent: 95000 },
            { categoryID: 'cat004', category: 'Bricks and Blocks', amountSpent: 60000 },
            { categoryID: 'cat005', category: 'Electrical', amountSpent: 70000 },
            { categoryID: 'cat006', category: 'Wood and Timber', amountSpent: 75000 },
            { categoryID: 'cat007', category: 'Glass and Glazing', amountSpent: 45000 },
            { categoryID: 'cat008', category: 'Plumbing and Piping', amountSpent: 58000 },
            { categoryID: 'cat009', category: 'HVAC (Heating, Ventilation, and Air Conditioning)', amountSpent: 92000 },
            { categoryID: 'cat010', category: 'Roofing', amountSpent: 65000 },
            { categoryID: 'cat011', category: 'Insulation', amountSpent: 48000 },
            { categoryID: 'cat012', category: 'Paints and Coatings', amountSpent: 52000 },
            { categoryID: 'cat013', category: 'Flooring', amountSpent: 61000 },
            { categoryID: 'cat014', category: 'Adhesives and Sealants', amountSpent: 42000 },
            { categoryID: 'cat015', category: 'Fasteners and Fixtures', amountSpent: 30000 },
            { categoryID: 'cat016', category: 'Safety and Protection', amountSpent: 37000 },
            { categoryID: 'cat017', category: 'Stone and Tile', amountSpent: 54000 },
            { categoryID: 'cat018', category: 'Drywall and Gypsum Board', amountSpent: 50000 },
            { categoryID: 'cat019', category: 'Windows and Doors', amountSpent: 66000 },
            { categoryID: 'cat020', category: 'Landscaping Materials', amountSpent: 43000 }
        ]

    });


    const totalSpent = data.data.reduce((sum, item) => sum + item.amountSpent, 0);

    const top5CategoryIDs = ['cat001', 'cat002', 'cat003', 'cat004', 'cat005']; // This would be determined dynamically in a real case

    // const colors = [
    //     "#549CFB", // Base Blue (Darker)
    //     "#5A91F9", // Blue Shade 1
    //     "#6086F7", // Blue Shade 2
    //     "#6676FF", // Blue Shade 3
    //     "#6E7AF5", // Blue Shade 4
    //     "#757EEE", // Blue Shade 5
    //     "#7B83E8", // Blue Shade 6
    //     "#8179FF", // Blue Shade 7
    //     "#8A79FF", // Blue Shade 8
    //     "#937BFF", // Blue Shade 9
    //     "#9C7DFF", // Blue Shade 10
    //     "#A682FF", // Blue Shade 11
    //     "#B088FF", // Blue Shade 12
    //     "#BA8EFF", // Blue Shade 13
    //     "#C497FF"  // Lightest Blue
    // ]
    const topCategories = data.data
        .filter(item => top5CategoryIDs.includes(item.categoryID))
        .map((item, index) => ({
            ...item,
            percentage: ((item.amountSpent / totalSpent) * 100).toFixed(2),
            color: colors[index] // Dynamically assign colors from the colors array
        }));
    // Filter out the top 5 categories to get the remaining ones for the bar graph
    const remainingCategories = data.data.filter(item => !top5CategoryIDs.includes(item.categoryID));

    React.useEffect(() => {
        const chartDom = document.getElementById('remaining-bar-chart') as HTMLElement;
        const myChart = echarts.init(chartDom);

        const option = {
            tooltip: {
                trigger: 'item',
                formatter: (params: any) => {
                    const category = params.name;
                    const amountSpent = params.value;
                    const formattedAmount = formatCurrency(amountSpent, data.currency);
                    const percentage = ((amountSpent / totalSpent) * 100).toFixed(2);
                    return `${category}: ${formattedAmount} (${percentage}%)`;
                }
            },
            xAxis: {
                type: 'category',
                data: remainingCategories.map(item => item.category),
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false
                }
            },
            yAxis: {
                show: false
            },
            grid: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            },
            series: [
                {
                    data: remainingCategories.map(item => item.amountSpent),
                    type: 'bar',
                    itemStyle: {
                        color: '#E0E4FF'
                    },
                    barWidth: '40%'
                }
            ]
        };

        myChart.setOption(option);

        return () => {
            myChart.dispose();
        };
    }, [remainingCategories, data.currency]);
    return (
        <div>
            <div className="card-title-group mb-1">
                <div className="card-title">
                    <h6 className="title"> Top Purchase Categories</h6>
                    <p>

                        Insights for Major Purchase Categories
                    </p>
                </div>
            </div>
            {topCategories.map((category) => (
                <div key={category.category} style={{ marginBottom: '5px' }}>
                    <div style={{}} className='progress-label'>
                        {category.category} - {formatCurrency(category.amountSpent, data.currency)}
                    </div>
                    <Progress
                        percent={parseFloat(category.percentage)}
                        status="active"
                        strokeColor={category.color}
                        trailColor="#f1f1f1"
                        strokeWidth={12}
                    />
                </div>
            ))}
            <div id="remaining-bar-chart" style={{ width: '100%', height: '100px' }} />
        </div>
    );
};

export default TopPurchaseCategoriesWidget;
