import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component"; // Assuming these are properly typed elsewhere

interface PaymentsNavProps{
    updateSm: (value: boolean) => void;  // Function to update state
    sm: boolean;  // Boolean state
}

const PaymentsNav: FC<PaymentsNavProps> = ({ updateSm, sm }) => {
   
    const location = useLocation();

    useEffect(() => {
        sm ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{fontSize: 14 }}>Payments</h6>
                   
                </div>
            </div>
            <div className="card-inner p-0">
                <ul className="link-list-menu">
                   
                    <li onClick={() => updateSm(false)}>
                        <Link to="/invoice-payables" className={isActive(`${process.env.PUBLIC_URL}/invoice-payables`)}>
                            <Icon name="eye-fill" />
                            <span>Invoice Payables</span>
                        </Link>
                    </li>

                    <li onClick={() => updateSm(false)}>
                        <Link to="/pending-payment-approvals" className={isActive(`${process.env.PUBLIC_URL}/pending-payment-approvals`)}>
                            <Icon name="check-fill-c" />
                            <span>Pending Approvals </span>
                        </Link>
                    </li>

                    <li onClick={() => updateSm(false)}>
                        <Link to="/run-payments" className={isActive(`${process.env.PUBLIC_URL}/run-payments`)}>
                            <Icon name="activity-round-fill" />
                            <span>Run Payments</span>
                        </Link>
                    </li>

                    <li onClick={() => updateSm(false)}>
                        <Link to="/view-payments" className={isActive(`${process.env.PUBLIC_URL}/view-payments`)}>
                            <Icon name="eye-fill" />
                            <span>View Payments</span>
                        </Link>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default PaymentsNav;
