import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component"; // Assuming these are properly typed elsewhere

interface InvoiceRequestAsideProps {
    updateSm: (value: boolean) => void;  // Function to update state
    sm: boolean;  // Boolean state
}

const InvoiceAside: FC<InvoiceRequestAsideProps> = ({ updateSm, sm }) => {
   
    const location = useLocation();

    useEffect(() => {
        sm ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{fontSize: 14 }}>Invoice</h6>
                   
                </div>
            </div>
            <div className="card-inner p-0">
                <ul className="link-list-menu">
                    <li onClick={() => updateSm(false)}>
                        <Link to="/view-invoice-list" className={isActive(`${process.env.PUBLIC_URL}/view-invoice-list`)}>
                            <Icon name="eye-fill" />
                            <span>View Invoices</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/track-purchase-invoice" className={isActive(`${process.env.PUBLIC_URL}/track-purchase-invoice`)}>
                            <Icon name="activity-round-fill" />
                            <span>Tracking</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/analytics-purchase-invoice" className={isActive(`${process.env.PUBLIC_URL}/analytics-purchase-invoice`)}>
                            <Icon name="growth-fill" />
                            <span>Analytics</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default InvoiceAside;
