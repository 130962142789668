import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col, Button, Modal, ModalBody } from "reactstrap";
import { DatePicker, Popover } from "antd";
import { EditOutlined } from "@ant-design/icons";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import sites from "../../../api/master/sites";
import costCenterApis from "../../../api/master/costCenter";
import FormTextArea from "../../form-components/TextAreaComponent";
import { useFormData } from "./FormDataContext";
import departments from "../../../api/master/departments";
import AskDocuments from "./AskDocuments";
import BudgetApprovalDrawer from "./BudgetApprovalDrawer";
import { SelectOptions, ApprovalData, DocumentFormValues } from "./types"; // Import your types
import { Icon } from "../../../components/Component";
import dayjs from "dayjs";
import usersApis from "../../../api/master/users";

const BudgetGeneralDetails: React.FC = (props: any) => {
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  const { formData, formState, updateFormData, updateFormState } =
    useFormData(); // Use the context to access formData and formState

  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
    []
  );
  const [departmentOptions, setdepartmentOptions] = useState<SelectOptions[]>(
    []
  );
  const [isOpen, setIsOpen] = useState(false);

  const [approvals, setApprovals] = useState({
    open: false,
    data: [],
  });
  const AllFormDetails = watch();

  const [popUpContent, setPopUpContent] = useState<JSX.Element | null>(null);
  const approvalRequired = watch("approvalRequired");
  const siteIdWatch = watch("site");

  const submitForm = (data: any) => {
    // console.log("setAPPROVAL",approvals);
    console.log("approvalsData", data);
    // if (Object.keys(errors).length === 0) {
      // Get selected siteName and costCenterName
      const selectedSite = sitesOptions.find(
        (option) => option.value === data.site
      );
      const selectedCostCenter = costCenterOptions.find(
        (option) => option.value === data.costCenter
      );
      const selectedDepartmet = departmentOptions.find(
        (option) => option.value === data.department
      );

      data.endDate = dayjs(data.endDate).format("YYYY-MM-DD");
      data.startDate = dayjs(data.startDate).format("YYYY-MM-DD");
      // Update formData with additional details
      updateFormData("budgetHeader", {
        ...data,
        siteName: selectedSite ? selectedSite.label : "",
        costCenterName: selectedCostCenter ? selectedCostCenter.label : "",
        departmentName: selectedDepartmet ? selectedDepartmet.label : "",
        approvalsData : JSON.stringify(approvals.data)
        // budgetApprovals: data.approvalsData?.map((approval:any) => ({
        //   roleId: approval.roleId,
        //   level: approval.level,
        //   userId: approval.userId,
        //   userName: approval?.userName,
        //   roleName: approval?.roleName,
        // })),

        // requiredDocs: formState.documentData?.documents?.map((doc) => ({
        //   documentName: doc.documentName,
        //   mandatory: doc.mandatory,
        // })),
      });
      setValue("approvalsData", JSON.stringify(approvals));
      if (approvalRequired == "Y" && approvals?.data?.length === 0) {
        return;
      }

    // if (approvalRequired == "Y" && approvals?.data?.length === 0) {
    //   return;
    // }
      console.log("1111", data);
      // return
      // Handle next step navigation here if necessary
      props.next();
    // } else {
    //   console.log("Form Errors:", errors);
    // }
  };

  const showApprovalDrawer = () => {
    updateFormState({ isApprovalDrawerOpen: true });
  };

  const onApprovalClose = () => {
    updateFormState({ isApprovalDrawerOpen: false });
  };

  const handleApprovalData = (data: ApprovalData[]) => {
    updateFormState({
      approvalData: data,
      approvalSubmissionStatus: true,
      approvalPopoverVisible: false,
    });
    onApprovalClose();
  };

  const handleDocumentsSubmit = (data: DocumentFormValues) => {
    updateFormState({ documentData: data, documentsPopoverVisible: false });
    onAskDocumentsClose();
  };

  const showAskDocumentsDrawer = () => {
    updateFormState({ isAskDocumentsDrawerOpen: true });
  };

  const onAskDocumentsClose = () => {
    updateFormState({ isAskDocumentsDrawerOpen: false });
  };

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser()
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCostCenter = async () => {
    try {
      const { data, status } = await costCenterApis.fetchCostCenter();
      if (status) {
        const costCenterOptions = data.map((item: any) => ({
          label: item.costCenterName,
          value: item.id.toString(),
        }));
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const { data, status } = await departments.fetchDeptData();
      if (status) {
        const departmentOptions = data.map((item: any) => ({
          label: item.deptName,
          value: item.deptID.toString(),
        }));
        setdepartmentOptions(departmentOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchAddresses = async () => {
  //   try {
  //     const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
  //     if (status) {
  //       const addressOptions = data.map((item: any) => ({
  //         label: item.deliveryLocationName,
  //         value: item.id.toString(),
  //       }));
  //       setBillingAddressList(data);
  //       setBillingAddressOptions(addressOptions);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    fetchSites();
    fetchCostCenter();
    fetchDepartments();
    setValue("budgetFor", "Procurement");
    setValue("lob", "Construction");
    const { approvalsData } = AllFormDetails;
    if (approvalsData) {
      setApprovals(JSON.parse(approvalsData));
    }
  }, []);

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="budgetName"
              label="Budget Name"
              required={true}
              placeholder="Enter Budget Name"
            />
          </Col>
          <Col lg="4">
            <FormDatePicker
              name="startDate"
              label="Start Date"
              required={true}
            />
          </Col>
          <Col lg="4">
            <FormDatePicker name="endDate" label="End Date" required={false} />
          </Col>
          <Col lg="12">
            <FormTextArea
              name="description"
              label="Budget Description"
              required={true}
              placeholder="Enter Budget Description"
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="budgetFor"
              label="Budget for"
              defaultValue={{
                label: "Site Procurement",
                value: "Procurement",
              }}
              options={[
                { label: "Site Procurement", value: "Procurement" },
                { label: "Site Operations", value: "Operations" },
              ]}
              required={true}
              placeholder="Select"
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="site"
              label="Site Name"
              options={sitesOptions}
              required={true}
              placeholder="Select"
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="lob"
              label="Select LOB"
              defaultValue={{
                label: "Construction",
                value: "Construction",
              }}
              options={[
                { label: "Construction", value: "Construction" },
                { label: "Others", value: "Others" },
              ]}
              required={true}
              placeholder="Select"
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="costCenter"
              label="Cost Center"
              options={costCenterOptions}
              required={false}
              placeholder="Select"
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="department"
              label="Department"
              options={departmentOptions}
              required={true}
              placeholder="Select"
            />
          </Col>

          <Col lg="3">
            <FormSelect
              name="approvalRequired"
              label="Approval Required ?"
              options={[
                { label: "Yes", value: "Y" },
                { label: "No", value: "N" },
              ]}
              placeholder="Select"
              required={true}
            />
            <div style={{ display: "none" }}>
              <FormInput name="approvalsData" label="" type="text" />
            </div>
            {approvalRequired == "Y" && (
              <Popover
                content="Click here to set required approvals"
                title="Approval Required"
              >
                <a
                  href="#"
                  className="success"
                  onClick={(e) => {
                    e.preventDefault();
                    setApprovals((pre) => {
                      return {
                        ...pre,
                        open: true,
                      };
                    });
                  }}
                >
                  <EditOutlined /> Set Approvals &nbsp;
                  {approvals?.data?.length == 0 ? (
                    <span className="mandatory">Please set approvals</span>
                  ) : (
                    <></>
                  )}
                </a>
              </Popover>
            )}
          </Col>
          <Col lg="3">
            <FormSelect
              name="docRequired"
              label="Require Documents?"
              options={[
                { label: "Yes", value: "Y" },
                { label: "No", value: "N" },
              ]}
              required={false}
              placeholder="Select"
              onChange={(val) => updateFormState({ showAskDocs: val === "Y" })}
            />
            {/* {formState.showAskDocs && (
              <Popover
                content="Click here to ask required documents"
                title="Documents Required"
              >
                <a
                  href="#"
                  className="success"
                  onClick={(e) => {
                    e.preventDefault();
                    showAskDocumentsDrawer();
                  }}
                >
                  <EditOutlined /> Ask Required Documents &nbsp;
                  {formState.documentsPopoverVisible ? (
                    <span className="mandatory">Please set documents</span>
                  ) : (
                    <></>
                  )}
                </a>
              </Popover>
            )} */}
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
          </ul>
        </div>
      </form>
      {approvals.open && 
      <BudgetApprovalDrawer
        open={approvals.open}
        siteId={siteIdWatch}
        onClose={() => {
          setApprovals((pre) => {
            return {
              ...pre,
              open: false,
            };
          });
        }}
        onSubmit={(data: any) => {
          console.log("data:111111", data);
          setApprovals({
            open: false,
            data: data,
          });
        }}
        setApprovalSubmissionStatus={(status: boolean) => {}}
        approvalData={approvals.data} // Pass the current approval data
      />
}
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        className="modal-dialog-centered"
        size="xl"
        backdrop="static"
      >
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setIsOpen(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <ModalBody>{popUpContent}</ModalBody>
      </Modal>
      {/* <AskDocuments
        open={formState.isAskDocumentsDrawerOpen}
        onClose={onAskDocumentsClose}
        onSubmit={handleDocumentsSubmit}
        documentData={
          formState.documentData || {
            documents: [
              { documentName: "", description: "", mandatory: false },
            ],
          }
        } // Pass the current document data
      /> */}
    </>
  );
};

export default React.memo(BudgetGeneralDetails);
