import React, { useState, useEffect } from 'react';
import { Select, Empty } from 'antd';
import * as echarts from 'echarts';
import { QuotationAnalyticsProps } from './interfaces';

const { Option } = Select;

type MaterialSavings = {
    materialID: string;
    material: string;
    savings: number;
};

type MaterialSavingsData = {
    [key: string]: MaterialSavings[];
};

const materialSavingsData: MaterialSavingsData = {
    Steel: [
        { materialID: 'Mat001', material: '10mm TMT Rebars', savings: 15 },
        { materialID: 'Mat002', material: '12mm TMT Rebars', savings: 20 },
        { materialID: 'Mat003', material: '16mm TMT Rebars', savings: 12 },
        { materialID: 'Mat004', material: '20mm TMT Rebars', savings: 18 },
        { materialID: 'Mat005', material: '25mm TMT Rebars', savings: 22 },
    ],
    Cement: [
        { materialID: 'Mat011', material: 'OPC 43 Grade Cement', savings: 8 },
        { materialID: 'Mat012', material: 'OPC 53 Grade Cement', savings: 10 },
        { materialID: 'Mat013', material: 'PPC (Portland Pozzolana Cement)', savings: 12 },
        { materialID: 'Mat014', material: 'PSC (Portland Slag Cement)', savings: 9 },
        { materialID: 'Mat015', material: 'White Cement', savings: 14 },
    ],
    Electricals: [],
    Wood: [],
    Glass: [],
};

const MaterialWiseSavings: React.FC<QuotationAnalyticsProps> = ({ site, dateRange, customDateRange, data }) => {
    console.log("Dataaaaa",data)
    // const [materialSavingsData, setMaterialSavingsData] = useState<MaterialSavingsData>(data || {});
    const firstCategory = Object.keys(materialSavingsData)[0] as keyof MaterialSavingsData;
    const [selectedCategory, setSelectedCategory] = useState<keyof MaterialSavingsData>('Steel');
    const [currentData, setCurrentData] = useState<MaterialSavings[]>(materialSavingsData[selectedCategory]);
    useEffect(() => {
        setCurrentData(materialSavingsData[selectedCategory]);

        if (materialSavingsData[selectedCategory]?.length > 0) {
            const chartDom = document.getElementById('material-savings-chart') as HTMLElement;
            if (chartDom) {
                const myChart = echarts.init(chartDom);

                const option = {
                    title: {
                        text: `Avg. ${selectedCategory} Savings - ${'7%'}`,

                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { type: 'shadow' },
                    },
                    xAxis: {
                        type: 'category',
                        data: currentData.map(item => item.material),
                        name: 'Material',
                        nameLocation: 'middle',
                        nameTextStyle: {
                            fontWeight: 'bold',
                            fontSize: 14,
                            padding: 40,
                        },
                        axisLabel: {
                            interval: 0,
                            formatter: (value: string) => {
                                const maxWidth = 10;
                                if (value.length > maxWidth) {
                                    return value.split(' ').reduce((acc, word) => {
                                        if (acc.length === 0) {
                                            acc.push(word);
                                        } else {
                                            const lastLine = acc[acc.length - 1];
                                            if ((lastLine + ' ' + word).length <= maxWidth) {
                                                acc[acc.length - 1] = lastLine + ' ' + word;
                                            } else {
                                                acc.push(word);
                                            }
                                        }
                                        return acc;
                                    }, [] as string[]).join('\n');
                                } else {
                                    return value;
                                }
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: { formatter: '{value}%' },
                        name: 'Avg. Savings (%)',
                        nameLocation: 'middle',
                        nameTextStyle: {
                            fontWeight: 'bold',
                            fontSize: 14,
                            padding: 40,
                        },
                    },
                    series: [
                        {
                            name: 'Savings',
                            type: 'bar',
                            data: currentData.map(item => item.savings),
                            itemStyle: {
                                color: '#6675FF',
                            },
                            barWidth: '20%',
                            barCategoryGap: '100%',
                        },
                    ],
                };

                myChart.setOption(option);

                return () => {
                    myChart.dispose();
                };
            }
        }
    }, [selectedCategory, currentData]);

    const handleCategoryChange = (value: keyof MaterialSavingsData) => {
        setSelectedCategory(value);
    };

    return (
        <div>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Material Wise Savings</h6>
                    <p>Insights on savings achieved across different materials.</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 8 }}>Filter by:</span>
                    <Select
                        placeholder="Select Category"
                        style={{ width: 200 }}
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        allowClear
                    >
                        {Object.keys(materialSavingsData).map((category) => (
                            <Option key={category} value={category}>
                                {category}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
            {currentData?.length > 0 ? (
                <div id="material-savings-chart" style={{ width: '100%', height: '300px' }} />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                    <Empty description="No Data found for the selected category" />
                </div>
            )}
        </div>
    );
};

export default MaterialWiseSavings;
