import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import supplierApis from "../../../api/master/supplier";
import Swal from "sweetalert2";
import { RSelect } from "../../../components/Component";
import usersApis from "../../../api/master/users";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
interface SelectOptions {
  label: string;
  value: string;
}
const GeneralInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const submitForm = (data: any) => {
    console.log("General Info Data:", data);
    props.next();
  };

  const [categories, setCategories] = useState<SelectOptions[]>([
    { label: "Category1", value: "Category1" },
    { label: "Category2", value: "Category2" },
  ]);
  const [industryTypeOptions, setIndustryTypeOptions] = useState<
    SelectOptions[]
  >([
    { label: "Supplier", value: "Supplier" },
    { label: "Customer", value: "Customer" },
  ]);
  const [states, setStates] = useState<SelectOptions[]>([]);

  const fetchStatesByCountry = async () => {
    try {
      const data = await usersApis.getStatesByCountry(1);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      //   if (stateId) {
      //     const state = stateOptions.find(
      //       (state: SelectOptions) => state.value === stateId
      //     );
      //     if (!state) {
      //       return;
      //     }
      //     setValue("state", state.value);
      //   }
    } catch (error) {}
  };

  const fetchSupplierCategory = async () => {
    try {
      const { data, status, message } =
        await supplierApis.fetchSupplierCategory();
      if (status) {
        console.log(data);
        setCategories(
          data.map((obj: any) => ({ label: obj.category, value: obj.id }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  const fetchIndustryType = async () => {
    try {
      const { data, status, message } =
        await supplierApis.getContructionsMaterialTypes();
      if (status) {
        console.log(data);
        setIndustryTypeOptions(
          data.map((obj: any) => ({ label: obj.name, value: obj.material_id }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  const handleMultiSelectChange = (selectedOptions: any, name: any) => {
    if (name == "categories") {
      const value = selectedOptions.map((option: any) => ({
        categoryID: option.value,
        categoryName: option.label,
      }));
      setValue(name, value);
    } else if (name == "materials") {
      const value = selectedOptions.map((option: any) => ({
        materialID: option.value,
        materialName: option.label,
      }));
      setValue(name, value);
    } else if (name == "supplyStates") {
      const value = selectedOptions.map((option: any) => ({
        stateID: option.value,
        stateName: option.label,
      }));
      setValue(name, value);
    }
  };

  useEffect(() => {
    fetchIndustryType();
    fetchSupplierCategory();
    fetchStatesByCountry();
  }, []);

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="referenceID"
              label="Supplier ID"
              type="text"
              required={true}
              // maxLength={10}
              placeholder="Enter Supplier ID"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="supplierName"
              label="Supplier Name"
              // disabled={action === "view"}
              required={true}
              type="text"
              maxLength={50}
              placeholder="Enter Supplier Name"
            />
          </Col>
          <Col lg="4">
            <FormMultiSelect
              name="materials"
              label="Material Type"
              options={industryTypeOptions}
              required={true}
              placeholder="Select Material Type"
            />
            {/* <label className="form-label">
              Material Type<span className="mandatory">*</span>
            </label>
            <RSelect
              isMulti
              name="industryType"
              // disabled={action === "view"}
              label="Material Type"
              options={industryTypeOptions}
              required={true}
              placeholder="-Select-"
              onChange={(value: any) =>
                handleMultiSelectChange(value, "materials")
              }
              closeMenuOnSelect={false}
            /> */}
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col lg="4">
            <FormMultiSelect
              name="categories"
              label="Supply Categories"
              required={true}
              options={categories}
              placeholder="Select Supply Categories"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="minSupply"
              label="Min.Supply"
              // required={true}
              type="number"
              // disabled={action === "view"}
              placeholder="Enter Min.Supply"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="deliveryLeadDays"
              label="Delivery Lead Days"
              required={true}
              type="number"
              // disabled={action === "view"}
              placeholder="Enter Delivery Lead Days"
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col lg="4">
            <FormSelect
              name="paymentTerms"
              label="Payment Terms"
              required={true}
              options={[
                { label: "Advance", value: "Advance" },
                { label: "Credit", value: "Credit" },
              ]}
              placeholder="Enter Payment Terms"
            />
          </Col>
          {watch("paymentTerms") == "Credit" && (
            <Col lg="4">
              <FormInput
                name="creditDays"
                label="Credit Days"
                required={true}
                type="number"
                placeholder="Enter Credit Days"
              />
            </Col>
          )}

          <Col lg="4">
            <FormMultiSelect
              name="supplyStates"
              label="Supply State"
              options={states}
              placeholder="Select Supply State"
            />
            {/* <label className="form-label">
              Supply State
              <span className="mandatory">*</span>
            </label>
            <RSelect
              isMulti
              name="supplyStates"
              label="Supply State"
              options={states}
              // required={true}
              // disabled={action === "view"}
              placeholder="-Select-"
              onChange={(value: any) =>
                handleMultiSelectChange(value, "supplyStates")
              }
              closeMenuOnSelect={false}
            /> */}
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default React.memo(GeneralInformation);
