import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Col, Row } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  PreviewAltCard
} from "../../components/Component";
import BudgetAside from "./BudgetAside";
import BudgetVsSpendAnalysis from "./analytics/BudgetVsSpendAnalysis";
import { Select, DatePicker } from "antd"; 
import moment, { Moment } from "moment";
import dayjs, { Dayjs } from 'dayjs';

const { Option } = Select;
const { RangePicker } = DatePicker;

const BudgetAnalytics: FC = () => {
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [site, setSite] = useState("ALL");
  const [dateRange, setDateRange] = useState("Month to Date");
  const [customDateRange, setCustomDateRange] = useState<[Moment, Moment] | null>(null);

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document.getElementsByClassName("nk-header")[0]?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document.getElementsByClassName("nk-header")[0]?.removeEventListener("click", headerClick);
    };
  }, []);

  const handleSiteChange = (value: string) => {
    setSite(value);
  };

  const handleDateRangeChange = (value: string) => {
    setDateRange(value);
    if (value !== "Custom Date") {
      setCustomDateRange(null); 
    }
  };

  const handleCustomDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      setCustomDateRange([moment(dates[0].toDate()), moment(dates[1].toDate())]);
    } else {
      setCustomDateRange(null);
    }
  };

  return (
    <>
      <Head title="Budget Analytics" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""
                }`}
            >
              <BudgetAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => setSm(!sm)}></div>}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Budget Analytics</BlockTitle>
                    <BlockDes>
                      <p>Integrated Dashboard for Budget and Expense Analysis</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent className="d-flex align-items-center">
                    <div style={{ marginRight: 16 }}>
                      <label style={{ fontWeight: 'bold', marginRight: 8 }}>Filter by Site:</label>
                      <Select
                        defaultValue="ALL"
                        style={{ width: 150 }}
                        onChange={handleSiteChange}
                      >
                        <Option value="ALL">All Sites</Option>
                        <Option value="SITE001">Site 1</Option>
                        <Option value="SITE002">Site 2</Option>
                        <Option value="SITE003">Site 3</Option>
                        <Option value="SITE004">Site 4</Option>
                        <Option value="SITE005">Site 5</Option>
                      </Select>
                    </div>
                    <div>
                      <label style={{ fontWeight: 'bold', marginRight: 8 }}>Filter by Date Range:</label>
                      <Select
                        defaultValue="Month to Date"
                        style={{ width: 200, marginRight: 16 }}
                        onChange={handleDateRangeChange}
                      >
                        <Option value="Month to Date">Month to Date</Option>
                        <Option value="Quarter to Date">Quarter to Date</Option>
                        <Option value="Year to Date">Year to Date</Option>
                        <Option value="Custom Date">Custom Date</Option>
                      </Select>
                      {dateRange === "Custom Date" && (
                        <RangePicker
                          value={
                            customDateRange
                              ? [dayjs(customDateRange[0].toDate()), dayjs(customDateRange[1].toDate())]
                              : null
                          }
                          onChange={(dates, dateStrings) => handleCustomDateChange(dates)}
                          style={{ width: 300 }}
                        />
                      )}
                    </div>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block>
                <Row className="g-gs">
                  <Col lg='12'>
                    <PreviewAltCard className="card-full" bodyClass={undefined}>
                      <BudgetVsSpendAnalysis />
                    </PreviewAltCard>
                  </Col>
                </Row>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default BudgetAnalytics;
