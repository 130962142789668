import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge, Row, Col } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
} from "../../components/Component";
import QuotationsAside from "./QuotationsAside";
import { Progress, Table, Select, Input } from "antd";
import type { ColumnType, ColumnGroupType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import quotationApis from "../../api/master/quotations";
import moment from "moment";

const { Option } = Select;

interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

interface TableData {
  key: number;
  rfqId: string;
  quotationDescription: string;
  siteName: string;
  deadlineType: string;
  lastSubmissionDate: string;
  costCenterName: string;
  generalLedger: string;
  status: string;
  statusColor: string;
}

const RFQView: FC = () => {
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined
  );
  const [filteredData, setFilteredData] = useState<TableData[]>([]);

  const [tableData, setTableData] = useState<TableData[]>([]);

  const [widgetData, setWidgetData] = useState<WidgetData[]>([]);
  const [editLineRow,setEditLineRow] = useState<any>();
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);
    fetchCounts();
    fetchList();
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const fetchCounts = async () => {
    try {
      const { response, status } = await quotationApis.fetchCounts();
      if (status) {
        const counts = response.data;
        const colors = ["#OO65BD", "#20639B", "#FA991C", "#F6D55C", "#OOCC99"];
        let formatData = counts.map((item: any, index: number) => ({
          title: item.title,
          count: item.count,
          progress: item.progress.toFixed(0),
          color: colors[index % colors.length],
        }));
        setWidgetData(formatData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchList = async () => {
    try {
      const { response, status } = await quotationApis.fetchList();
      if (status) {
        const data = response.map((item: any) => {
          item.statusColor = widgetData[item % widgetData.length]?.color;
          return item.quotationHeader;
        });

        console.log("data", data);
        setTableData(data);
        setFilteredData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const widgetData: WidgetData[] = [
  //   {
  //     title: "Draft RFQs",
  //     count: 10,
  //     progress: 30,
  //     color: "#OO65BD",
  //   },
  //   {
  //     title: "Pending Approvals",
  //     count: 5,
  //     progress: 50,
  //     color: "#20639B",
  //   },
  //   {
  //     title: "In Progress",
  //     count: 7,
  //     progress: 70,
  //     color: "#FA991C",
  //   },
  //   {
  //     title: "Converted to POs",
  //     count: 12,
  //     progress: 90,
  //     color: "#F6D55C",
  //   },
  //   {
  //     title: "Delivered",
  //     count: 15,
  //     progress: 100,
  //     color: "#OOCC99",
  //   },
  // ];

  // const tableData: TableData[] = Array.from({ length: 25 }, (v, i) => ({
  //   key: i,
  //   rfqId: `RFQ${i + 1}`,
  //   description: `Description for RFQ${i + 1}`,
  //   siteName: `Site ${i + 1}`,
  //   deadlineType: i % 2 === 0 ? "Soft" : "Hard",
  //   lastDateOfSubmission: `2024-12-${i + 1}`,
  //   costCenter: `CC${i + 1}`,
  //   generalLedger: `GL${i + 1}`,
  //   status: widgetData[i % widgetData?.length]?.title,
  //   statusColor: widgetData[i % widgetData.length]?.color,
  // }));

  // useEffect(() => {}, []);

  const handleStatusFilterChange = (value: string | undefined) => {
    setStatusFilter(value);
    filterTable(searchText, value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
    filterTable(value, statusFilter);
  };

  const filterTable = (searchText: string, status: string | undefined) => {
    let filteredData = tableData;

    if (searchText) {
      filteredData = filteredData.filter(
        (item) =>
          item.rfqId.toLowerCase().includes(searchText.toLowerCase()) ||
          item.quotationDescription
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.siteName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (status && status !== "ALL") {
      filteredData = filteredData.filter((item) => item.status === status);
    }

    setFilteredData(filteredData);
  };
  const navigate = useNavigate();
  const handleRFQClick = (rfq: any) => {
    console.log("Summary", rfq);
    navigate("/rfq-full-view", { state: { rfq } });
  };

  const columns: (ColumnGroupType<TableData> | ColumnType<TableData>)[] = [
    {
      title: "RFQ ID",
      dataIndex: "rfqId",
      key: "rfqId",
      fixed: "left",
      width: 100,
      sorter: (a, b) => a.rfqId.localeCompare(b.rfqId),
      align: "left",
      render: (text, record) => (
        <a onClick={() => handleRFQClick(record)} style={{color:'#1677ff',fontWeight:"600"}}>{text}</a>
      ),
    },
    {
      title: "Description",
      dataIndex: "quotationDescription",
      key: "quotationDescription",
      fixed: "left",
      width: 200,
      sorter: (a, b) =>
        a.quotationDescription.localeCompare(b.quotationDescription),
      align: "left",
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 150,
      sorter: (a, b) => a.siteName.localeCompare(b.siteName),
      align: "left",
    },
    {
      title: "Deadline Type",
      dataIndex: "deadlineType",
      key: "deadlineType",
      width: 150,
      sorter: (a, b) => a.deadlineType.localeCompare(b.deadlineType),
      align: "left",
    },
    {
      title: "Last Date of Submission",
      dataIndex: "lastDateOfSubmission",
      key: "lastDateOfSubmission",
      width: 200,
      sorter: (a, b) =>
        new Date(a.lastSubmissionDate).getTime() -
        new Date(b.lastSubmissionDate).getTime(),
      align: "left",
      render: (text: string) => {
        const date = new Date(text);
        const formattedDate = moment(text).format("DD-MMM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Cost Center",
      dataIndex: "costCenterName",
      key: "costCenterName",
      width: 150,
      sorter: (a, b) => a.costCenterName.localeCompare(b.costCenterName),
      align: "left",
    },
    // {
    //   title: "General Ledger",
    //   dataIndex: "generalLedger",
    //   key: "generalLedger",
    //   width: 150,
    //   sorter: (a, b) => a.generalLedger.localeCompare(b.generalLedger),
    //   align: "left",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      render: (text, record) => (
        <span style={{ color: record.statusColor }}>{text}</span>
      ),
      width: 150,
      sorter: (a, b) => a.status.localeCompare(b.status),
      align: "left",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (text, record: any, index) =>
       <>
            <Button
              size="sm"
              // color=""
              onClick={() => {
                navigate(`/view/${record.quotationId}`);
              }}
            >
              <Icon name="focus" />
            </Button>
          </>
    },
  ];

  return (
    <>
      <Head title="RFQ - List" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <QuotationsAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}

              <Block>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {widgetData.map((widget, index) => (
                    <div
                      key={index}
                      style={{
                        border: "1px solid #dbdfea",
                        padding: "15px",
                        borderRadius: "8px",
                        width: "calc(20% - 10px)", // Ensure widgets are 20% of the width
                        minWidth: "150px", // Ensure a minimum width for smaller screens
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ marginBottom: "20px" }}>
                        <h6 style={{ margin: 0 }}>{widget.title}</h6>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <Progress
                            type="circle"
                            percent={widget.progress}
                            width={50}
                            strokeColor={widget.color}
                            format={(percent) => `${percent}%`}
                          />
                        </div>
                        <div style={{ flex: 1, textAlign: "center" }}>
                          <p style={{ fontSize: "24px", margin: 0 }}>
                            {widget.count}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Block>
              <Block>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div>
                    <span>Filter by Status: </span>
                    <Select
                      style={{ width: 200 }}
                      onChange={handleStatusFilterChange}
                      value={statusFilter || "ALL"}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <div
                            style={{
                              padding: "8px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Search by Status: </span>
                            <SearchOutlined />
                          </div>
                        </>
                      )}
                    >
                      <Option value="ALL">All</Option>
                      {widgetData.map((widget, index) => (
                        <Option key={index} value={widget.title}>
                          {widget.title}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Input
                      placeholder="Search by Site Name &amp; RFQ ID"
                      value={searchText}
                      onChange={handleSearchChange}
                      style={{ width: 400 }}
                      prefix={<SearchOutlined />}
                    />
                  </div>
                </div>
              </Block>
              <Row>
                <Col>
                  <div
                    style={{ width: screenSize - 320, overflow: "auto" }}
                    id="customTable"
                  >
                    <Table
                      columns={columns}
                      dataSource={filteredData}
                      pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30", "50"],
                      }}
                      bordered
                      size="middle"
                      scroll={{ x: 1400 }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default RFQView;
