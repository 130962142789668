import React, { useState, useEffect, useRef } from 'react';
import { Select, Empty } from 'antd';
import * as echarts from 'echarts';
import { formatCurrency, formatCurrency2 } from '../../../utils/Utils'; // Adjust the import path as needed
import { format } from 'date-fns'; // Assuming you're using date-fns for date formatting

const { Option } = Select;

interface CategoryData {
    categoryID: string;
    categoryName: string;
    budget: number;
    timePeriod: 'daily' | 'monthly';
    dates: string[];
    budgetAllocated: number[];
    amountSpent: number[];
}

const categories = [
    { categoryID: 'cat001', categoryName: 'Steel' },
    { categoryID: 'cat002', categoryName: 'Cement' },
    { categoryID: 'cat003', categoryName: 'Electricals' }, // Example with no data
];

const data: CategoryData[] = [
    {
        categoryID: 'cat001',
        categoryName: 'Steel',
        budget: 100000000, // 10 Cr
        timePeriod: 'monthly',
        dates: [
            'April 2023', 'May 2023', 'June 2023', 'July 2023', 'August 2023', 
            'September 2023', 'October 2023', 'November 2023', 'December 2023', 
            'January 2024', 'February 2024', 'March 2024'
        ],
        budgetAllocated: Array(12).fill(8333333), // Evenly distributed budget
        amountSpent: [
            7000000, 8500000, 9000000, 9500000, 7500000, 
            8000000, 8500000, 8000000, 9000000, 8500000, 
            9500000, 10000000
        ],
    },
    {
        categoryID: 'cat002',
        categoryName: 'Cement',
        budget: 50000000, // 5 Cr
        timePeriod: 'daily',
        dates: Array.from({ length: 20 }, (_, i) => 
            format(new Date(2023, 7, 1 + i), 'dd-MMM-yyyy')
        ), // Generates dates starting from 1-Aug-2023
        budgetAllocated: Array(20).fill(2500000), // Evenly distributed budget
        amountSpent: [
            2400000, 2600000, 2500000, 2450000, 2550000, 
            2300000, 2600000, 2500000, 2400000, 2500000, 
            2450000, 2600000, 2550000, 2500000, 2400000, 
            2450000, 2500000, 2600000, 2500000, 2550000
        ],
    },
    // Add more category data as needed
];

const currency = 'INR';

const BudgetVsSpendChart: React.FC = () => {
    const [selectedCategoryID, setSelectedCategoryID] = useState<string>(categories[0]?.categoryID || '');
    const chartRef = useRef<HTMLDivElement>(null);
    const chartInstance = useRef<echarts.ECharts | null>(null);

    const initializeChart = (selectedData: CategoryData) => {
        if (chartRef.current) {
            if (!chartInstance.current) {
                chartInstance.current = echarts.init(chartRef.current);
            }

            const option = {
                title: {
                    text: `${selectedData.categoryName} Budget vs Spend`,
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: (params: any) => {
                        const budget = params[0].value;
                        const spent = params[1].value;
                        const percentage = ((spent / budget) * 100).toFixed(2);
                        const color = spent > budget ? 'red' : 'green';
                        return `
                            <div>
                                <strong>${params[0].name}</strong><br/>
                                Budget: ${formatCurrency(budget, currency)}<br/>
                                Spent: ${formatCurrency(spent, currency)} <span style="color:${color};">(${percentage}%)</span>
                            </div>
                        `;
                    },
                },
                xAxis: {
                    type: 'category',
                    data: selectedData.dates,
                },
                yAxis: {
                    type: 'value',
                    name: `Amount (${currency})`,
                    axisLabel: {
                        formatter: (value: number) => formatCurrency2(value, currency),
                    },
                },
                series: [
                    {
                        name: 'Budget Allocated',
                        type: 'line',
                        data: selectedData.budgetAllocated,
                        lineStyle: {
                            color: '#3BAEA3',
                        },
                        smooth: true,
                        areaStyle: {
                            color: 'rgba(59, 174, 163, 0.3)', // Light teal fill color with transparency
                        },
                    },
                    {
                        name: 'Amount Spent',
                        type: 'line',
                        data: selectedData.amountSpent,
                        lineStyle: {
                            color: '#5470C6',
                        },
                        smooth: true,
                    },
                ],
            };

            chartInstance.current.setOption(option);
        }
    };

    const disposeChart = () => {
        if (chartInstance.current) {
            chartInstance.current.dispose();
            chartInstance.current = null;
        }
    };

    const handleCategoryChange = (value: string) => {
        setSelectedCategoryID(value);
        const selectedData = data.find(category => category.categoryID === value);
        if (selectedData && selectedData.dates.length > 0) {
            initializeChart(selectedData);
        } else {
            disposeChart();
        }
    };

    useEffect(() => {
        const initialData = data.find(category => category.categoryID === selectedCategoryID);
        if (initialData && initialData.dates.length > 0) {
            initializeChart(initialData);
        } else {
            disposeChart();
        }
    }, [selectedCategoryID]);

    const selectedData = data.find(category => category.categoryID === selectedCategoryID);

    return (
        <div>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Budget vs Spend Analysis</h6>
                    <p>Comparison of budget allocation and spending over the selected period.</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 8 }}>Filter by Category:</span>
                    <Select
                        placeholder="Select Category"
                        style={{ width: 200 }}
                        value={selectedCategoryID}
                        onChange={handleCategoryChange}
                    >
                        {categories.map(category => (
                            <Option key={category.categoryID} value={category.categoryID}>
                                {category.categoryName}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
            {selectedData && selectedData.dates && selectedData.dates.length > 0 ? (
                <div ref={chartRef} id="budget-spend-chart" style={{ width: '100%', height: '400px' }} />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                    <Empty description="No budget details found for this category" />
                </div>
            )}
        </div>
    );
};

export default BudgetVsSpendChart;
