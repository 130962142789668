import React, { useEffect, useState, useCallback } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import { getCountrys, getStatesByCountry } from "../../../api/misc/dropDowns";
import usersApis from "../../../api/master/users";
import sites from "../../../api/master/sites";
import Swal from "sweetalert2";
import { RSelect } from "../../../components/Component";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
interface SelectOptions {
  label: string;
  value: string;
}

const GeneralInformation = (props: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();
  const [personalInfoFormData, setPersonalInfoFormData] = useState<any>({});

  const submitForm = (data: any) => {
    console.log("General Info Data:", data);
    // setPersonalInfoFormData(data);
    props.next();
  };

  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);

  const countryWatch = watch("country");
  const stateWatch = watch("state");
  useEffect(() => {
    fetchStatesByCountry(countryWatch);
  }, [countryWatch]);
  useEffect(() => {
    getDistricts(stateWatch);
  }, [stateWatch]);
  const fetchCountrys = async (id?: string) => {
    try {
      const data = await usersApis.getCountrys();
      const countryOptions = data.data.map((country: any) => ({
        label: country.countryName,
        value: country.countryId.toString(),
      }));
      setCountries(countryOptions);
      if (id) {
        const country = countryOptions.find(
          (country: SelectOptions) => country.value === id.toString()
        );
        if (!country) {
          return;
        }
        setValue("country", id);
      }
    } catch (error) {}
  };

  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) {
          return;
        }
        setValue("state", state.value);
      }
    } catch (error) {}
  };

  const getDistricts = async (stateID: any) => {
    try {
      const districts = await usersApis.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const [workingSites, setWorkingSites] = useState<any>([]);

  const fetchWorkingSites = async (selectedOptions: SelectOptions[] = []) => {
    try {
      const { data, status, message } = await sites.fetchSites();
      if (status) {
        setWorkingSites(
          data.map((item: any) => ({
            label: item.name,
            value: item.siteID?.toString(),
          }))
        );
        // if (selectedOptions.length > 0) {
        //   setValue("siteAccessibility", selectedOptions);
        // }
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };

  useEffect(() => {
    fetchWorkingSites();
    fetchCountrys();
  }, []);

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="referenceID"
              label="User ID"
              required={true}
              type="text"
              maxLength={10}
              placeholder="Enter User ID"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="name"
              label="User Name"
              required={true}
              type="text"
              maxLength={50}
              placeholder="Enter User Name"
            />
          </Col>

          <Col lg="4">
            <FormMultiSelect
              name="siteAccessibility"
              label="Site Accessibility"
              required={true}
              options={workingSites}
              placeholder="Select Site Accessibility"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="email"
              label="Email Address"
              type="email"
              required={true}
              maxLength={50}
              placeholder="Enter Email Address"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="phoneNumber"
              label="Phone Number"
              type="text"
              required={true}
              placeholder="Enter Phone Number"
              pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="country"
              label="Country"
              options={countries}
              required={true}
              placeholder="Enter Country"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="state"
              label="State"
              required={true}
              options={states}
              placeholder="Select State"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="district"
              label="District"
              options={districtOptions}
              required={true}
              placeholder="Select District"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="city"
              label="City"
              required={true}
              placeholder="Enter City"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="area"
              label="Area"
              placeholder="Enter Area"
              required={true}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="PIN"
              label="PIN"
              required={true}
              type="number"
              maxLength={6}
              placeholder="Enter PIN"
            />
          </Col>
          <Col lg="4">
            <FormDatePicker
              name="DOB"
              label="Date of Birth"
              maxDate={new Date()}
              showYearDropdown={true}
              scrollableYearDropdown={true}
            />
          </Col>
          <Col lg="4">
            <FormDatePicker name="DOJ" label="Date of Joining" />
          </Col>
          <Col lg="4">
            <FormInput
              name="bloodGroup"
              label="Blood Group"
              type="text"
              placeholder="Enter Blood Group"
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default React.memo(GeneralInformation);
