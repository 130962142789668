import React, { useEffect, useState } from "react";
import { Badge, Button } from "reactstrap";
import { useFormData } from "./FormDataContext"; // Import your context hook
import {
  Collapse,
  Descriptions,
  Popover,
  Timeline,
  Table,
  List,
  Modal,
} from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { ApprovalData, Material } from "./types"; // Import your types
import quotationApis from "../../../api/master/quotations";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { useNavigate } from "react-router";

const { Panel } = Collapse;

const RFQPreview: React.FC<{ prev: () => void }> = (props) => {
  const { formData } = useFormData();
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const navigate = useNavigate();



  const handleSubmit = async (data: any) => {
    let fileCounter = 0;
    let fileCounterfile = 0

    console.log("additionalData", formData.materialsData);
    const quotationLines = formData.materialsData?.map((material: any, index: number) => {
      const hasFile = material.file !== undefined && material.file !== null;

      return {
        materialId: material.materialID || null,
        materialName: material.materialName || "",
        categoryId: material.categoryID || null,
        categoryName: material.categoryName || "",
        uomId: material.uomID || null,
        quantity: material.qty || "",
        needByDate: material.needByDate || "",
        deliveryAddressId: material.deliveryAddressID || null,
        deliveryDate: material.needByDate || "",
        ...(hasFile && { fileIndex: fileCounter++ }) // Only include fileIndex if there's a file
      };
    });
    const submissionData = {
      quotationHeader: {
        ...formData.quotationHeader,
        // quotationId: 1,
        // orgId: 1,
        billingAddressId: formData.additionalData?.billingAddress || null,
        quotationFor: formData.additionalData?.quotationFor || "",
        quotationDescription:
          formData.additionalData?.quotationDescription || "",
        costCenter: formData.additionalData?.costCenter || null,
        lastSubmissionDate: dayjs(formData.additionalData?.lastSubmissionDate).format("YYYY-MM-DD") || "",
        deadlineType: formData.additionalData?.deadlineType || "",
        siteId: formData.additionalData?.siteId || null,
        additionalCharges: formData.additionalData?.additionalCharges || "",
        deliveryLevelType: formData.additionalData?.deliveryLevelType || "",
        deliveryAddressId: formData.additionalData?.deliveryAddress || null,
        deliveryDateType: formData.additionalData?.deliveryLevelType || "",
        deliveryDate: formData.additionalData?.lastSubmissionDate || "",
        autoRelease: formData.additionalData?.autoRelease || "",
        approvalRequired: formData.additionalData?.approvalRequired || "",
        supplierDocsRequired:
          formData.additionalData?.supplierDocsRequired || "",
        publishTo:
          formData.additionalData?.publishTo === "Yes"
            ? "MARKET_PLACE"
            : "PRIVATE_SUPPLIERS",
      },
      quotationLines: quotationLines || [],
      requiredDocs:
        formData.quotationHeader?.requiredDocs?.map(
          (doc: any, index: number) => ({
            documentName: doc.documentName || "",
            mandatory: doc.mandatory || 0,
          })
        ) || [],
      quotationApprovals:
        formData.quotationHeader?.quotationApprovals?.map(
          (approval: any, index: number) => ({
            roleId: approval.roleId || "",
            level: approval.level || 0,
            userId: approval.userId || "",
          })
        ) || [],
      docKeys: formData.materialsData
        ?.map((material: any, index: number) => {
          if (material.file !== undefined && material.file !== null) {
            const docKey = {
              fileIndex: fileCounterfile++, // Use the same fileCounter
              module: "Quotation",
              moduleFileType: "Special Instructions",
              level: "LINE_LEVEL",
            };
            return docKey;
          }
          return null; // Return null if there is no file
        })
        .filter((doc: any) => doc !== null) || [], // Filter out null values
      additionalData: {
        selectedSuppliers: Object.keys(formData.additionalData)
          .filter(
            (key) => key.startsWith("supplier_") && formData.additionalData[key]
          )
          .map((key) => parseInt(key.replace("supplier_", ""))),
        termsAndConditions: formData.additionalData?.termsAndConditions || "",
        instructions: formData.additionalData?.instructions || "",
        publishTo:
          formData.additionalData?.publishTo === "Yes"
            ? "MARKET_PLACE"
            : "PRIVATE_SUPPLIERS",
      },
    };

    // Append attachments to docKeys
    formData.attachments?.forEach((attachment: any, index: number) => {
      submissionData.docKeys.push({
        fileIndex: formData.materialsData.length + index,
        module: "Quotation",
        moduleFileType: "attachments",
        level: "HEADER_LEVEL",
        // quotationLineId: 0,
        // quotationId: 0,
      });
    });

    if (formData.additionalData.instructions) {
      submissionData.docKeys.push({
        fileIndex: submissionData.docKeys.length + 0,
        module: "Quotation",
        moduleFileType: "instructions",
        level: "HEADER_LEVEL",
      });
      console.log("090999429", JSON.stringify(formData))
    }

    if (formData.additionalData.termsAndConditions) {
      submissionData.docKeys.push({
        fileIndex: submissionData.docKeys.length + 0,
        module: "Quotation",
        moduleFileType: "termsAndConditions",
        level: "HEADER_LEVEL",
      });
      console.log("090999429", JSON.stringify(formData))
    }

    console.log(JSON.stringify(submissionData));

    delete submissionData.quotationHeader.requiredDocs;
    delete submissionData.quotationHeader.quotationApprovals;
    delete submissionData.quotationHeader.siteName;
    delete submissionData.quotationHeader.costCenterName;

    delete submissionData.quotationHeader.billingAddress;

    delete submissionData.quotationHeader.deliveryAddress;




    // Create FormData object
    const formDataToSend = new FormData();
    formDataToSend.append(
      "quotationHeader",
      JSON.stringify(submissionData.quotationHeader)
    );
    formDataToSend.append(
      "quotationLines",
      JSON.stringify(submissionData.quotationLines)
    );
    formDataToSend.append(
      "requiredDocs",
      JSON.stringify(submissionData.requiredDocs)
    );
    formDataToSend.append(
      "quotationApprovals",
      JSON.stringify(submissionData.quotationApprovals)
    );
    formDataToSend.append("docKeys", JSON.stringify(submissionData.docKeys));
    formDataToSend.append(
      "additionalData",
      JSON.stringify(submissionData.additionalData)
    );

    // Append files to FormData
    // let fileIndex = 0;
    formData.docKeys.forEach((doc: any) => {
      if (doc.file) {
        formDataToSend.append(`files`, new File([doc.file], doc.fileName));
        // fileIndex++;
      }
    });

    formData.attachments?.forEach((attachment: any) => {
      if (attachment.originFileObj) {
        formDataToSend.append(
          `files`,
          attachment.originFileObj,
          attachment.name
        );
        // fileIndex++;
      }
    });


    console.log("formData.additionalData.instructions", formData.additionalData.instructions)

    if (formData.additionalData.instructions) {
      const instructionsFile = new Blob([formData.additionalData.instructions], {
        type: "text/html",
      });
      formDataToSend.append(
        `files`,
        new File([instructionsFile], "instructions.html", {
          type: "text/html",
        })
      )
    }

    if (formData.additionalData.termsAndConditions) {
      const termsAndConditionsFile = new Blob([formData.additionalData.termsAndConditions], {
        type: "text/html",
      });
      formDataToSend.append(
        `files`,
        new File([termsAndConditionsFile], "termsAndConditions.html", {
          type: "text/html",
        })
      )
    }

    // return
    // Log the formDataToSend for debugging
    console.log("FormData to send:");
    for (const [key, value] of Array.from(formDataToSend.entries())) {
      if (key.includes("files")) {
        console.log(key, value);
      } else {
        console.log(key, JSON.parse(value as string)); // parsing JSON string back to object for readability
      }
    }



    createQuotations(formDataToSend);
  };

  const createQuotations = async (data: any) => {
    console.log("data:::Creation", data);
    try {
      const response: any = await quotationApis.quotationsCreationMethod(data);
      const { status, error }: any = response;
      if (status === true) {
        Swal.fire("Created!", "Quotation has been created", "success");
        navigate(`/view-rfqs`);
      }
      else {
        Swal.fire("Error!", error, "error");
      }
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  const billingAddressContent = formData?.quotationHeader?.billingAddress ? (
    <div className="billingAddress_align">
      <p>
        {formData.quotationHeader.billingAddress.addressLine1},{" "}
        {formData.quotationHeader.billingAddress.addressLine2}
      </p>
      <p>
        {formData.quotationHeader.billingAddress.cityName},{" "}
        {formData.quotationHeader.billingAddress.stateName},{" "}
        {formData.quotationHeader.billingAddress.countryName} -{" "}
        {formData.quotationHeader.billingAddress.pincode}
      </p>
    </div>
  ) : null;

  const orderDeliveryAddressContent = formData?.quotationHeader
    ?.deliveryAddress ? (
    <div className="billingAddress_align">
      <p>
        {formData.quotationHeader.deliveryAddress.addressLine1},{" "}
        {formData.quotationHeader.deliveryAddress.addressLine2}
      </p>
      <p>
        {formData.quotationHeader.deliveryAddress.cityName},{" "}
        {formData.quotationHeader.deliveryAddress.stateName},{" "}
        {formData.quotationHeader.deliveryAddress.countryName} -{" "}
        {formData.quotationHeader.deliveryAddress.pincode}
      </p>
    </div>
  ) : null;

  const approvalTimelineContent = formData?.quotationHeader
    ?.quotationApprovals ? (
    <Timeline className="approval-timeline">
      {formData.quotationHeader.quotationApprovals.map(
        (approval: ApprovalData, index: number) => (
          <Timeline.Item key={index}>
            {approval.userName}
            <div className="designation">{approval.roleName}</div>
          </Timeline.Item>
        )
      )}
    </Timeline>
  ) : null;

  const requiredDocsContent = formData?.quotationHeader?.requiredDocs ? (
    <Table
      dataSource={formData.quotationHeader.requiredDocs.map(
        (doc: any, index: number) => ({
          key: index + 1,
          sNo: index + 1,
          documentName: doc.documentName,
          mandatory: doc.mandatory ? "Yes" : "No",
        })
      )}
      columns={[
        {
          title: "S.No",
          dataIndex: "sNo",
          key: "sNo",
        },
        {
          title: "Document Name",
          dataIndex: "documentName",
          key: "documentName",
        },
        {
          title: "Mandatory to Upload",
          dataIndex: "mandatory",
          key: "mandatory",
        },
      ]}
      pagination={false}
      size="small"
      style={{ minWidth: "200px" }}
    />
  ) : null;

  useEffect(() => {
    console.log(JSON.stringify(formData));
  }, []);

  const columns = [
    {
      title: "MATERIAL ID",
      dataIndex: "materialID",
      key: "materialID",
      fixed: "left" as const,
      width: 120,
    },
    {
      title: "CATEGORY",
      dataIndex: "categoryName",
      key: "categoryName",
      fixed: "left" as const,
      width: 120,
    },
    {
      title: "MATERIAL NAME",
      dataIndex: "materialName",
      key: "materialName",
      width: 150,
    },
    // {
    //   title: "DESCRIPTION",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 200,
    // },
    {
      title: "UOM",
      dataIndex: "uomName",
      key: "uomName",
    },
    {
      title: "QTY",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "NEED BY DATE",
      dataIndex: "needByDate",
      key: "needByDate",
    },
  ];

  // const suppliers = Object.keys(formData.additionalData || {})
  //   .filter(
  //     (key) => key.startsWith("supplier_") && formData.additionalData[key]
  //   )
  //   .map((key) => key.replace("supplier_", "Supplier "));


  const suppliers: any = formData.SupplierNamesSelected;
  console.log("suppliers", suppliers)

  return (
    <div className="content clearfix">
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="General Details" key="1">
          <Descriptions title="" className="custom-description-title">
            <Descriptions.Item label="Quotation For">
              {formData?.additionalData?.quotationFor}
            </Descriptions.Item>
            <Descriptions.Item label="Description">
              {formData?.additionalData?.quotationDescription}
            </Descriptions.Item>
            <Descriptions.Item label="Cost Center">
              {formData?.quotationHeader?.costCenterName}
            </Descriptions.Item>
            <Descriptions.Item label="Last Submission Date">
              <span className="badge-dim badge bg-primary rounded-pill">
                <strong>
                  {dayjs(formData?.quotationHeader?.lastSubmissionDate).format("DD-MM-YYYY")}
                </strong>
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Time Limit">
              {formData.quotationHeader?.deadlineType}
            </Descriptions.Item>
            <Descriptions.Item label="Site Name">
              {formData.quotationHeader?.siteName}
            </Descriptions.Item>
            <Descriptions.Item label="Approvals">
              {formData.quotationHeader?.approvalRequired === "Y" ? (
                <Popover
                  content={approvalTimelineContent}
                  title={
                    <span style={{ color: "#2263b3" }}>Approval Hierarchy</span>
                  }
                >
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Yes
                  </span>
                </Popover>
              ) : (
                "No"
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Additional Charges">
              {" "}
              {formData.quotationHeader?.additionalCharges}
            </Descriptions.Item>
            <Descriptions.Item label="Auto Release">
              {formData.quotationHeader?.autoRelease === "Y" ? "Yes" : "No"}
            </Descriptions.Item>
            <Descriptions.Item label="Billing Address">
              <Popover
                content={billingAddressContent}
                title={
                  <span style={{ color: "#2263b3" }}>Billing Address</span>
                }
              >
                <span
                  style={{
                    color: "#2263b3",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {
                    formData.quotationHeader?.billingAddress
                      ?.deliveryLocationName
                  }
                </span>
              </Popover>
            </Descriptions.Item>
            <Descriptions.Item label="Delivery Address">
              {formData.quotationHeader?.deliveryLevelType === "LINE_LEVEL"
                ? "Line Level"
                : "Order Level"}
            </Descriptions.Item>
            <Descriptions.Item label="Documents Required From Supplier">
              {formData.quotationHeader?.supplierDocsRequired === "Y" ? (
                <Popover
                  content={requiredDocsContent}
                  title={
                    <span style={{ color: "#2263b3" }}>Required Documents</span>
                  }
                >
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Yes
                  </span>
                </Popover>
              ) : (
                "No"
              )}
            </Descriptions.Item>
            {formData.quotationHeader?.deliveryLevelType === "ORDER_LEVEL" && (
              <Descriptions.Item label="Delivery Address">
                <Popover
                  content={orderDeliveryAddressContent}
                  title={
                    <span style={{ color: "#2263b3" }}>Delivery Address</span>
                  }
                >
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {
                      formData.quotationHeader?.deliveryAddress
                        ?.deliveryLocationName
                    }
                  </span>
                </Popover>
              </Descriptions.Item>
            )}
          </Descriptions>
        </Panel>
        <Panel header="Materials" key="2">
          <Table
            columns={columns}
            dataSource={formData.materialsData || []}
            scroll={{ x: "max-content" }}
            expandable={{
              expandedRowRender: (record: Material) => (
                <div className="expandableBody">
                  <div style={{ flex: 1, textAlign: "left" }}>
                    <p style={{ margin: 0 }}>{record.description}</p>
                    {record.fileName && record.file && (
                      <div>
                        <PaperClipOutlined /> &nbsp;
                        <a
                          href={URL.createObjectURL(record.file)}
                          download={record.fileName}
                        >
                          {record.fileName}
                        </a>
                      </div>
                    )}
                  </div>
                  {formData.quotationHeader?.deliveryLevelType ===
                    "LINE_LEVEL" &&
                    record.deliveryAddressDetails && (
                      <div className="lineAddress" style={{ flex: 1 }}>
                        <h6>Delivery Address</h6>
                        <ul>
                          <li>{record.deliveryAddressDetails.addressLine1}</li>
                          <li>{record.deliveryAddressDetails.addressLine2}</li>
                          <li>
                            {record.deliveryAddressDetails.cityName},{" "}
                            {record.deliveryAddressDetails.stateName},{" "}
                            {record.deliveryAddressDetails.countryName} -{" "}
                            {record.deliveryAddressDetails.pincode}
                          </li>
                        </ul>
                      </div>
                    )}
                </div>
              ),
            }}
            bordered
            size="small"
            pagination={false}
            locale={{ emptyText: "No materials added" }}
          />
        </Panel>
        <Panel header="Other Information" key="3">
          <Descriptions
            title="Other Information"
            className="custom-description-title"
          >
            <Descriptions.Item label="Suppliers">
              <List
                size="small"
                dataSource={suppliers}
                renderItem={(item: any) => <List.Item>{item}</List.Item>}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Instructions">
              <span
                style={{
                  color: "#2263b3",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setInstructionsVisible(true)}
              >
                View Instructions
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Terms and Conditions">
              <span
                style={{
                  color: "#2263b3",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setTermsVisible(true)}
              >
                View Terms and Conditions
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Attachments">
              {formData.attachments?.map((attachment: any, index: number) => (
                <div key={index}>
                  <PaperClipOutlined /> &nbsp;
                  <a
                    href={URL.createObjectURL(attachment.originFileObj)}
                    download={attachment.name}
                  >
                    {attachment.name}
                  </a>
                </div>
              ))}
            </Descriptions.Item>
          </Descriptions>
        </Panel>
      </Collapse>
      <Modal
        title="Instructions"
        visible={instructionsVisible}
        footer={null}
        onCancel={() => setInstructionsVisible(false)}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: formData.additionalData?.instructions || "",
          }}
        />
      </Modal>
      <Modal
        title="Terms and Conditions"
        visible={termsVisible}
        footer={null}
        onCancel={() => setTermsVisible(false)}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: formData.additionalData?.termsAndConditions || "",
          }}
        />
      </Modal>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </li>
          <li>
            <Button
              color="secondary"
              onClick={props.prev}
              className="btn-dim btn btn-secondary"
            >
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default React.memo(RFQPreview);
