import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import { Popover } from "antd";
import { EditOutlined } from '@ant-design/icons';
import QuotationApprovalDrawer from "./QuotationApprovalDrawer";
import AskDocuments from "./AskDocuments";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import sites from "../../../api/master/sites";
import costCenterApis from "../../../api/master/costCenter";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import { useFormData } from './FormDataContext'; // Import your context hook
import { AddressDetails, SelectOptions, ApprovalData, DocumentFormValues } from './types'; // Import your types
import usersApis from "../../../api/master/users";

const RFQGeneralDetails: React.FC = (props: any) => {
  const { handleSubmit, formState: { errors }, watch,setValue } = useFormContext();
  const { formData, formState, updateFormData, updateFormState } = useFormData(); // Use the context to access formData and formState

  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>([]);
  const [billingAddressList, setBillingAddressList] = useState<AddressDetails[]>([]);
  const [billingAddressOptions, setBillingAddressOptions] = useState<SelectOptions[]>([]);

  const [prefferedStates, setPrefferedStates] = useState<any>([]);

  const submitForm = (data: any) => {
    if (Object.keys(errors).length === 0) {
      // Get selected siteName and costCenterName
      const selectedSite = sitesOptions.find(option => option.value === data.siteId);
      const selectedCostCenter = costCenterOptions.find(option => option.value === data.costCenter);

      // Get selected billingAddress and deliveryAddress details
      const selectedBillingAddress = billingAddressList.find(addr => addr.id.toString() === data.billingAddress);
      const selectedDeliveryAddress = billingAddressList.find(addr => addr.id.toString() === data.deliveryAddress);

      // Update formData with additional details
      updateFormData('quotationHeader', {
        ...data,
        siteName: selectedSite ? selectedSite.label : '',
        costCenterName: selectedCostCenter ? selectedCostCenter.label : '',
        billingAddress: selectedBillingAddress,
        deliveryAddress: selectedDeliveryAddress,
        quotationApprovals: formState.approvalData?.map(approval => ({
          roleId: approval.roleId,
          level: approval.level,
          userId: approval.userId,
          userName: approval?.userName,
          roleName: approval?.roleName
        })),
        requiredDocs: formState.documentData?.documents?.map(doc => ({
          documentName: doc.documentName,
          mandatory: doc.mandatory,
        }))
      });

      // Handle next step navigation here if necessary
      props.next();
    } else {
      console.log("Form Errors:", errors);
    }
  };

  const onBillingAddressChange = (value: string) => {
    const foundAddress = billingAddressList.find(addr => addr.id.toString() === value) || null;
    updateFormState({ selectedBillingAddress: foundAddress });
  };

  const handleDeliveryAddressTypeChange = (value: string) => {
    const visibility = value === 'ORDER_LEVEL';
    updateFormState({ deliveryAddressVisibility: visibility });
    if (visibility) updateFormState({ selectedDeliveryAddress: null });
  };

  const handleDeliveryAddressChange = (value: string) => {
    const foundAddress = billingAddressList.find((addr) => addr.id.toString() === value) || null;
    updateFormState({ selectedDeliveryAddress: foundAddress });
  };

  const fetchCurrentUserInfo = async () => {
    try {
      const res = await usersApis.getCurrentUser();

      if (res) {
        const { prefferedStates } = res;
        setPrefferedStates(prefferedStates?.map((item: any) => item?.stateID));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showApprovalDrawer = () => {
    fetchCurrentUserInfo();
    updateFormState({ isApprovalDrawerOpen: true });
  };

  const onApprovalClose = () => {
    updateFormState({ isApprovalDrawerOpen: false });
  };

  const handleApprovalData = (data: ApprovalData[]) => {
    updateFormState({ approvalData: data, approvalSubmissionStatus: true, approvalPopoverVisible: false });
    onApprovalClose();
  };

  const handleDocumentsSubmit = (data: DocumentFormValues) => {
    updateFormState({ documentData: data, documentsPopoverVisible: false });
    onAskDocumentsClose();
  };

  const showAskDocumentsDrawer = () => {
    updateFormState({ isAskDocumentsDrawerOpen: true });
  };

  const onAskDocumentsClose = () => {
    updateFormState({ isAskDocumentsDrawerOpen: false });
  };

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser()
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCostCenter = async () => {
    try {
      const { data, status } = await costCenterApis.fetchCostCenter();
      if (status) {
        const costCenterOptions = data.map((item: any) => ({
          label: item.costCenterName,
          value: item.id.toString(),
        }));
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAddresses = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        const addressOptions = data.map((item: any) => ({
          label: item.deliveryLocationName,
          value: item.id.toString(),
        }));
        setBillingAddressList(data);
        setBillingAddressOptions(addressOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSites();
    fetchCostCenter();
    fetchAddresses();
    setValue("autoRelease", "Y");
  }, []);

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="3">
            <FormSelect
              name="quotationFor"
              label="Quotation For"
              options={[{ label: 'Material', value: 'Material' }, { label: 'Service', value: 'Service' }]}
              required={true}
              placeholder="Select Quotation Type"
            />
          </Col>
          <Col lg="9">
            <FormInput
              name="quotationDescription"
              label="Quotation Description"
              required={true}
              type="text"
              minLength={2}
              maxLength={100}
              placeholder="Enter Description"
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="costCenter"
              label="Cost Center"
              options={costCenterOptions}
              required={true}
              placeholder="Select Cost Center"
            />
          </Col>
          <Col lg="3">
            <FormDatePicker
              name='lastSubmissionDate'
              label="Last Submission Date"
              required={true}
              placeholder="Select Date"
              minDate={new Date()}
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="deadlineType"
              label="Time limit"
              options={[{ label: 'Soft', value: 'SOFT' }, { label: 'Hard', value: 'HARD' }]}
              required={true}
              placeholder="Select"
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="siteId"
              label="Site Name"
              options={sitesOptions}
              required={true}
              placeholder="Select"
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="approvalRequired"
              label="Approval Required?"
              options={[{ label: 'Yes', value: 'Y' }, { label: 'No', value: 'N' }]}
              required={true}
              placeholder="Select"
              onChange={(val) => updateFormState({ showApprovals: val === 'Y' })}
            />
            {formState.showApprovals && (
              <Popover
                content="Click here to set required approvals"
                title="Approval Required"
              >
                <a href='#' className="success" onClick={(e) => {
                  e.preventDefault();
                  showApprovalDrawer();
                }}>
                  <EditOutlined /> Set Approvals &nbsp;
                  {
                    formState.approvalPopoverVisible ? <span className="mandatory">Please set approvals</span> : <></>
                  }
                </a>
              </Popover>
            )}
          </Col>

          <Col lg="3">
            <FormSelect
              name="additionalCharges"
              label="Additional Charges?"
              options={[{ label: 'Fixed', value: 'Fixed' }, { label: 'Flexible', value: 'Flexible' }]}
              required={true}
              placeholder="Select"
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="autoRelease"
              label="Auto Release"
              defaultValue={{
                label: "Yes",
                value: "Y",
              }}
              options={[{ label: 'Yes', value: 'Y' }, { label: 'No', value: 'N' }]}
              required={true}
              placeholder="Select"
            />
          </Col>
          <Col lg="3">
            <FormSelect
              name="supplierDocsRequired"
              label="Require Documents from supplier?"
              options={[{ label: 'Yes', value: 'Y' }, { label: 'No', value: 'N' }]}
              required={true}
              placeholder="Select"
              onChange={(val) => updateFormState({ showAskDocs: val === 'Y' })}
            />
            {formState.showAskDocs && (
              <Popover
                content="Click here to ask required documents"
                title="Documents Required"
              >
                <a href='#' className="success" onClick={(e) => {
                  e.preventDefault();
                  showAskDocumentsDrawer();
                }}>
                  <EditOutlined /> Ask Required Documents &nbsp;
                  {
                    formState.documentsPopoverVisible ? <span className="mandatory">Please set documents</span> : <></>
                  }
                </a>
              </Popover>
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="3">
            <FormSelect
              name="billingAddress"
              label="Billing Address"
              options={billingAddressOptions}
              required={true}
              placeholder="Select"
              onChange={onBillingAddressChange}
            />
            {formState.selectedBillingAddress && (
              <Popover content={(
                <ul>
                  <li>{formState.selectedBillingAddress.addressLine1} {formState.selectedBillingAddress.addressLine2}</li>
                  <li>{formState.selectedBillingAddress.cityName}, {formState.selectedBillingAddress.stateName}, {formState.selectedBillingAddress.countryName} - {formState.selectedBillingAddress.pincode}</li>
                </ul>
              )} title="Selected Address">
                <a>View Selected Billing Address</a>
              </Popover>
            )}
          </Col>
          <Col lg="3">
            <FormSelect
              name="deliveryLevelType"
              label="Delivery Address at"
              options={[{ label: 'Order Level', value: 'ORDER_LEVEL' }, { label: 'Line Level', value: 'LINE_LEVEL' }]}
              required={true}
              placeholder="Select"
              onChange={handleDeliveryAddressTypeChange}
            />
          </Col>
          {formState.deliveryAddressVisibility && (
            <Col lg="3">
              <FormSelect
                name="deliveryAddress"
                label="Delivery Address"
                options={billingAddressOptions}
                required={true}
                placeholder="Select"
                onChange={handleDeliveryAddressChange}
              />
              {formState.selectedDeliveryAddress && (
                <Popover content={(
                  <ul>
                    <li>{formState.selectedDeliveryAddress.addressLine1} {formState.selectedDeliveryAddress.addressLine2}</li>
                    <li>{formState.selectedDeliveryAddress.cityName}, {formState.selectedDeliveryAddress.stateName}, {formState.selectedDeliveryAddress.countryName} - {formState.selectedDeliveryAddress.pincode}</li>
                  </ul>
                )} title="Selected Address">
                  <a>View Selected Delivery Address</a>
                </Popover>
              )}
            </Col>
          )}
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
          </ul>
        </div>
      </form>
      <QuotationApprovalDrawer
        open={formState.isApprovalDrawerOpen}
        onClose={onApprovalClose}
        onSubmit={handleApprovalData}
        stateIds={prefferedStates}
        setApprovalSubmissionStatus={(status: boolean) => updateFormState({ approvalSubmissionStatus: status })}
        approvalData={formState.approvalData || []} // Pass the current approval data
      />
      <AskDocuments
        open={formState.isAskDocumentsDrawerOpen}
        onClose={onAskDocumentsClose}
        onSubmit={handleDocumentsSubmit}
        documentData={formState.documentData || { documents: [{ documentName: "", description: "", mandatory: false }] }} // Pass the current document data
      />
    </>
  );
};

export default React.memo(RFQGeneralDetails);
