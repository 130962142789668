import React, { useState, useEffect, useMemo, FC, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Badge, Card } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  RSelect,
  UserAvatar,
  TooltipComponent,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
import axios from "../../../axios";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal, ModalBody } from "reactstrap";
import DatePicker from "react-datepicker";
import { getDateStructured } from "../../../utils/Utils";
import supplierApis from "../../../api/master/supplier";
import { useNavigate } from "react-router-dom";
import FormatDate from "../DateFormater";
import usersApis from "../../../api/master/users";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import moment from "moment";

interface SelectOptions {
  label: string;
  value: string;
}
const ViewSuppliersDetails: FC = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let { siteId } = useParams();
  const [data, setData] = useState<any>();
  const [modalGI, setGIModal] = useState(false);
  const [modalEditBlockName, setModalEditBlockName]: any = useState("");

  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);
  const methods = useForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = methods;

  // const onmaterialChangeWatch = watch("materials");

  const [formData, setFormData] = useState<any>({
    referenceID: "",
    supplierName: "",
    // industryType: "",
    supplierCategory: "",
    incorporationDate: "",
    pan: "",
    gstin: "",
    lastFYTurnover: "",
    contactPerson: "",
    phoneNumber: "",
    email: "",
    country: "",
    state: "",
    city: "",
    area: "",
    pincode: "",
    accountNumber: "",
    bankName: "",
    branch: "",
    ifscCode: "",
    minSupply: "",
    deliveryLeadDays: "",
    paymentTerms: "",
    // supplyStates: "",
    accountHolderName: "",
    supplyStates: [],
    cin: "",
    categories: [],
    materials: [],
  });
  const [docs, setDocs] = useState<any>([]);
  const cancelledChequeWatch = watch("cancelledCheque");
  const submitForm = (submitData: any) => {
    const formData: any = new FormData();
    formData.append("supplierData", JSON.stringify(submitData));
    formData.append("files", JSON.stringify([]));
    formData.append(
      "filesData",
      JSON.stringify({
        newfiledata: [],
        deletedFileData: [],
        updatedFileData: [],
      })
    );
    submitUpdates(formData);
    setGIModal(false);
  };

  const [categories, setCategories] = useState<SelectOptions[]>([]);
  const [industryTypeOptions, setIndustryTypeOptions] = useState<
    SelectOptions[]
  >([]);
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  const fetchSupplierCategory = async () => {
    try {
      const { data, status, message } =
        await supplierApis.fetchSupplierCategory();
      if (status) {
        console.log(data);
        setCategories(
          data.map((obj: any) => ({
            label: obj.category,
            value: obj.id.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  const fetchIndustryType = async () => {
    try {
      const { data, status, message } =
        await supplierApis.getContructionsMaterialTypes();
      if (status) {
        console.log(data);
        setIndustryTypeOptions(
          data.map((obj: any) => ({
            label: obj.name,
            value: obj.material_id.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  const getCountries = async () => {
    try {
      const _countries = await usersApis.getCountrys();
      if (_countries.status) {
        if (_countries.data.length > 0) {
          const transformedData = _countries.data.map((item: any) => ({
            value: item.countryId.toString(),
            label: item.countryName,
          }));
          setCountries(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) {
          return;
        }
      }
    } catch (error) {}
  };
  const getDistricts = async (stateID: any) => {
    try {
      const districts = await usersApis.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    viewChange();

    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    const id = siteId;
    if (id !== undefined || null || "") {
      fetchSupplierData(id);
      fetchSupplierCategory();
      fetchIndustryType();
      getCountries();
    }
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [siteId]);

  const openEditModal = (modalEditBlockName: any) => {
    setModalEditBlockName(modalEditBlockName);
    setGIModal(true);
  };
  const docUploadSubmit = (data: any) => {
    const formData: any = new FormData();
    if (data?.cancelledCheque) {
      const filesData = [];
      const deletedFileData: any = [];
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "supplier",
        purpose: "cancled bank cheque",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      formData.append(`files`, data?.cancelledCheque[0]);

      docs.forEach((doc:any) => {
        deletedFileData.push({
          docId: doc?.docId,
        });
      });
      
    
      
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: filesData,
          deletedFileData: deletedFileData,
          updatedFileData: [],
        })
      );
    } else {
      formData.append("files", JSON.stringify([]));
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: [],
          deletedFileData: [],
          updatedFileData: [],
        })
      );
    }
    delete data?.cancelledCheque;
    formData.append("supplierData", JSON.stringify(data));
    setGIModal(false);
    submitUpdates(formData);
    setValue("cancelledCheque", null);
  };

  const submitUpdates = async (submitData: any) => {
    const { status, message } = await supplierApis.updateSupplierById(
      siteId,
      submitData
    );
    if (status) {
      Swal.fire("Updated!", "Supplier has been updated", "success");
      const id = siteId;
      console.log("id", id);
      if (id !== undefined || null || "") {
        fetchSupplierData(id);
      }
    } else {
      Swal.fire("Failed!", message, "error");
    }
  };

  const fetchSupplierData = async (id: any) => {
    try {
      const { data, status, message } = await supplierApis.fetchSupplierById(
        id
      );
      if (status) {
        console.log("status", data);
        const newData = data;
        // if (data.docs) {
        //   let newDocs = data?.docs?.find(
        //     (document: any) => document.moduleFileType == "cancled bank cheque"
        //   );
        //   setDocs(newDocs);
        // }
        setDocs(data.docs);
        setData(newData); // Update data with fetched response
        setFormData({
          referenceID: newData.referenceID,
          supplierName: newData.supplierName,
          categories: newData.categories,
          materials: newData.materials,
          incorporationDate: newData.incorporationDate,
          pan: newData.pan,
          gstin: newData.gstin,
          lastFYTurnover: newData.lastFYTurnover,
          contactPerson: newData.contactPerson,
          phoneNumber: newData.phoneNumber,
          email: newData.email,
          country: newData.country,
          state: newData.state,
          city: newData.city,
          area: newData.area,
          pincode: newData.pincode,
          accountNumber: newData.accountNumber,
          bankName: newData.bankName,
          branch: newData.branch,
          ifscCode: newData.ifscCode,
          minSupply: newData.minSupply,
          deliveryLeadDays: newData.deliveryLeadDays,
          paymentTerms: newData.paymentTerms,
          // supplyStates: newData.supplyStates,
          accountHolderName: newData.accountHolderName,
          supplyStates: newData.supplyStates,
          cin: newData.cin,
          creditDays: newData.creditDays,
          // categories: newData.categories,
          // materials: newData.materials,
        });
        setValue("referenceID", newData.referenceID);
        setValue("supplierName", newData.supplierName);
        // setValue("supplierCategory", newData.supplierCategory);
        setValue("incorporationDate", moment(newData.incorporationDate));
        setValue("pan", newData.pan);
        setValue("gstin", newData.gstin);
        setValue("lastFYTurnover", newData.lastFYTurnover);
        setValue("contactPerson", newData.contactPerson);
        setValue("phoneNumber", newData.phoneNumber);
        setValue("email", newData.email);
        setValue("country", newData.country.countryId);
        setValue("state", newData.state.stateId);
        setValue("city", newData.city);
        setValue("area", newData.area);
        setValue("pincode", newData.pincode);
        setValue("accountNumber", newData.accountNumber);
        setValue("bankName", newData.bankName);
        setValue("branch", newData.branch);
        setValue("ifscCode", newData.ifscCode);
        setValue("minSupply", newData.minSupply);
        setValue("deliveryLeadDays", newData.deliveryLeadDays);
        setValue("paymentTerms", newData.paymentTerms);
        setValue("accountHolderName", newData.accountHolderName);
        setValue("creditDays", newData.creditDays);
        setValue(
          "supplyStates",
          newData.supplyStates.map((item: any) => item.stateID)
        );
        setValue("cin", newData.cin);
        setValue(
          "categories",
          newData.categories.map((item: any) => item.categoryID)
        );
        setValue(
          "materials",
          newData.materials.map((item: any) => item.materialID)
        );
        // setValue("categories", newData.categories);
        // setValue("materials", newData.materials);
        fetchIndustryType();
        fetchStatesByCountry(newData.country.countryId, newData.state.stateId);
        getDistricts(newData.state.stateId);
      }
    } catch (err: any) {
      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  return (
    <React.Fragment>
      <Head title="Supplier Details - Regular"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Supplier Details -{" "}
                    <strong className="text-primary small">
                      {data["supplierName"]}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      <li>
                        Created At:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <Link to={`${process.env.PUBLIC_URL}/suppliers`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Row className="gy-5">
                <Col
                  lg="5"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">General Information</BlockTitle>
                        <p>Name, Address etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("GeneralInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Supplier ID</div>
                          <div className="data-value">{data.referenceID}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Supplier Name</div>
                          <div className="data-value">{data.supplierName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Material Type</div>
                          <div className="data-value">
                            {formData.materials
                              .map((material: any) => material.materialName)
                              .join(", ")}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Supply Categories</div>
                          <div className="data-value">
                            {formData.categories
                              .map((categories: any) => categories.categoryName)
                              .join(", ")}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Min.Supply</div>
                          <div className="data-value">{data.minSupply}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Delivery Lead Days</div>
                          <div className="data-value">
                            {data.deliveryLeadDays}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Payment Terms</div>
                          <div className="data-value">{data.paymentTerms}</div>
                        </div>
                      </li>
                      {data.paymentTerms == "Credit" && (
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Credit Days</div>
                            <div className="data-value">{data.creditDays}</div>
                          </div>
                        </li>
                      )}
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Supply States</div>
                          <div className="data-value">
                            {formData.supplyStates
                              .map(
                                (supplyStates: any) => supplyStates.stateName
                              )
                              .join(", ")}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Legal Information</BlockTitle>
                        <p>PAN, GSTIN etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("LegalInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">CIN</div>
                          <div className="data-value">{data.cin}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Incorporation Date</div>
                          <div className="data-value">
                            {FormatDate(data.incorporationDate)}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">PAN</div>
                          <div className="data-value">{data.pan}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">GSTIN</div>
                          <div className="data-value">{data.gstin}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Last FY Turn over</div>
                          <div className="data-value">
                            {data.lastFYTurnover}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>

                <Col
                  lg="7"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Contact Information</BlockTitle>
                        <p>Basic Contact Information Details etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("ContactInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contact Person</div>
                          <div className="data-value">{data.contactPerson}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Phone Number</div>
                          <div className="data-value">{data.phoneNumber}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Email</div>
                          <div className="data-value">{data.email}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Country</div>
                          <div className="data-value">
                            {
                              countries.find(
                                (c) => c.value == data.country.countryId
                              )?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">State</div>
                          <div className="data-value">
                            {
                              states.find((s) => s.value == data.state.stateId)
                                ?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">City</div>
                          <div className="data-value">{data.city}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Area</div>
                          <div className="data-value">{data.area}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Zip/Pincode</div>
                          <div className="data-value">{data.pincode}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Bank Information</BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("BankInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Account Holder Name</div>
                          <div className="data-value">
                            {data.accountHolderName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Account Number</div>
                          <div className="data-value">{data.accountNumber}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Bank Name</div>
                          <div className="data-value">{data.bankName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Branch</div>
                          <div className="data-value">{data.branch}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">IFSC Code</div>
                          <div className="data-value">{data.ifscCode}</div>
                        </div>
                      </li>
                      {docs?.map((document: any) => {
                        // if (document.moduleFileType !== "cancled bank cheque")
                        //   return <></>;
                        return (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                {document.moduleFileType}
                              </div>
                              <span className="data-value">
                                <a
                                  href={document.filePath}
                                  target="_blank"
                                  style={{
                                    pointerEvents: "auto",
                                    cursor: "default",
                                  }}
                                >
                                  {document.fileName}
                                </a>
                              </span>
                            </div>
                          </li>
                        );
                      })}
                      {/* <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Cancelled Cheque</div>
                          <div className="data-value">
                            <a
                              href={docs?.filePath}
                              target="_blank"
                              style={{
                                pointerEvents: "auto",
                                cursor: "default",
                              }}
                            >
                              {docs?.fileName}
                            </a>
                          </div>
                        </div>
                      </li> */}
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Block>

            <Modal
              isOpen={modalGI}
              className="modal-dialog-centered"
              size="xl"
              toggle={() => setGIModal(false)}
              backdrop="static"
            >
              <a
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setGIModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>

              {modalEditBlockName === "GeneralInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit General Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="referenceID"
                                  label="Supplier ID"
                                  required={true}
                                  type="text"
                                  minLength={2}
                                  maxLength={30}
                                  placeholder="Enter Supplier ID"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="supplierName"
                                  label="Supplier Name"
                                  required={true}
                                  type="text"
                                  minLength={2}
                                  maxLength={30}
                                  placeholder="Enter Supplier Name"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormMultiSelect
                                  name="materials"
                                  label="Material Type"
                                  required={true}
                                  options={industryTypeOptions}
                                  placeholder="Select Material Type"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <FormMultiSelect
                                name="categories"
                                label="Supply Categories"
                                required={true}
                                options={categories}
                                placeholder="Select Supply Categories"
                              />
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  type="number"
                                  label="Min.Supply"
                                  name="minSupply"
                                  defaultValue={formData.minSupply}
                                  placeholder="Enter Min.Supply"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  type="number"
                                  name="deliveryLeadDays"
                                  required={true}
                                  label="Delivery Lead Days"
                                  defaultValue={formData.deliveryLeadDays}
                                  placeholder="Enter Delivery Lead Days"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormSelect
                                  name="paymentTerms"
                                  label="Payment Terms"
                                  required={true}
                                  options={[
                                    { label: "Advance", value: "Advance" },
                                    { label: "Credit", value: "Credit" },
                                  ]}
                                  placeholder="Enter Payment Terms"
                                />
                              </div>
                            </Col>
                            {watch("paymentTerms") == "Credit"  && (
                              <Col lg="6">
                                <FormInput
                                  name="creditDays"
                                  label="Credit Days"
                                  required={true}
                                  defaultValue={formData.creditDays}
                                  type="number"
                                  placeholder="Enter Credit Days"
                                />
                              </Col>
                            )}
                            <Col md="6">
                              <FormMultiSelect
                                name="supplyStates"
                                label="Supply State"
                                options={states}
                                placeholder="Select Supply State"
                              />
                            </Col>
                          </Row>
                          <Row className="gy-4 mt-2">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    // onClick={() => submitForm()}
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "LegalInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Legal Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="cin"
                                  label="CIN"
                                  type="text"
                                  disabled
                                  defaultValue={formData.cin}
                                  placeholder="Enter pan"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                {/* <label
                                  className="form-label"
                                  htmlFor="incorporationDate"
                                >
                                  Incorporation Date
                                </label> */}
                                <FormDatePicker
                                  name="incorporationDate"
                                  label="Incorporation Date"
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  label="PAN"
                                  disabled
                                  name="pan"
                                  defaultValue={formData.pan}
                                  placeholder="Enter pan"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  label="GSTIN"
                                  disabled
                                  name="gstin"
                                  defaultValue={formData.gstin}
                                  placeholder="Enter GSTIN"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  type="number"
                                  label="Last FY Turn over"
                                  name="lastFYTurnover"
                                  defaultValue={formData.lastFYTurnover}
                                  placeholder="Enter Last FY Turn over"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="gy-4 mt-1">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "ContactInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Contact Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  name="contactPerson"
                                  label="Contact Person"
                                  type="text"
                                  defaultValue={formData.contactPerson}
                                  placeholder="Enter Contact Person"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="number"
                                  label="Phone Number"
                                  name="phoneNumber"
                                  defaultValue={formData.phoneNumber}
                                  placeholder="Enter Phone Number"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  label="Email"
                                  name="email"
                                  defaultValue={formData.email}
                                  placeholder="Enter Email"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormSelect
                                  label="Country"
                                  name="country"
                                  options={countries}
                                  // defaultValue={countries.find(
                                  //   (option) =>
                                  //     option.value == formData.country.countryId
                                  // )}
                                  onChange={(value: any) => {
                                    // setFormData({
                                    //   ...formData,
                                    //   country: value,
                                    // });
                                    fetchStatesByCountry(value);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormSelect
                                  name="state"
                                  label="State"
                                  options={states}
                                  // defaultValue={states.find(
                                  //   (option) =>
                                  //     option.value == formData.state.stateId
                                  // )}
                                  // onChange={(value: any) =>
                                  // setFormData({
                                  //   ...formData,
                                  //   state: value,
                                  // })
                                  // }
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  label="City"
                                  name="city"
                                  defaultValue={formData.city}
                                  placeholder="Enter City"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  label="Area"
                                  name="area"
                                  defaultValue={formData.area}
                                  placeholder="Enter Area"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  label="Zip/Pincode"
                                  type="number"
                                  name="pincode"
                                  defaultValue={formData.pincode}
                                  placeholder="Enter Zip/Pincode"
                                />
                              </div>
                            </Col>
                            <Col md="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "BankInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Bank Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(docUploadSubmit)}
                        >
                          <Row className="gy-4">
                            <Col lg="4">
                              <FormInput
                                name="accountHolderName"
                                type="text"
                                label="Account Holder Name"
                                required={true}
                                placeholder="Enter Account Holder Name"
                              />
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="number"
                                  required={true}
                                  name="accountNumber"
                                  label="Account Number"
                                  defaultValue={formData.accountNumber}
                                  placeholder="Enter Account Number"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  name="bankName"
                                  required={true}
                                  label="Bank Name"
                                  defaultValue={formData.bankName}
                                  placeholder="Enter Bank Name"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  name="branch"
                                  label="Branch"
                                  required={true}
                                  defaultValue={formData.branch}
                                  placeholder="Enter Branch"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  name="ifscCode"
                                  label="IFSC Code"
                                  required={true}
                                  defaultValue={formData.ifscCode}
                                  placeholder="Enter IFSC Code"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="file"
                                  required={docs?.filePath ? false : true}
                                  name="cancelledCheque"
                                  label="Upload Cancelled Cheque"
                                />
                                {cancelledChequeWatch && (
                                  <div>{cancelledChequeWatch[0]?.name}</div>
                                )}
                                {docs?.fileName}
                              </div>
                            </Col>

                            <Col md="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      reset(formData);
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default ViewSuppliersDetails;
