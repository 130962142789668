import React, { useState } from 'react';
import { Table, Select, Empty } from 'antd';
import { formatCurrency, formatCurrency2 } from '../../../utils/Utils'; // Assuming this utility formats currency
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { QuotationAnalyticsProps } from './interfaces';
const { Option } = Select;

const Top10NegotiationsByMaterials: React.FC<QuotationAnalyticsProps> = ({ site, dateRange, customDateRange,data }) => {
    // const [materialsData, setMaterialsData] = useState<any>(data || {});
    // const firstCategory = Object.keys(materialsData)[0];
    const [selectedCategory, setSelectedCategory] = useState<keyof typeof materialsData>('Steel');

    const materialsData = {
        Steel: [
            { materialID: 'mat001', material: '10mm TMT Rebars', quotationValue: 100000, negotiatedValue: 95000, percentReduced: 5, currency: 'INR' },
            { materialID: 'mat002', material: '12mm TMT Rebars', quotationValue: 120000, negotiatedValue: 115000, percentReduced: 4.17, currency: 'INR' },
            { materialID: 'mat003', material: '16mm TMT Rebars', quotationValue: 150000, negotiatedValue: 142500, percentReduced: 5, currency: 'INR' },
            { materialID: 'mat004', material: '20mm TMT Rebars', quotationValue: 180000, negotiatedValue: 170000, percentReduced: 5.56, currency: 'INR' },
            { materialID: 'mat005', material: '25mm TMT Rebars', quotationValue: 200000, negotiatedValue: 190000, percentReduced: 5, currency: 'INR' },
        ],
        Cement: [
            { materialID: 'mat006', material: 'OPC 43 Grade Cement', quotationValue: 80000, negotiatedValue: 76000, percentReduced: 5, currency: 'INR' },
            { materialID: 'mat007', material: 'OPC 53 Grade Cement', quotationValue: 85000, negotiatedValue: 80750, percentReduced: 5, currency: 'INR' },
            { materialID: 'mat008', material: 'PPC (Portland Pozzolana Cement)', quotationValue: 90000, negotiatedValue: 85500, percentReduced: 5, currency: 'INR' },
            { materialID: 'mat009', material: 'PSC (Portland Slag Cement)', quotationValue: 95000, negotiatedValue: 90250, percentReduced: 5, currency: 'INR' },
            { materialID: 'mat010', material: 'White Cement', quotationValue: 100000, negotiatedValue: 95000, percentReduced: 5, currency: 'INR' },
        ],
        Electricals: [],
        Wood: [],
        Glass: [],
    };

    const columns = [
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
           
        },
        {
            title: 'Quotation Value',
            dataIndex: 'quotationValue',
            key: 'quotationValue',
            render: (value: number, record: { currency: string }) => (
                <div>
                    {formatCurrency2(value, record.currency)}
                    <Tooltip title={formatCurrency(value, record.currency)}>
                        <InfoCircleOutlined style={{ marginLeft: 8, color: '#1890ff', fontSize:10 }} />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Negotiated Value',
            dataIndex: 'negotiatedValue',
            key: 'negotiatedValue',
            render: (value: number, record: { currency: string }) => (
                <div>
                    {formatCurrency2(value, record.currency)}
                    <Tooltip title={formatCurrency(value, record.currency)}>
                        <InfoCircleOutlined style={{ marginLeft: 8, color: '#1890ff', fontSize:10 }} />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: '% Reduced',
            dataIndex: 'percentReduced',
            key: 'percentReduced',
            render: (value: number) => (
                <span style={{ color: 'green' }}>
                    {`${value}%`}
                </span>
            ),
        },
    ];

    const handleCategoryChange = (value: keyof typeof materialsData) => {
        setSelectedCategory(value);
    };

    return (
        <div>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Top 10 Negotiations by Materials</h6>
                    <p>Insights on negotiation savings by material.</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 8 }}>Filter by:</span>
                    <Select
                        placeholder="Select Category"
                        style={{ width: 200 }}
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                    >
                        {Object.keys(materialsData).map((category) => (
                            <Option key={category} value={category}>
                                {category}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
            {materialsData[selectedCategory]?.length > 0 ? (
                <Table 
                    columns={columns} 
                    dataSource={materialsData[selectedCategory]} 
                    rowKey="materialID" 
                    pagination={{ pageSize: 5 }}  // Set maximum 5 rows per page
                    size='small' 
                    rowClassName={(record, index) => index % 2 === 0 ? '' : 'striped-row'} 
                />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                    <Empty description="No Data found for the selected category" />
                </div>
            )}
        </div>
    );
};

export default Top10NegotiationsByMaterials;
