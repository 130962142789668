import makeApiCall from "../master/axiosIndex";

const purchaseOrderCreation = {
    purchaseOrderCreationMethod: (data: any) =>
        makeApiCall({
            url: "/order-vs-billing/submitOrder",
            method: "post",
            data
        }),
    purchaseOrderUpdateMethod: (data: any,orderNumber: any) =>
        makeApiCall({
            url: `/order-vs-billing/amendmentOrder/${orderNumber}`,
            method: "post",
            data
        }),

};

export default purchaseOrderCreation