import React, { useState, useEffect, FC, useRef, useMemo } from "react";
import Content from "../../../layout/content/Content";
import { Card, Row, Col } from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Button,
  Icon,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
} from "../../../components/Component";
import MastersAside from "../MastersAside";
import Swal from "sweetalert2";
import DynamicSidebar from "../../../components/dynamicSidebar/DynamicSidebar";
import ContractorsMaster from "./ContractorsMaster";
import { Contractor } from "./types/contractors";
import ContractorsInfo from "./ContractorsInfo";
import { Tooltip as ReactTooltip } from "react-tooltip";
import contractorsApis from "../../../api/master/contractors";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Modal,
  ModalBody,
} from "reactstrap";

import { Link } from "react-router-dom";
import moment from "moment";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import EditModal from "./EditModal";
import { useNavigate } from "react-router-dom";
import Export from "../../../components/export/Export";

// import AddModal from "./AddContractors";
const Contractors: FC = () => {
  const [mobileView, setMobileView] = useState(false);
  const [activeRow, setActiveRow] = useState<any>({
    id: null,
    action: "",
  });
  const [isComponent1Visible, setIsComponent1Visible] = useState(true);

  const gridRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarContent, setSidebarContent] = useState<JSX.Element | null>(
    null
  );

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [modal, setModal] = useState<any>({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [viewModal, setViewModal] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    balance: 0,
    phone: "",
    status: "Active",
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    email: "",
    balance: 0,
    phone: "",
    status: "",
  });

  const closeModal = () => {
    setModal({ add: false });
    resetForm();
  };

  const closeEditModal = () => {
    setModal({ edit: false });
    resetForm();
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      balance: 0,
      phone: "",
      status: "Active",
    });
  };

  const onFormSubmit = (submitData: any) => {
    const { name, email, balance, phone } = submitData;
  };

  const onEditSubmit = (submitData: any) => {
    const { name, email, phone } = submitData;
  };

  const [rowData, setRowData] = useState<Contractor[]>([]);
  const [originalData, setOriginalData] = useState([]);

  const fetchData = async () => {
    const { data, message, status } = await contractorsApis.fetchContractor();
    if (status) {
      setRowData(data);
      setOriginalData(data); // Store the original data
    } else {
      Swal.fire("Error", message, "error");
    }
  };

  const handleDelete = (id: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteContractorById(id);
      }
    });
  };
  const deleteContractorById = async (id: any) => {
    const { status, message } = await contractorsApis.deleteContractorById(id);
    if (status) {
      Swal.fire("Deleted!", "Contractor has been deleted", "success");
      fetchData();
    } else {
      Swal.fire("Error", message, "error");
    }
  };

  const addContractors = () => {
    navigate(`/addContractors`);
  };

  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = originalData.filter((item: any) => {
        return item.companyName
          .toLowerCase()
          .includes(onSearchText.toLowerCase());
      });
      setRowData([...filteredObject]);
    } else {
      setRowData([...originalData]); // Reset to original data
    }
  }, [onSearchText, originalData]);

  const sortFunc = (params: any) => {
    let defaultData = rowData;
    if (params === "asc") {
      let sortedData = defaultData.sort((a: any, b: any) =>
        a.companyName.localeCompare(b.companyName)
      );
      setRowData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a: any, b: any) =>
        b.companyName.localeCompare(a.companyName)
      );
      setRowData([...sortedData]);
    }
  };
  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = rowData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const onFilterChange = (e: any) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Head title="Contractors"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Contractors
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {rowData.length} Contractors.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Export data={rowData} />
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button color="primary" onClick={() => addContractors()}>
                        <Icon name="plus"></Icon>
                        <span>Add </span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable
            className="card-stretch"
            bodyClassName={undefined}
            title={undefined}
          >
            <div className="card-inner position-relative card-tools-toggle">
              <div className={`card-search search-wrap active`}>
                <div className="card-body">
                  <div
                    className="search-content"
                    style={{ whiteSpace: "nowrap", display: "flex" }}
                  >
                    <Button className="search-back btn-icon toggle-search active">
                      <Icon name="search"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by Company Name"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="btn btn-trigger btn-icon dropdown-toggle"
                      >
                        <Icon name="setting"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end className="dropdown-menu-xs">
                        <ul className="link-check">
                          <li>
                            <span>Show</span>
                          </li>
                          <li className={itemPerPage === 10 ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setItemPerPage(10);
                              }}
                            >
                              10
                            </DropdownItem>
                          </li>
                          <li className={itemPerPage === 15 ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setItemPerPage(15);
                              }}
                            >
                              15
                            </DropdownItem>
                          </li>
                        </ul>
                        <ul className="link-check">
                          <li>
                            <span>Order</span>
                          </li>
                          <li className={sort === "dsc" ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSortState("dsc");
                                sortFunc("dsc");
                              }}
                            >
                              DESC
                            </DropdownItem>
                          </li>
                          <li className={sort === "asc" ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSortState("asc");
                                sortFunc("asc");
                              }}
                            >
                              ASC
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
            </div>

            <DataTableBody compact className={undefined} bodyclass={undefined}>
              <DataTableHead>
                <DataTableRow className={undefined} size={undefined}>
                  <span className="sub-text">#</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Internal ID</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">General Contractor Name</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">GSTIN</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Contact Person</span>
                </DataTableRow>
                <DataTableRow size="sm" className={undefined}>
                  <span className="sub-text">Phone Number</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Email</span>
                </DataTableRow>
                {/* <DataTableRow size="md" className={undefined}>
                <span className="sub-text">State</span>
              </DataTableRow>
              <DataTableRow size="md" className={undefined}>
                <span className="sub-text">District</span>
              </DataTableRow> */}
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  {/* <span className="sub-text">Details</span> */}
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item: any, index) => {
                    return (
                      <DataTableItem
                        key={item.id}
                        className={undefined}
                        style={{ color: "black" }}
                      >
                        <DataTableRow size="md" className={undefined}>
                          <span>{index + 1}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.referenceID}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.companyName}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.gstin}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.contactPerson}</span>
                        </DataTableRow>
                        <DataTableRow size="sm" className={undefined}>
                          <span>{item.phoneNumber}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.email}</span>
                        </DataTableRow>
                        {/* <DataTableRow size="md" className={undefined}>
                      <span>{item.state.stateName}</span>
                    </DataTableRow>
                    <DataTableRow size="md" className={undefined}>
                      <span>{item.district.districtName}</span>
                    </DataTableRow> */}
                        <DataTableRow size="md" className={undefined}>
                          <span
                            className={`tb-status text-${
                              item.status === "Active" ? "success" : "danger"
                            }`}
                          >
                            {item.status}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <ul className="nk-tb-actions">
                            <li
                              className="nk-tb-action-hidden"
                              onClick={() =>
                                navigate(
                                  `${process.env.PUBLIC_URL}/viewContractorDetails/${item.contractorID}`
                                )
                              }
                            >
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="focus"
                                direction="top"
                                text="View Details"
                                iconClass={undefined}
                              />
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/viewContractorDetails/${item.contractorID}`}
                                      >
                                        <Icon name="focus"></Icon>
                                        <span>View Details</span>
                                      </Link>
                                    </li>
                                    <li
                                      onClick={() =>
                                        handleDelete(item.contractorID)
                                      }
                                    >
                                      <Link to={`#`}>
                                        <Icon name="delete"></Icon>
                                        <span>Delete user</span>
                                      </Link>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={rowData.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        {/* <AddModal modal={modal.add} formData={formData} setFormData={setFormData} closeModal={closeModal} onSubmit={onFormSubmit} filterStatus /> */}
        <EditModal
          modal={modal.edit}
          formData={editFormData}
          setFormData={setEditFormData}
          closeModal={closeEditModal}
          onSubmit={onEditSubmit}
          filterStatus
        />
      </Content>
    </React.Fragment>
  );
};

export default Contractors;
