
import { MenuItem } from './MenuInterfaces';
const menu: MenuItem[] = [
  {
    text: "Dashboard",
    link: "/dashboard",
  },
  {
    text: "Masters",
    subMenu: [
      {
        text: "Organisation",
        link: "/organisation",
      },
      {
        text: "Sites",
        link: "/sites",
      },
      {
        text:"Delivery Address",
        link:"delivery-address"
      },
      {
        text: "Departments",
        link: "/departments",
      },
      {
        text: "Roles",
        active: false,
        subMenu: [
          {
            text: "Roles",
            link: "/roles",
          },
          {
            text: "Role Hierarchy",
            link: "/reporting-hierarchy",
          },
        ],
      },
      {
        text:"Users",
        link:"/users"
      },
      {
        text: "Suppliers",
        active: false,
        subMenu: [
          {
            text: "Supplier Category",
            link: "/supplier-category",
          },
          {
            text: "Suppliers",
            link: "/suppliers",
          },
        ],
      },
      {
        text: "General Contractors",
        link: "/general-contractors",
      },
      // {
      //   text: "Consultants",
      //   link: "/consultants",
      // },
     
      {
        text: "Approval Heirarchy",
        link: "/approval-heirarchy",
      },
      {
        text: "Materials",
        link: "/materails",
      },
      {
        text: "Cost Center",
        link: "/cost-center",
      },
      {
        text: "General Ledger",
        link: "/general-ledger",
      },
    ],
  },
  {
    text: "Procure to Pay",
    subMenu: [
      {
        text: "Budget",
        link: "/view-budget",
      },
      {
        text: "Purchase Request",
        link: '/add-new-purchase-request'
      },
      {
        text: "Forward Auction",
        link: "/forward-auction-new"
      },
      {
        text: "Reverse Auction",
        link: "reverse-auction-new",
      },
      {
        text: "Purchase Orders",
        link: "view-purchase-order",
      },
      {
        text: "Invoices",
        link: "/view-invoice-list",
      },
      {
        text:"Credit Note",
        link:"/all-credit-debit-notes"
      },
      {
        text: "Goods Receipt Note",
        link: "/view-dispatch-list",
      },
      {
        text: "Payments",
        link: "/invoice-payables",
      },
      // {
      //   text: "Inward Goods",
      //   link: "/pricing-table",
      // },
      // {
      //   text: "Returns",
      //   link: "/image-gallery",
      // },
      // {
      //   text: "Rate Contracts",
      //   link: "/pages/regular-v2",
      // },
    ],
  },
  {
    text: "Market Place",
    subMenu: [
      {
        text: "Quotations",
        link: "/new-rfq",
      },
      {
        text: "Forward Auction",
        link: "/forward-auction-new"
      },
      {
        text: "Reverse Auction",
        link: "reverse-auction-new",
      },
      {
        text: "Registered Suppliers",
        link: "reverse-auction-new",
      }
    ]
  },
  {
    text: "Inventory",
    subMenu: [
      {
        text: "Material Issue",
        active: false,
      },
      {
        text: "Utilization Status",
        active: false,
       
      },
      {
        text: "Damaged",
        link: "/_blank",
      },
      {
        text: "Inventory",
        link: "",
      },
    ],
  },
];
export default menu;
