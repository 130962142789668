import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import { TableColumnsType, Table, Progress, Select, Input, Empty } from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import PurchaseOrderAside from "./PurchaseOrderAside";
import ViewOrderDetails from "./ViewOrderDetails";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import { useNavigate } from "react-router";
import statusMapper from "./StatusMapper";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}

const ViewPurchaseOrdersList: FC = () => {
  const [widgetData, setWidgetData] = useState<WidgetData[]>([
    {
      title: "Approved",
      count: 0,
      progress: 0,
      color: "#OO65BD",
    },
    {
      title: "Pending Approval",
      count: 0,
      progress: 0,
      color: "#20639B",
    },
    {
      title: "Supplier Changes",
      count: 0,
      progress: 0,
      color: "#FA991C",
    },
    {
      title: "Changes Requested",
      count: 0,
      progress: 0,
      color: "#F6D55C",
    },
    {
      title: "To be Invoiced",
      count: 0,
      progress: 0,
      color: "#OOCC99",
    },
  ]);
  const navigate = useNavigate();
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [viewPODetails, setViewPODetails] = useState<any>(null);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | undefined>(
    undefined
  );
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    viewChange();
    fetchPurchaseOrders();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Order #",
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: 120,
      fixed: "left",
      render: (text) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: " Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width: 120,
      fixed: "left",
      render: (date) => <span>{FormatDate(date)}</span>,
    },
    {
      title: "Supplier ",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 200,
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Delivery Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 200,
      render: (date) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {FormatCurrency(text, record.currencyFormat, record.currencySymbol)}
          </span>
        </div>
      ),
    },

    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {FormatCurrency(text, record.currencyFormat, record.currencySymbol)}
          </span>
        </div>
      ),
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {FormatCurrency(text, record.currencyFormat, record.currencySymbol)}
          </span>
        </div>
      ),
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {FormatCurrency(text, record.currencyFormat, record.currencySymbol)}
          </span>
        </div>
      ),
    },

    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 250,
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {FormatCurrency(text, record.currencyFormat, record.currencySymbol)}
          </span>
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date) => <span>{FormatDate(date)}</span>,
      width: 100,
    },
    {
      title: "INWARD STATUS",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      width: 200,
      fixed: "right",
      render: (status: any) => {
        const _status = statusMapper[status]?.buyer;
        return (
          <div className="justify-start align-center">
            <h6
              className={`tb-status ms-1`}
              style={{
                color: `${_status?.color}`,
                fontSize: "14px",
              }}
            >
              {_status?.icon} {_status?.title||status}
            </h6>
          </div>
        );
      },
    },

    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 100,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/view-purchase-order/${record.orderNumber}`);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ]);
  const handleStatusFilterChange = (value: string | undefined) => {
    setStatusFilter(value);
    filterTable(searchText, value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
    filterTable(value, statusFilter);
  };

  const filterTable = (searchText: string, status: string | undefined) => {
    let _filteredData: any = originalData;
    if (searchText) {
      console.log("searchText", searchText);

      _filteredData = _filteredData.filter(
        (item: any) =>
          item.orderNumber
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.description.toLowerCase().includes(searchText.toLowerCase()) ||
          item.siteName.toLowerCase().includes(searchText.toLowerCase()) ||
          item.createdUserName
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item.supplierName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (status && status !== "ALL") {
      console.log("status", status);
      console.log("filteredData", _filteredData);
      _filteredData = _filteredData?.filter(
        (item: any) => item.orderStatus == status
      );
    }

    setFilteredData(_filteredData);
  };
  const fetchPurchaseOrders = async () => {
    try {
      const res = await purchaseOrderApis.fetchPurchaseOrders();
      const { data, status, message } = res;
      if (status) {
        setOriginalData(data);
        setFilteredData(data);
        setWidgetData((widgetArray: any) => {
          return widgetArray.map((widget: any) => {
            const statusList = data?.filter((item: any) => {
              const mappedStatus = statusMapper[item?.orderStatus]?.buyer?.title;
              if (!mappedStatus) {
                return false;
              }
              return (
                mappedStatus?.toLowerCase() ==
                widget?.title?.trim()?.toLowerCase()
              );
            });
            const statusCount = statusList.length;
            const progress =statusCount?(Number(statusCount) / Number(data?.length)) * 100:0

            return {
              ...widget,
              count: statusCount,
              progress,
            };
          });
        });
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const FormatCurrency = (
    number: any,
    currencyFormat: any,
    currencySymbol: any
  ) => {
    if (currencyFormat == "") {
      currencyFormat = "₹#,##0.00";
    }
    if (currencySymbol == "") {
      currencySymbol = "₹";
    }
    // Define regex patterns to extract formatting details
    const symbolBeforePattern = /^([^#0,]+)?([#0.,]+)$/;
    const symbolAfterPattern = /^([#0.,]+)([^#0,]+)?$/;
    let locale = "en-US"; // Default locale
    let symbolPosition = "before"; // Default to symbol before the number
    let minimumFractionDigits = 2; // Default to 2 decimal places
    // Check for symbol position
    let matchBefore = currencyFormat?.match(symbolBeforePattern);
    let matchAfter = currencyFormat?.match(symbolAfterPattern);
    if (matchBefore) {
      symbolPosition = "before";
    } else if (matchAfter) {
      symbolPosition = "after";
    }
    // Check for fraction digits
    let fractionMatch = currencyFormat?.match(/[.,]0*$/);
    if (fractionMatch) {
      minimumFractionDigits = fractionMatch[0].length - 1;
    }
    // Create a new Intl.NumberFormat object
    let formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    // Format the number
    let formattedNumber = formatter.format(number);
    if (formattedNumber == "NaN" || formattedNumber == "undefined") {
      return "";
    }
    // Add the currency symbol in the correct position
    if (symbolPosition === "before") {
      return currencySymbol + formattedNumber;
    } else {
      return formattedNumber + currencySymbol;
    }
  };

  return (
    <>
      <Head title="Purchase Requests - View" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <PurchaseOrderAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <div>
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">
                        {viewPODetails == null ? (
                          <span>Purchase Orders</span>
                        ) : (
                          <span>
                            Order - {viewPODetails.orderNumber} |{" "}
                            {statusMapper[viewPODetails.status]?.buyer?.title||viewPODetails?.status}
                          </span>
                        )}
                      </BlockTitle>
                    </BlockHeadContent>
                    <Button
                      className="toggle"
                      style={{
                        visibility: viewPODetails ? "visible" : "hidden",
                      }}
                      color="primary"
                      onClick={async () => {
                        await fetchPurchaseOrders();
                        setViewPODetails(null);
                        filterTable(searchText, statusFilter);
                      }}
                    >
                      <Icon name="arrow-left" />
                      <span>Back</span>
                    </Button>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>
                {
                  originalData?.length>0?
                <>
                <Block>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    {widgetData.map((widget, index) => (
                      <div
                        key={index}
                        style={{
                          border: "1px solid #dbdfea",
                          padding: "15px",
                          borderRadius: "8px",
                          width: "calc(20% - 10px)", // Ensure widgets are 20% of the width
                          minWidth: "150px", // Ensure a minimum width for smaller screens
                          marginBottom: "10px",
                        }}
                      >
                        <div style={{ marginBottom: "20px" }}>
                          <h6 style={{ margin: 0 }}>{widget.title}</h6>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <Progress
                              type="circle"
                              percent={Number(widget?.progress)?.toFixed(0)?.toString() as any}
                              width={50}
                              strokeColor={widget.color}
                              format={(percent) => `${percent}%`}
                            />
                          </div>
                          <div style={{ flex: 1, textAlign: "center" }}>
                            <p style={{ fontSize: "24px", margin: 0 }}>
                              {widget.count}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Block>
                <Block>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <div>
                      <span>Search by Status: </span>
                      <Select
                        style={{ width: 200 }}
                        onChange={handleStatusFilterChange}
                        value={statusFilter || "ALL"}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <div
                              style={{
                                padding: "8px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>Search by Status: </span>
                              <SearchOutlined />
                            </div>
                          </>
                        )}
                      >
                        <Option value="ALL">All</Option>
                        {Object.keys(statusMapper).map((item, index) => {
                          const _status = statusMapper[item]?.buyer;
                          return (
                            <Option key={index} value={item}>
                              {_status?.title||item}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input
                        placeholder="Search by Site Name &amp; Purchase Order Number"
                        value={searchText}
                        onChange={handleSearchChange}
                        style={{ width: 400 }}
                        prefix={<SearchOutlined />}
                      />
                    </div>
                  </div>
                </Block>
                {
                  filteredData?.length>0?
                <div
                  style={{
                    width: screenSize - 330 + "px",
                    minHeight: "200px",
                    marginTop: "20px",
                  }}
                >
                  <Table
                    className="customTable"
                    columns={columns.map((item: any, index) => ({
                      ...item,
                      index,
                    }))}
                    dataSource={filteredData}
                    pagination={{
                      pageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30", "50"],
                    }}
                    bordered
                    size="middle"
                    scroll={{ x: 1400 }}
                  />
                </div>
                :
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <Empty description="No Data Available for this Selected filters" />
                </div>
                }

                </>
                :
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <Empty description="No Purchase Orders Found" />
                </div>
                }
              </div>
              
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default ViewPurchaseOrdersList;
