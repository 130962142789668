import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { Select, Empty } from 'antd';
import { formatCurrency } from '../../../utils/Utils';
import { QuotationAnalyticsProps } from './interfaces';

const { Option } = Select;

const monthlySavingsData = {
    siteName: 'Site A',
    siteID: 'SITE001',
    currency: 'INR',
    data: [
        {
            categoryID: 'cat001',
            categoryName: 'Steel',
            avgSavings: [10, 6, 7, 6, 7, 8, 7, 8, 9, 8, 7, 8], // Smooth curve for savings percentages
            actualValues: [220000, 225000, 230000, 235000, 190000, 245000, 250000, 255000, 260000, 265000, 270000, 275000], // Smooth curve for actual values
        },
        {
            categoryID: 'cat002',
            categoryName: 'Cement',
            avgSavings: [4, 5, 6, 5, 6, 7, 6, 7, 8, 7, 6, 7],
            actualValues: [120000, 125000, 130000, 135000, 140000, 145000, 150000, 155000, 160000, 165000, 170000, 175000],
        },
        // Add more categories if needed
    ],
};

const MonthlySavingsTrends: React.FC<QuotationAnalyticsProps> = ({ site, dateRange, customDateRange }) => {
    const [selectedCategory, setSelectedCategory] = React.useState<string | undefined>('Steel');
    const selectedSite = monthlySavingsData;

    const months = [
        'January 2023', 'February 2023', 'March 2023', 'April 2023',
        'May 2023', 'June 2023', 'July 2023', 'August 2023',
        'September 2023', 'October 2023', 'November 2023', 'December 2023'
    ];

    useEffect(() => {
        const chartDom = document.getElementById('monthly-savings-chart') as HTMLElement;
        const myChart = echarts.init(chartDom);

        const selectedData = selectedSite.data?.find(item => item.categoryName === selectedCategory) || { avgSavings: [], actualValues: [] };

        if (selectedData.avgSavings.length > 0) {
            const option = {
                grid: {
                    left: '100px', // Shift the graph to the left by 100px
                },
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    show: false, // Hide the legend since we don't need the trendline text
                },
                xAxis: {
                    type: 'category',
                    data: months,
                    name: 'Months',
                    nameLocation: 'middle',
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontSize: 14,
                        padding: 20,
                    },
                    axisLabel: {
                        interval: 0, // Ensure every label is shown
                        rotate: 0,  // No rotation
                        formatter: (value: string) => {
                            const [month, year] = value.split(' ');
                            return `${month}\n${year}`; // Display month and year on separate lines
                        },
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        name: 'Actual Savings',
                        nameLocation: 'middle',
                        nameTextStyle: {
                            fontWeight: 'bold',
                            fontSize: 14,
                            padding: 60,
                        },
                        position: 'left',
                        axisLabel: {
                            formatter: (value: number) => formatCurrency(value, selectedSite.currency),
                        },
                    },
                    {
                        type: 'value',
                        name: 'Savings in Percentage (%)',
                        nameLocation: 'middle',
                        nameTextStyle: {
                            fontWeight: 'bold',
                            padding: 40, // Increase padding for the savings percentage y-axis
                        },
                        position: 'right',
                        axisLabel: {
                            formatter: (value: number) => `${value}%`,
                        },
                    },
                ],
                series: [
                    {
                        name: 'Actual Savings',
                        type: 'bar',
                        data: selectedData.actualValues,
                        itemStyle: {
                            color: '#5470C6', // Bar color
                        },
                        barWidth: '30%', // Bar size as 30%
                        barCategoryGap: '50%', // Space between bars as 50%
                        yAxisIndex: 0, // Link to the left y-axis
                    },
                    {
                        name: 'Savings in Percentage',
                        type: 'line',
                        data: selectedData.avgSavings,
                        lineStyle: {
                            color: '#3BAEA3', // Trendline color
                        },
                        smooth: true, // Ensure the line is smooth
                        yAxisIndex: 1, // Link to the right y-axis
                    },
                ],
            };

            myChart.setOption(option);
        } else {
            myChart.clear(); // Clear the chart if there's no data
        }

        return () => {
            myChart.dispose();
        };
    }, [selectedCategory]);

    const handleCategoryChange = (value: string) => {
        setSelectedCategory(value);
    };

    return (
        <div>
            <div className="card-title-group mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-title">
                    <h6 className="title">Month-on-Month Savings Trends</h6>
                    <p>Visual representation of savings trends by material category over the past 12 months.</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 8 }}>Filter by Category:</span>
                    <Select
                        placeholder="Select Category"
                        style={{ width: 200 }}
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                    >
                        {selectedSite.data?.map(category => (
                            <Option key={category.categoryID} value={category.categoryName}>
                                {category.categoryName}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
            {selectedSite?.data?.find((item: any) => item.categoryName === selectedCategory)?.avgSavings?.length ? (
                <div id="monthly-savings-chart" style={{ width: '100%', height: '400px' }} />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                    <Empty description="No Data found for the selected category" />
                </div>
            )}
        </div>
    );
};

export default MonthlySavingsTrends;
