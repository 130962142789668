import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Table, Popover, Input } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { formatCurrency, colors } from '../../../../utils/Utils';
import UserLabel from '../../../../utils/UserLabel';
import { Button } from 'reactstrap';
import QCApprovalDrawer from './QCApprovalDrawer';
import usersApis from '../../../../api/master/users';
import quotationApis from '../../../../api/master/quotations';
import { useNavigate } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { EditOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import makeApiCall from '../../../../api/master/axiosIndex';
import Swal from 'sweetalert2';

interface ShortlistedSuppliersDrawerProps {
    visible: boolean;
    onClose: () => void;
    rfqId: string;
    siteName: string;
    data: any[];
}

interface EditableCellProps {
    value: any;
    onChange: (value: any) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({ value, onChange }) => (
    <Input
        value={value}
        onChange={(e) => onChange(e.target.value)}
    />
);

const ShortlistedSuppliersDrawer: React.FC<ShortlistedSuppliersDrawerProps> = ({
    visible,
    onClose,
    rfqId,
    siteName,
    data,
}) => {
    const [approvals, setApprovals] = useState({
        open: false,
        data: [],
    });
    const [prefferedStates, setPrefferedStates] = useState<any>([]);
    const [editingKey, setEditingKey] = useState<string | null>(null);
    const [editValues, setEditValues] = useState<{ [key: string]: any }>({});
    const [tableData, setTableData] = useState<any[]>(data);
    const navigate = useNavigate();

    const supplierColorMap = useMemo(() => {
        const map: { [key: string]: string } = {};
        let colorIndex = 0;

        data.forEach((record) => {
            if (!map[record.supplierName]) {
                map[record.supplierName] = colors[colorIndex % colors.length];
                colorIndex++;
            }
        });

        return map;
    }, [data]);

    useEffect(() => {
        setTableData(data);
    }, [data]);


    useEffect(() => {
        console.log("Current editingKey:", editingKey);
    }, [editingKey]);
    

    const columns = [
        {
            title: 'Supplier Name',
            dataIndex: 'supplierName',
            key: 'supplierName',
            render: (text: string, record: any) => (
                <UserLabel
                    supplierName={record.supplierName}
                    color={supplierColorMap[record.supplierName]}
                    text={text}
                />
            ),
        },
        {
            title: 'Material Name',
            dataIndex: 'materialName',
            key: 'materialName',
        },
        {
            title: 'Asked Quantity',
            dataIndex: 'requiredQty',
            key: 'requiredQty',
        },
        {
            title: 'Awarded Quantity',
            dataIndex: 'awardedQty',
            key: 'awardedQty',
            render: (text: number, record: any) => (
                editingKey === record.id ? (
                    <Input
                        value={editValues[record.id]?.awardedQty ?? text}
                        onChange={(e) => handleFieldChange(record.id, 'awardedQty', e.target.value)}
                    />
                ) : text
            ),
        },
        {
            title: 'Reason',
            dataIndex: 'shortListReason',
            key: 'shortListReason',
            render: (text: string, record: any) => (
                editingKey === record.id ? (
                    <Input
                        value={editValues[record.id]?.shortListReason ?? text}
                        onChange={(e) => handleFieldChange(record.id, 'shortListReason', e.target.value)}
                    />
                ) : text
            ),
        },        
        {
            title: 'Awarded Qty %',
            dataIndex: 'awardedPercentage',
            key: 'awardedPercentage',
            render: (text: any) => `${text}%`,
        },
        {
            title: 'Initial Quotation',
            dataIndex: 'initialPrice',
            key: 'initialPrice',
            render: (text: any) => formatCurrency(text, 'INR'),
        },
        {
            title: 'Best Quotation',
            dataIndex: 'price',
            key: 'price',
            render: (text: any) => formatCurrency(text, 'INR'),
        },
        {
            title: 'Taxes',
            dataIndex: 'tax',
            key: 'tax',
            render: (text: any, record: any) => (
                <>
                    {formatCurrency(text, 'INR')}
                    <Popover
                        content={
                            <div>
                                <p>CGST: {record.cgst || 0}%</p>
                                <p>SGST: {record.sgst || 0}%</p>
                                <p>IGST: {record.igst || 0}%</p>
                            </div>
                        }
                        title="Tax Breakdown"
                    >
                        <InfoCircleOutlined style={{ marginLeft: 8 }} />
                    </Popover>
                </>
            ),
        },
        {
            title: 'Line Total',
            dataIndex: 'lineTotal',
            key: 'lineTotal',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: any, record: any) => (
                <Dropdown overlay={actionsMenu(record.id,record.referencedSupplierId,record.quotationId,record)}>
                    <MoreOutlined />
                </Dropdown>
            ),
        },
    ];

    const actionsMenu = (id:any,key: string,quotationId:any,record:any) => (
        <Menu>
            <Menu.Item icon={<EditOutlined />} onClick={() => handleEdit(id,key)}>
                Edit
            </Menu.Item>
            <Menu.Item icon={<DeleteOutlined />} onClick={() => handleRemove(key,quotationId,record)}>
                Remove
            </Menu.Item>
        </Menu>
    );

    const handleEdit = (id:any,key: string) => {
        console.log("tableData",tableData,key)
        const currentItem = tableData.find(item => item.id == id);
        console.log("currentItem",currentItem)
        if (currentItem) {
            console.log("id")
            setEditingKey(id);  // Set the key for the currently editing row
            setEditValues({
                [id]: {
                    awardedQty: currentItem.awardedQty || '',
                    shortListReason: currentItem.shortListReason || '',
                }
            });
        }
    };

    const handleSave = () => {
        if (editingKey) {
            const updatedData = tableData.map(item =>
                item.id === editingKey
                    ? { ...item, ...editValues[editingKey] }
                    : item
            );
            setTableData(updatedData);
            setEditingKey(null);
            setEditValues({});
            makeApiCall(updatedData);
        }
    };

    const makeApiCall = async (updatedData: any) => {
        console.log("updatedData", updatedData);
        
        // Map the updated data to match API expectations
        const data = updatedData.map((item: any) => ({
            id: item.id, // Assuming key is the unique identifier
            awardQty: item.awardedQty,
            reason: item.shortListReason
        }));
    
        console.log("data", data);
    
        try {
            // Call API to update the data
            const { response, status } = await quotationApis.editShortListed({ list: data });
            
            if (status) {
                Swal.fire("Updated!", "", "success");
    
                // Fetch updated data
                const { response: fetchResponse, status: fetchStatus } = await quotationApis.getShortListed(rfqId);
                
                if (fetchStatus) {
                    // Update table data with fetched response
                    setTableData(fetchResponse.data.suppliers);
                } else {
                    console.error("Failed to fetch updated data");
                }
            } else {
                console.error("Failed to update data");
            }
        } catch (error) {
            console.error("API call error:", error);
        }
    };
    

   const handleCancel = () => {
        setEditingKey(null);
        setEditValues({});
    };


    const handleFieldChange = (key: string, field: string, value: any) => {
        console.log("Updating field:", field, "for record ID:", key, "with value:", value);
        setEditValues(prev => ({
            ...prev,
            [key]: {
                ...prev[key],
                [field]: value
            }
        }));
    };

    const handleRemove = (key: string,quotationId:any,record:any) => {
        console.log(`Removing record with key:`,record);
        // setTableData(prev => prev.filter(item => item.referencedSupplierId !== key));
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                let { response, status }: any = quotationApis.removeShortListed(quotationId,record.id);
                if (status) {
                    fetchData()
                    Swal.fire({
                        title: "Removed!",
                        text: "Your Line has been removed.",
                        icon: "success"
                    });
                }

            };
        })
    }

    const fetchCurrentUserInfo = async () => {
        try {
            const res = await usersApis.getCurrentUser();
            if (res) {
                const { prefferedStates } = res;
                setPrefferedStates(prefferedStates?.map((item: any) => item?.stateID));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handelSendQCApprovals = async (data: any) => {
        console.log("data", data);
        try {
            const { res, status } = await quotationApis.sendToQCApproval({ approvals: data }, rfqId);
            if (status) {
                navigate(`/view-rfqs`);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCurrentUserInfo();
    }, []);


    const fetchData = async () => {
        try {
            const { response, status } = await quotationApis.getShortListed(rfqId);
            if (status) {
                setTableData(response.data.suppliers);
            } else {
                console.error("Failed to fetch data");
            }
        } catch (error) {
            console.error("Fetch data error:", error);
        }
    };

    useEffect(() => {
       
    
        fetchData();
    }, [rfqId]);

    return (
        <>
            <Drawer
                title={`Shortlisted Suppliers for RFQ: ${rfqId}, Site: ${siteName}`}
                width={'85vw'}
                onClose={onClose}
                open={visible}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <div className='mb-2' style={{ display: "flex", float: "right" }}>
                    <Button className="link link-light"
                        onClick={(e) => {
                            e.preventDefault();
                            setApprovals(pre => ({
                                ...pre,
                                open: true,
                            }));
                        }}
                    >Send for QC Approvals</Button>
                    <Button className="link link-light">Convert to PO</Button>
                </div>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    rowKey="referencedSupplierId"
                    size="small"
                    pagination={false}
                    footer={() => (
                        editingKey !== null && (
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={handleSave} style={{ marginRight: 8 }}>
                                    Save
                                </Button>
                                <Button onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </div>
                        )
                    )}
                />
            </Drawer>
            {approvals.open && (
                <QCApprovalDrawer
                    open={approvals.open}
                    onClose={() => setApprovals(pre => ({ ...pre, open: false }))}
                    onSubmit={(data: any) => {
                        setApprovals({ open: false, data });
                        handelSendQCApprovals(data);
                    }}
                    stateIds={prefferedStates}
                    setApprovalSubmissionStatus={(status: boolean) => { }}
                    approvalData={approvals.data}
                />
            )}
        </>
    );
};

export default ShortlistedSuppliersDrawer;
