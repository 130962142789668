import React, { FC, useState, useEffect } from "react";
import { Col, Row, Card, Button } from "reactstrap";
import { Table, Input, Popover, Space, Tooltip, message, Checkbox, Form, InputNumber } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  TwitchOutlined,
  EditOutlined,
  BarChartOutlined,
  MessageOutlined,
  TrophyOutlined,
  SaveOutlined
} from "@ant-design/icons";
import StatusIndicatorDrawer from "./QuoteComparison/StatusIndicatorDrawer";
import ScenarioBuilderDrawer from "./QuoteComparison/ScenarioBuilderDrawer";
import AllocationDrawer from "./QuoteComparison/AllocationDrawer";
import ShortlistedSuppliersDrawer from "./QuoteComparison/ShortlistedSuppliersDrawer";
import quotationApis from "../../../api/master/quotations";
import Swal from "sweetalert2";
import SupplierDetailsDrawer from "./QuoteComparison/SupplierDetailsDrawer";

//import { QuotationComparisonProps, SupplierQuotation} from './QuotationTypes'

interface QuotationComparisonProps {
  rfq: any;
}

type Material = {
  quotationLineId: number;
  key: string;
  materialName: string;
  materialID: string;
  awardedQty: string;
  qty: number;
  uom: string; // Added UOM (Unit of Measure) field
  targetPrice: number;
};

type SupplierQuotation = {
  supplierID: string;
  supplierName: string;
  currency: string;
  quotation: {
    materialID: string;
    quotationLineId: number;
    unitPrice: number;
    supplyQty: number;
    remainingQty: number
    minOrderQty: number;
    minQty: number;
    awardedQty: number;
    cgst?: number; // Central GST rate (optional)
    sgst?: number; // State GST rate (optional)
    igst?: number; // Integrated GST rate (optional)
    cgstAmount?: number; // Calculated CGST amount
    sgstAmount?: number; // Calculated SGST amount
    igstAmount?: number; // Calculated IGST amount
    taxes?: number; // Total taxes (CGST + SGST or IGST)
    initialQuotation?: number; // Initial Quotation Price (optional)
    finalQuotation?: number; // Final Quotation Price (optional)
    needByDate: string; // New field for need by date
    eta: string; // New field for ETA
  }[];
  subTotal: number;
  totalTaxes: number;
  paymentTerms: string;
  leadTime: string;
  grandTotal: number;
  score?: {
    // Optional score object
    materialScore: number;
    quotationScore: number;
    onTimeDeliveryScore: number;
    volumeScore: number;
    creditScore: number;
    score: number;
    rank: number;
  };
};
interface ShortlistedSupplier {
  supplierID: string;
  supplierName: string;
  materialID: string;
  materialName: string;
  askedQty: number;
  awardedQty: number;
  awardeQtyPercentage: string;
  initialQuotation: string | number;
  bestQuotation: string | number;
  taxes: number;
  cgst: number;
  sgst: number;
  igst: number;
  lineTotal: number;
}

// const initialMaterials: Material[] = [
//   {
//     key: "1",
//     materialName: "Floor Plan",
//     materialID: "mat001",
//     qty: 20,
//     uom: "Nos", // Example UOM
//     targetPrice: 0,
//   },
//   {
//     key: "2",
//     materialName: "Exterior Elevation",
//     materialID: "mat002",
//     qty: 10,
//     uom: "Nos", // Example UOM
//     targetPrice: 0,
//   },
// ];

// const supplierQuotations: SupplierQuotation[] = [
//   {
//     supplierID: "Sup001",
//     supplierName: "ABC Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 123,
//         supplyQty: 20,
//         minOrderQty: 2,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 22.14,
//         sgstAmount: 22.14,
//         taxes: 44.28,
//         initialQuotation: 130,
//         finalQuotation: 123,
//         needByDate: "2024-12-01",
//         eta: "2024-12-05",
//       },
//       {
//         materialID: "mat001",
//         unitPrice: 123,
//         supplyQty: 20,
//         minOrderQty: 2,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 22.14,
//         sgstAmount: 22.14,
//         taxes: 44.28,
//         initialQuotation: 130,
//         finalQuotation: 123,
//         needByDate: "2024-12-01",
//         eta: "2024-12-05",
//       },
//       {
//         materialID: "mat001",
//         unitPrice: 123,
//         supplyQty: 20,
//         minOrderQty: 2,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 22.14,
//         sgstAmount: 22.14,
//         taxes: 44.28,
//         initialQuotation: 130,
//         finalQuotation: 123,
//         needByDate: "2024-12-01",
//         eta: "2024-12-05",
//       },
//       {
//         materialID: "mat001",
//         unitPrice: 123,
//         supplyQty: 20,
//         minOrderQty: 2,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 22.14,
//         sgstAmount: 22.14,
//         taxes: 44.28,
//         initialQuotation: 130,
//         finalQuotation: 123,
//         needByDate: "2024-12-01",
//         eta: "2024-12-05",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 345,
//         supplyQty: 10,
//         minOrderQty: 3,
//         igst: 18,
//         igstAmount: 621,
//         taxes: 621,
//         initialQuotation: 350,
//         finalQuotation: 345,
//         needByDate: "2024-12-10",
//         eta: "2024-12-15",
//       },
//     ],
//     subTotal: 50000,
//     totalTaxes: 1000,
//     paymentTerms: "30 days",
//     leadTime: "5 days",
//     grandTotal: 51000,
//     score: {
//       materialScore: 9,
//       quotationScore: 8,
//       onTimeDeliveryScore: 9,
//       volumeScore: 9,
//       creditScore: 8,
//       score: 86,
//       rank: 1, // Rank 1
//     },
//   },
//   {
//     supplierID: "Sup002",
//     supplierName: "DEF Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 121,
//         supplyQty: 20,
//         minOrderQty: 6,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.78,
//         sgstAmount: 21.78,
//         taxes: 43.56,
//         initialQuotation: 128,
//         finalQuotation: 121,
//         needByDate: "2024-12-02",
//         eta: "2024-12-06",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 346,
//         supplyQty: 10,
//         minOrderQty: 7,
//         igst: 18,
//         igstAmount: 622.8,
//         taxes: 622.8,
//         initialQuotation: 352,
//         finalQuotation: 346,
//         needByDate: "2024-12-11",
//         eta: "2024-12-16",
//       },
//     ],
//     subTotal: 60000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 61500,
//     score: {
//       materialScore: 8,
//       quotationScore: 8,
//       onTimeDeliveryScore: 8,
//       volumeScore: 8,
//       creditScore: 7,
//       score: 80,
//       rank: 2, // Rank 2
//     },
//   },
//   {
//     supplierID: "Sup003",
//     supplierName: "GEH Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 8,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-03",
//         eta: "2024-12-07",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 9,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-12",
//         eta: "2024-12-17",
//       },
//     ],
//     subTotal: 70000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 71500,
//     score: {
//       materialScore: 8,
//       quotationScore: 7,
//       onTimeDeliveryScore: 8,
//       volumeScore: 7,
//       creditScore: 7,
//       score: 76,
//       rank: 3, // Rank 3
//     },
//   },
//   {
//     supplierID: "Sup004",
//     supplierName: "Radha Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 5,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-04",
//         eta: "2024-12-08",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 4,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-13",
//         eta: "2024-12-18",
//       },
//     ],
//     subTotal: 80000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 81500,
//     score: {
//       materialScore: 7,
//       quotationScore: 7,
//       onTimeDeliveryScore: 7,
//       volumeScore: 6,
//       creditScore: 6,
//       score: 70,
//       rank: 4, // Rank 4
//     },
//   },
//   {
//     supplierID: "Sup004",
//     supplierName: "Radha Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 5,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-04",
//         eta: "2024-12-08",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 4,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-13",
//         eta: "2024-12-18",
//       },
//     ],
//     subTotal: 80000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 81500,
//     score: {
//       materialScore: 7,
//       quotationScore: 7,
//       onTimeDeliveryScore: 7,
//       volumeScore: 6,
//       creditScore: 6,
//       score: 70,
//       rank: 4, // Rank 4
//     },
//   },
//   {
//     supplierID: "Sup004",
//     supplierName: "Radha Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 5,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-04",
//         eta: "2024-12-08",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 4,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-13",
//         eta: "2024-12-18",
//       },
//     ],
//     subTotal: 80000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 81500,
//     score: {
//       materialScore: 7,
//       quotationScore: 7,
//       onTimeDeliveryScore: 7,
//       volumeScore: 6,
//       creditScore: 6,
//       score: 70,
//       rank: 4, // Rank 4
//     },
//   },
//   {
//     supplierID: "Sup004",
//     supplierName: "Radha Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 5,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-04",
//         eta: "2024-12-08",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 4,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-13",
//         eta: "2024-12-18",
//       },
//     ],
//     subTotal: 80000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 81500,
//     score: {
//       materialScore: 7,
//       quotationScore: 7,
//       onTimeDeliveryScore: 7,
//       volumeScore: 6,
//       creditScore: 6,
//       score: 70,
//       rank: 4, // Rank 4
//     },
//   },
//   {
//     supplierID: "Sup004",
//     supplierName: "Radha Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 5,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-04",
//         eta: "2024-12-08",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 4,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-13",
//         eta: "2024-12-18",
//       },
//     ],
//     subTotal: 80000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 81500,
//     score: {
//       materialScore: 7,
//       quotationScore: 7,
//       onTimeDeliveryScore: 7,
//       volumeScore: 6,
//       creditScore: 6,
//       score: 70,
//       rank: 4, // Rank 4
//     },
//   },
//   {
//     supplierID: "Sup004",
//     supplierName: "Radha Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 5,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-04",
//         eta: "2024-12-08",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 4,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-13",
//         eta: "2024-12-18",
//       },
//     ],
//     subTotal: 80000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 81500,
//     score: {
//       materialScore: 7,
//       quotationScore: 7,
//       onTimeDeliveryScore: 7,
//       volumeScore: 6,
//       creditScore: 6,
//       score: 70,
//       rank: 4, // Rank 4
//     },
//   },
//   {
//     supplierID: "Sup004",
//     supplierName: "Radha Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 5,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-04",
//         eta: "2024-12-08",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 4,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-13",
//         eta: "2024-12-18",
//       },
//     ],
//     subTotal: 80000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 81500,
//     score: {
//       materialScore: 7,
//       quotationScore: 7,
//       onTimeDeliveryScore: 7,
//       volumeScore: 6,
//       creditScore: 6,
//       score: 70,
//       rank: 4, // Rank 4
//     },
//   },
//   {
//     supplierID: "Sup004",
//     supplierName: "Radha Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 5,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-04",
//         eta: "2024-12-08",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 4,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-13",
//         eta: "2024-12-18",
//       },
//     ],
//     subTotal: 80000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 81500,
//     score: {
//       materialScore: 7,
//       quotationScore: 7,
//       onTimeDeliveryScore: 7,
//       volumeScore: 6,
//       creditScore: 6,
//       score: 70,
//       rank: 4, // Rank 4
//     },
//   },
//   {
//     supplierID: "Sup004",
//     supplierName: "Radha Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 5,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-04",
//         eta: "2024-12-08",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 4,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-13",
//         eta: "2024-12-18",
//       },
//     ],
//     subTotal: 80000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 81500,
//     score: {
//       materialScore: 7,
//       quotationScore: 7,
//       onTimeDeliveryScore: 7,
//       volumeScore: 6,
//       creditScore: 6,
//       score: 70,
//       rank: 4, // Rank 4
//     },
//   },
//   {
//     supplierID: "Sup004",
//     supplierName: "Radha Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 5,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-04",
//         eta: "2024-12-08",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 4,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-13",
//         eta: "2024-12-18",
//       },
//     ],
//     subTotal: 80000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 81500,
//     score: {
//       materialScore: 7,
//       quotationScore: 7,
//       onTimeDeliveryScore: 7,
//       volumeScore: 6,
//       creditScore: 6,
//       score: 70,
//       rank: 4, // Rank 4
//     },
//   },
//   {
//     supplierID: "Sup004",
//     supplierName: "Radha Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 5,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-04",
//         eta: "2024-12-08",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 4,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-13",
//         eta: "2024-12-18",
//       },
//     ],
//     subTotal: 80000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 81500,
//     score: {
//       materialScore: 7,
//       quotationScore: 7,
//       onTimeDeliveryScore: 7,
//       volumeScore: 6,
//       creditScore: 6,
//       score: 70,
//       rank: 4, // Rank 4
//     },
//   },
//   {
//     supplierID: "Sup005",
//     supplierName: "GVK Enterprises",
//     currency: "INR",
//     quotation: [
//       {
//         materialID: "mat001",
//         unitPrice: 119,
//         supplyQty: 20,
//         minOrderQty: 8,
//         cgst: 9,
//         sgst: 9,
//         cgstAmount: 21.42,
//         sgstAmount: 21.42,
//         taxes: 42.84,
//         initialQuotation: 126,
//         finalQuotation: 119,
//         needByDate: "2024-12-05",
//         eta: "2024-12-09",
//       },
//       {
//         materialID: "mat002",
//         unitPrice: 350,
//         supplyQty: 20,
//         minOrderQty: 5,
//         igst: 18,
//         igstAmount: 630,
//         taxes: 630,
//         initialQuotation: 355,
//         finalQuotation: 350,
//         needByDate: "2024-12-14",
//         eta: "2024-12-19",
//       },
//     ],
//     subTotal: 90000,
//     totalTaxes: 1500,
//     paymentTerms: "45 days",
//     leadTime: "7 days",
//     grandTotal: 91500,
//     score: {
//       materialScore: 6,
//       quotationScore: 6,
//       onTimeDeliveryScore: 6,
//       volumeScore: 5,
//       creditScore: 5,
//       score: 60,
//       rank: 5, // Rank 5
//     },
//   },
// ];

//Allocation / Award Data
type SubmittedDataItem = {
  quotationLineId: number;
  materialID: string;
  materialName: string;
  supplierID: string;
  awardQty: string;
  reason: string;
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: any) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={`payableAmount_${record.invoiceNumber}`}
          style={{ margin: 0 }}
          rules={[
            {
              type: "number",
              min: 1,
              message: `${title} must > 0`,
            },
          ]}
          initialValue={Number(record?.grossTotal) - Number(record?.paidAmount)}
        >
          <InputNumber
            defaultValue={
              Number(record?.grossTotal) - Number(record?.paidAmount)
            }
            className="w-100"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
            onKeyPress={(event) => {
              if (!/^[0-9]+$/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};


const QuotationComparison: FC<QuotationComparisonProps> = ({ rfq }) => {
  const [initialMaterials, setInitialMaterials] = useState<Material[]>([]);
  const [supplierQuotations, setSupplierQuotations] = useState<SupplierQuotation[]>([]);
  const [materials, setMaterials]: any = useState<Material[]>([]);
  const [inputValues, setInputValues] = useState<Record<string, string>>(
    initialMaterials.reduce(
      (acc, material) => ({
        ...acc,
        [material.key]: String(material.targetPrice),
      }),
      {}
    )
  );
  const [statusIndicatorDrawerVisible, setStatusIndicatorDrawerVisible] =
    useState(false);
  const [statusIndicatorRanges, setStatusIndicatorRanges] = useState<any>({});

  const [selectedMaterials, setSelectedMaterials] = useState<string[]>([]); // State to track selected materials
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false); // State for Select All checkbox
  const [allocationDrawerVisible, setAllocationDrawerVisible] = useState(false);
  const [submittedData, setSubmittedData] = useState<SubmittedDataItem[]>([]); // This for Awared data from AllocationDrawer


  const [allocationStatusColor, setAllocationStatusColor] = useState("red");
  const [allocationStatus, setAllocationStatus] = useState("Not Awarded");
  const [countsData, setCountsData]: any = useState();

  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);

  const [columns, setColumns] = useState<any>([]);

  const [supplierDetailsDrawerVisible, setSupplierDetailsDrawerVisible] = useState(false);

  const [supplierDetailsData, setSupplierDetailsData] = useState<any>({});

  const getColor = (totalPrice: number, targetPrice: number) => {
    console.log("colorIndex", totalPrice, targetPrice);
    if (targetPrice === 0) {
      return "#fff"; // White for undefined target price
    }

    const difference = totalPrice - targetPrice;
    const percentageDifference = (difference / targetPrice) * 100;

    if (percentageDifference >= statusIndicatorRanges?.outlierRange?.from) {
      return "#fceceb"; // Red - Outlier
    } else if (
      percentageDifference >= statusIndicatorRanges?.slightlyHighRange?.from &&
      percentageDifference < statusIndicatorRanges?.slightlyHighRange?.to
    ) {
      return "#f5c38e"; // Orange - Slightly High
    } else if (
      percentageDifference >= statusIndicatorRanges?.highRange?.from &&
      percentageDifference < statusIndicatorRanges?.highRange?.to
    ) {
      return "#f2bb4e"; // Yellow - High
    } else if (
      percentageDifference >= statusIndicatorRanges?.veryHighRange?.from &&
      percentageDifference < statusIndicatorRanges?.veryHighRange?.to
    ) {
      return "#f7e257";
    } else {
      return "#fff"; // Default Green for very high
    }
  };

  const updateTargetPrice = (key: string, value: string) => {
    console.log("updateTargetPrice", key, value);
    setInputValues((prevValues) => ({
      // console.log("333333",);
      ...prevValues,
      [key]: value,
    }));
    console.log(inputValues);
  };

  const handleBlur = (key: string) => {
    const value = inputValues[key] === "" ? "0" : inputValues[key];
    console.log(":::INPUTVALUE:::", value);
    setMaterials((prevMaterials: any) =>
      prevMaterials.map((material: any) =>
        // console.log("tarrrrrr:::", material.key, key);
        material.key === key
          ? { ...material, targetPrice: Number(value) }
          : material
      )
    );
    console.log("setMaterials", materials)
  };

  const generateColumns = (
    updateTargetPrice: (key: string, value: string) => void,
    inputValues: Record<string, string>,
    handleBlur: (key: string) => void,
    _supplierQuotations: any
  ): ColumnsType<any> => {
    const _columns: ColumnsType<any> = [
      // {
      //   title: (
      //     <Checkbox
      //       checked={isAllSelected}
      //       onChange={(e) => handleSelectAll(e.target.checked)}
      //     />
      //   ), // Select All
      //   dataIndex: "selection",
      //   key: "selection",
      //   fixed: "left",
      //   width: 50,
      //   render: (text: any, record: any) => (
      //     <Checkbox
      //       checked={selectedMaterials.includes(record.key)}
      //       onChange={(e) =>
      //         handleMaterialSelection(record.key, e.target.checked)
      //       }
      //     />
      //   ),
      // },
      {
        title: "S.No",
        dataIndex: "key",
        key: "key",
        fixed: "left",
        width: 50,
      },
      {
        title: "Material Name",
        dataIndex: "materialName",
        key: "materialName",
        fixed: "left",
        width: 250,
      },
      {
        title: "Allocated Qty",
        dataIndex: "awardedQty",
        key: "awardedQty",
        width: 50
      },
      {
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        fixed: "left",
        width: 100,
        render: (text: number, record: any) => (
          <span>
            {text} {record.uomName}
          </span>
        ),
      },
      {
        title: "Target Price (TP)",
        dataIndex: "targetPrice",
        key: "targetPrice",
        fixed: "left",
        width: 100,
        render: (text: number, record: any, index: any) => (
          // <Input
          //   value={inputValues[record.key] || ""}
          //   onChange={(e) => updateTargetPrice(record.key, e.target.value)}
          //   onBlur={() => handleBlur(record.key)}
          // />
          <Form.Item
            name={`targetPrice_${index}`}
            style={{ margin: 0 }}
            // rules={[
            //   {
            //     type: "number",
            //     min: 1,
            //     message: `${title} must > 0`,
            //   },
            // ]}
            initialValue={record?.targetPrice}
          >
            <InputNumber
              defaultValue={record?.targetPrice}
              className="w-100"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
              onKeyPress={(event) => {
                if (!/^[0-9]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        ),
      },
    ];

    const getSupplierInitials = (name: string) => {
      const words = name.split(" ");
      if (words.length > 1) {
        return `${words[0][0]}${words[1][0]}`.toUpperCase();
      }
      return name.substring(0, 2).toUpperCase();
    };

    const colors = [
      "#173F5F",
      "#20639B",
      "#3CAEA3",
      "#F6D55C",
      "#ED553B",
      "#e81e63",
      "#03a9f4",
      "#cddc39",
      "#9c27b0",
      "#ffeb3b",
    ];

    const handleSupplierClick = async (supplierID: string) => {
      try {
        console.log("supplierID", supplierID);
        const { response, status } = await quotationApis.latestBySupplierQuotationId(rfq.quotationId, supplierID);
        if (status === true) {
          console.log("data::::@@@@@@@", response.data);
          setSupplierDetailsData(response.data);
          setSupplierDetailsDrawerVisible(true);
        } else {
          console.log("response");
        }
      } catch {
        console.log("error");
      }
    }

    _supplierQuotations?.forEach((supplier: any, index: any) => {
      console.log("2222:::", supplier);
      const colorIndex = index % colors.length;
      _columns.push({
        title: (
          <div className="supplier-name-wrapper">
            <div className="supplier-name">
              <div onClick={() => handleSupplierClick(supplier.supplierID)} style={{ display: "flex", justifyContent: "center" }}>
                <Popover content={supplier.supplierName} placement="top">
                  <div
                    className="supplier-icon"
                    style={{ backgroundColor: colors[1], marginRight: 10 }}
                  >
                    {getSupplierInitials(supplier.supplierName)}
                  </div>
                </Popover>
                {supplier.score && supplier.score.rank ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "green",
                      marginLeft: 10,
                    }}
                  >
                    <TrophyOutlined style={{ marginRight: 5 }} />
                    <span>{supplier.score.rank}</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div>Price</div>
          </div>
        ),
        key: supplier.supplierID,
        dataIndex: `totalPrice_${supplier.supplierID}`,
        width: 150,
        render: (value: any, record: any) => {
          console.log("value:::record", value, record);
          const { unitPrice, totalPrice, supplyQty } = value || {
            unitPrice: 0,
            totalPrice: 0,
            supplyQty: 0
          };
          console.log("unitPrice:::", unitPrice);
          return (
            <div style={{ padding: 4, margin: 0, textAlign: "right" }}>
              <div>
                Supply Qty:{" "}
                {supplyQty}
              </div>
              <div>
                Unit Price:{" "}
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                }).format(unitPrice)}
              </div>
              <div
                style={{
                  backgroundColor: getColor(totalPrice, record.targetPrice),
                  padding: 4,
                  margin: 0,
                }}
              >
                Total Price:{" "}
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                }).format(totalPrice)}
              </div>
            </div>
          );
        },
      });
    });

    console.log("Columns:::", _columns)

    setColumns(_columns)
    return _columns;
  };

  const generateDataSource = (materials: Material[], _supplierQuotations: any): Record<any, any>[] => {
    console.log("dataSourceee::::", materials);

    return materials.map((material) => {
      console.log("ma;;;;", material);

      console.log("quotationLineId", material.quotationLineId);
      // console.log("1111:::", supplierQuotations);

      const data: Record<string, any> = {
        key: material.key,
        materialName: material.materialName,
        awardedQty: material.awardedQty,
        qty: material.qty,
        uom: material.uom,
        targetPrice: material.targetPrice,
      };
      _supplierQuotations.forEach((supplier: any) => {
        const quotation = supplier.quotation.find(
          (q: any) => q.quotationLineId === material.quotationLineId
        );
        console.log("quotationLineId", quotation);
        if (quotation) {
          console.log("1111:::IFF", quotation, material);
          data[`totalPrice_${supplier.supplierID}`] = {
            quotationLineId: material.quotationLineId,
            supplyQty: quotation.supplyQty,
            materialID: material.materialID,
            unitPrice: quotation.unitPrice,
            totalPrice: quotation.unitPrice * quotation.supplyQty,
          };
        }
      });
      console.log("data:::return", data);
      return data;
    });
  };

  // const columns = 
  // let dataSource:any;
  const showStatusIndicatorDrawer = () => {
    setStatusIndicatorDrawerVisible(true);
  };

  const closeStatusIndicatorDrawer = () => {
    setStatusIndicatorDrawerVisible(false);
  };

  const handleStatusIndicatorSubmit = (values: any) => {
    console.log("Submitted Values: ", values);
    setStatusIndicatorRanges(values);
    message.success("Status indicators have been set successfully.");
  };
  // State to control the ScenarioBuilderDrawer visibility
  const [scenarioBuilderVisible, setScenarioBuilderVisible] = useState(false);

  // Handler to open the ScenarioBuilderDrawer
  const openScenarioBuilder = (e: any) => {
    e.preventDefault();
    getScenarioBuilder();
    setScenarioBuilderVisible(true);
  };

  // Handler to close the ScenarioBuilderDrawer
  const closeScenarioBuilder = () => {
    setScenarioBuilderVisible(false);
  };

  const closeSupplierDrawer = () => {
    setSupplierDetailsDrawerVisible(false)
  }

  // Handler to handle the form submission from ScenarioBuilderDrawer
  const handleScenarioSubmit = (values: any) => {
    console.log("Scenario Values: ", values, rfq.quotationId);
    const formatData = {
      quotationId: rfq.quotationId,
      materialCoverage: values.materialCoverage,
      volumeCoverage: values.volumeCoverage,
      creditCoverage: values.longCreditDays,
      quotationValueCoverage: values.quotationValue,
      onTimeDeliveryCoverage: values.onTimeDelivery,
      delayPercentage: values.delayPercentage,
      credit30days: values.credit30Days,
    };
    console.log("formatData", formatData);
    addScenarioBuilder(formatData);
  };

  const addScenarioBuilder = async (data: any) => {
    try {
      const { response, status } = await quotationApis.addScenarioBuilder(data);
      if (status) {
        message.success("Scenario has been added successfully.");
        closeScenarioBuilder();
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleMaterialSelection = (key: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedMaterials((prev) => [...prev, key]);
    } else {
      setSelectedMaterials((prev) => prev.filter((item) => item !== key));
    }
  };

  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedMaterials(materials.map((material: any) => material.key));
    } else {
      setSelectedMaterials([]);
    }
    setIsAllSelected(isSelected);
  };

  const openAllocationDrawer = (e: any) => {
    e.preventDefault();
    if (selectedMaterials.length > 0) {
      setAllocationDrawerVisible(true);
    } else {
      message.warning("Please select at least one material to allocate.");
    }
  };

  const closeAllocationDrawer = () => {
    setAllocationDrawerVisible(false);
    getSupplierQuotations();
  };

  const handleAllocationSubmit = (values: SubmittedDataItem[]) => {
    console.log("Submitted Values::::1111", values);
    setSubmittedData((prev) => {
      const updatedData = [...prev, ...values];
      console.log("updatedData ", JSON.stringify(updatedData));
      const totalRequired = materials.reduce(
        (acc: any, material: any) => acc + material.qty,
        0
      );
      const totalAwarded = materials.reduce((acc: any, material: any) => {
        // Filter the updatedData for the current material
        const total = updatedData
          .filter((item) => item.quotationLineId === material.quotationLineId)
          .reduce((sum, item) => sum + parseFloat(item.awardQty), 0);

        // Add the total awarded quantity for this material to the accumulator
        return acc + total;
      }, 0);

      console.log("totalAwarded ", totalAwarded);
      console.log("materials.length ", totalRequired);

      const allocationStatus =
        totalAwarded === 0
          ? "Not Awarded"
          : totalAwarded === totalRequired
            ? "Fully Awarded"
            : "Partially Awarded";

      const allocationStatusColor =
        allocationStatus === "Fully Awarded"
          ? "green"
          : allocationStatus === "Partially Awarded"
            ? "orange"
            : "red";
      setAllocationStatus(allocationStatus);
      setAllocationStatusColor(allocationStatusColor);
      console.log("updatedData");
      return updatedData;
    });
    console.log("SetSubmittedData", submittedData)
    createAllocationSubmit(values);
  };

  const createAllocationSubmit = async (values: any) => {
    console.log("values", values);
    try {
      const { response, status } = await quotationApis.shortListSuppliers(rfq.quotationId, { list: values });
      if (status) {
        message.success("Successfully awarded!");
      }
    } catch (error: any) {
      message.error(error.message);
    }
  }

  useEffect(() => {
    console.log("submittedData updated:", submittedData);
  }, [submittedData, allocationStatus, allocationStatusColor]);

  useEffect(() => {
    (async () => {
      const QData = await getSupplierQuotations();
      console.log("IF&IF")
      generateColumns(updateTargetPrice, inputValues, handleBlur, QData)
    })()
  }, []);

  const getSupplierQuotations = async () => {
    try {
      const { response, status } = await quotationApis.quoteComparision(
        rfq.quotationId
      );
      console.log("setSupplierQuotations", response);
      if (status) {
        setCountsData(response.data.counts)
        let data = response.data.supplierHeaders;
        console.log("data:::", data);
        setSupplierQuotations(response.data.supplierHeaders);



        let data2 = response.data.materials.map((item: any, index: any) => {
          return {
            ...item,
            key: index + 1,
          };
        });
        console.log("data2:::", data2);
        setInitialMaterials(data2);
        setMaterials(data2);
        let returnData: any = generateDataSource(data2, response.data.supplierHeaders);
        console.log("returnData", returnData);
        setDataSource(returnData);
        return response?.data?.supplierHeaders
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    console.log("UseEffect:::", columns)
  }, []);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [shortlistedData, setShortlistedData] = useState([]);

  const showDrawer = async () => {
    if (countsData?.shortListCount == 0) {
      message.warning("There are no shortlisted suppliers.");
      return;
    }
    // // alert("Hi");
    const { response, status } = await quotationApis.getShortListed(rfq.quotationId);
    if (status) {
      console.log("log:::LIST", response)
      // console.log("map list ", submittedData, supplierQuotations)
      const data: ShortlistedSupplier[] = response.data.suppliers;
      console.log(data);
      setShortlistedData(data as any);
      setDrawerVisible(true);
    }

  };

  const [scenarioBuilderData, setScenarioBuilderData] = useState([]);

  const getScenarioBuilder = async () => {
    try {
      const { response, status } = await quotationApis.getScenarioBuilder(
        rfq.quotationId
      );
      if (status) {
        setScenarioBuilderData(response.data);
        console.log("setScenarioBuilderData", response.data);
      }
    } catch (error) { }
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const saveTargetPriceValue = async () => {
    // let data =  form.validateFields()
    // console.log("3333",data,materials)
    let editValues: any = await form.validateFields();
    const targets = Object.keys(editValues).map(key => {
      const index = parseInt(key.split('_')[1], 10); // Extract index from the key
      const targetPrice = editValues[key];
      console.log("index", index)
      // Use the index to find the corresponding material
      const material = materials[index];
      console.log("material", material)
      if (!material) {
        console.warn(`No material found for index ${index}`);
        return null;
      }

      return {
        quotationLineId: material.quotationLineId,
        targetPrice: targetPrice
      };
    }).filter(item => item !== null); // Filter out any null values

    console.log("target", targets)
    saveAPICall(targets)
  }

  const saveAPICall = async (targets: any) => {
    try {
      const { response, status } = await quotationApis.addTargetPrice({ targets: targets });
      if (status) {
        Swal.fire("Saved", "Target Price is Set", "success");
        getSupplierQuotations();
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  }

  const onRowSelection = (newSelectedRowIds: any) => {
    setSelectedMaterials(newSelectedRowIds)
  }
  return (
    <div className="table-container">
      <Row>
        <Col lg="12">
          <Card
            className="card-bordered"
            style={{ minHeight: "500px", overflowY: "auto" }}
          >
            <div className="card-inner">
              <div className="status-indicator-wrapper">
                <Space>
                  <div className="status-indicator outlier">
                    <span
                      className="color-box"
                      style={{ backgroundColor: "#f5222d" }}
                    ></span>
                    Outlier
                  </div>
                  <div className="status-indicator slightly-high">
                    <span
                      className="color-box"
                      style={{ backgroundColor: "#fa8c16" }}
                    ></span>
                    Slightly High
                  </div>
                  <div className="status-indicator high">
                    <span
                      className="color-box"
                      style={{ backgroundColor: "#faad14" }}
                    ></span>
                    High
                  </div>
                  <div className="status-indicator very-high">
                    <span
                      className="color-box"
                      style={{ backgroundColor: "#fadb14" }}
                    ></span>
                    Very High
                  </div>
                  <Tooltip title="Edit">
                    <EditOutlined
                      className="edit-icon"
                      onClick={showStatusIndicatorDrawer}
                    />
                  </Tooltip>
                  <Tooltip title="Save Target Price">
                    <div style={{ marginLeft: "10px", marginTop: "-2px" }}>
                      <SaveOutlined
                        className="edit-icon"
                        onClick={saveTargetPriceValue}
                      />
                    </div>

                  </Tooltip>
                </Space>
                <div>
                  <a onClick={openAllocationDrawer}>
                    Click here to allocate award{" "}
                    {/* <span style={{ color: allocationStatusColor }}>
                      {allocationStatus}
                    </span> */}
                  </a>
                </div>
                <div>
                  <Space>
                    <Tooltip title="Click to view shortlisted suppliers">
                      <a onClick={showDrawer}>
                        Shortlisted Suppliers:{" "}
                        {countsData?.shortListCount}
                      </a>
                    </Tooltip>
                  </Space>
                </div>
              </div>

              <div className="scenarioBuilderButton">
                <a
                  href="#"
                  className="btn btn-dim btn-outline-light"
                  onClick={openScenarioBuilder}
                >
                  <BarChartOutlined /> &nbsp; Scenario Builder
                </a>
                {/* <a
                  href="#"
                  className="btn btn-dim btn-outline-light"
                  onClick={openScenarioBuilder}
                  // disabled={true}
                >
                  <BarChartOutlined /> &nbsp; Add Supplier Quote
                </a> */}
              </div>
              <div id="comparison-table">
                <Form form={form} component={false}
                >
                  <Table
                    dataSource={dataSource}
                    rowSelection={{ selectedRowKeys: selectedMaterials, onChange: onRowSelection }}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: "max-content" }}
                    size="small"
                    bordered
                    style={{ paddingTop: 50 }}
                    summary={() => (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={6} align="right">
                            <span className="summary-header">Sub Total</span>
                          </Table.Summary.Cell>
                          {supplierQuotations.map((supplier, index) => (
                            <Table.Summary.Cell
                              key={supplier.supplierID}
                              index={index + 4}
                              align="right"
                            >
                              {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              }).format(supplier.subTotal)}
                            </Table.Summary.Cell>
                          ))}
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={6} align="right">
                            <span className="summary-header">Total Taxes</span>
                          </Table.Summary.Cell>
                          {supplierQuotations.map((supplier, index) => (
                            <Table.Summary.Cell
                              key={supplier.supplierID}
                              index={index + 4}
                              align="right"
                            >
                              {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              }).format(supplier.totalTaxes)}
                            </Table.Summary.Cell>
                          ))}
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={6} align="right">
                            <span className="summary-header">Payment Terms</span>
                          </Table.Summary.Cell>
                          {supplierQuotations.map((supplier, index) => (
                            <Table.Summary.Cell
                              key={supplier.supplierID}
                              index={index + 4}
                              align="right"
                            >
                              {supplier.paymentTerms}
                            </Table.Summary.Cell>
                          ))}
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={6} align="right">
                            <span className="summary-header">Lead Time</span>
                          </Table.Summary.Cell>
                          {supplierQuotations.map((supplier, index) => (
                            <Table.Summary.Cell
                              key={supplier.supplierID}
                              index={index + 4}
                              align="right"
                            >
                              {supplier.leadTime}
                            </Table.Summary.Cell>
                          ))}
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={6} align="right">
                            <span className="summary-header">Grand Total</span>
                          </Table.Summary.Cell>
                          {supplierQuotations.map((supplier, index) => (
                            <Table.Summary.Cell
                              key={supplier.supplierID}
                              index={index + 4}
                              align="right"
                            >
                              {new Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              }).format(supplier.grandTotal)}
                            </Table.Summary.Cell>
                          ))}
                        </Table.Summary.Row>
                      </>
                    )}
                  />

                </Form>

              </div>
            </div>
            <StatusIndicatorDrawer
              visible={statusIndicatorDrawerVisible}
              onClose={closeStatusIndicatorDrawer}
              onSubmit={handleStatusIndicatorSubmit} // Handle submission from the child component
            />
            <ScenarioBuilderDrawer
              scenarioBuilderData={scenarioBuilderData}
              visible={scenarioBuilderVisible}
              onClose={closeScenarioBuilder}
              onSubmit={handleScenarioSubmit}
            />
            <AllocationDrawer
              visible={allocationDrawerVisible}
              onClose={closeAllocationDrawer}
              materials={materials?.filter((material: any) =>
                selectedMaterials.includes(material.key)
              )}
              supplierQuotations={supplierQuotations}
              submittedData={submittedData}
              onSubmit={handleAllocationSubmit} // Ensure this is correctly passed
            />
            <ShortlistedSuppliersDrawer
              visible={drawerVisible}
              onClose={closeDrawer}
              rfqId={rfq.quotationId}
              siteName={"siteName"}
              data={shortlistedData}
            />
            {supplierDetailsDrawerVisible}
            {
              supplierDetailsDrawerVisible &&
              <SupplierDetailsDrawer
                visible={supplierDetailsDrawerVisible}
                onClose={closeSupplierDrawer}
                rfqId={rfq.quotationId}
                siteName={"siteName"}
                dataDrawer={supplierDetailsData}
              />
            }

          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default QuotationComparison;
