import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Form, Row, Alert, Col, Button } from "reactstrap";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import FormInput from "../form-components/InputComponent";
import { Icon, TooltipComponent } from "../../components/Component";
import FormSelect from "../form-components/SelectComponent";
import FormDatePicker from "../form-components/DatePicker";
import FormTextArea from "../form-components/TextAreaComponent";
import AntSelect from "../form-components/AntSelectComponent";
import { Popover } from "antd";
import adminOrgApis from "../../api/master/adminOrg";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import misc from "../../api/misc/dropDowns";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import moment from "moment";
import materialsApis from "../../api/master/materials";
import supplierApis from "../../api/master/supplier";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";

interface SelectOptions {
  label: string;
  value: any;
}
interface bomLine {
  categoryId: string;
  categoryName: string;
  materialId: string;
  materialName: string;
  quantity: string;
  allocated: string;
}

interface FormData {
  budgetLineName: string;
  budgetValue: string;
  startDate: any;
  endDate: any;
  bom: string;
  allocation: string;
  lineDescription: string;
  currencyCode: string;
  bomLines: bomLine[];
}
const MaterialModal: FC<any> = ({
  modal,
  closeModal,
  appenddata,
  update,
  formData,
  status,
}) => {
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
  const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);

  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);

  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
    setValue,
  } = methods;

  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "bomLines",
  });

  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });

  const billOfMaterialWatch = watch("bom");

  const addNewDocument = () => {
    // if (fields.length < 10) {
    append({
      categoryId: "",
      categoryName: "",
      materialId: "",
      materialName: "",
      quantity: "",
      allocated: "",
    });
    // }
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  useEffect(() => {
    if (modal && formData.item) {
      console.log(formData.item, "formData.item");
      reset({
        budgetLineName: formData.item.budgetLineName,
        budgetValue: formData.item.budgetValue,
        startDate: dayjs(formData.item.startDate),
        endDate: dayjs(formData.item.endDate),
        bom: formData.item.bom,
        currencyCode: formData.item.currencyCode,
        allocation: formData.item.allocation,
        lineDescription: formData.item.lineDescription,
        bomLines: formData.item.bomLines,
      });
    } else {
      reset({
        budgetLineName: "",
        budgetValue: "",
        startDate: "",
        endDate: "",
        bom: "",
        allocation: "",
        lineDescription: "",
        currencyCode: "",
        bomLines: [],
      });
    }
  }, [formData.item, modal, reset]);

  //   useEffect(() => {
  //     if (billOfMaterialWatch === "Y" && fields.length === 0) {
  //       append({
  //         categoryId: "",
  //         categoryName: "",
  //         materialId: "",
  //         materialName: "",
  //         quantity: "",
  //         allocated: "",
  //       });
  //     }
  //   }, [billOfMaterialWatch, append, fields.length]);

  const fetchCategorys = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const categoryOption = data.map((item: any) => ({
          label: item.category,
          value: item.id.toString(),
        }));
        setCategoryOptions(categoryOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaterials = async () => {
    try {
      const { data, status } = await materialsApis.fetchProduct();
      if (status) {
        const materialOption = data.map((item: any) => ({
          label: item.materialName,
          value: item.materialID.toString(),
        }));
        setMaterialOptions(materialOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryChange = (index: number, value: string) => {
    const selectedCategory = categoryOptions.find(
      (option) => option.value === value
    );
    if (selectedCategory) {
      setValue(`bomLines.${index}.categoryId`, value);
      setValue(`bomLines.${index}.categoryName`, selectedCategory.label);
    }
  };

  const handleMaterialChange = (index: number, value: string) => {
    const selectedMaterial = materialOptions.find(
      (option) => option.value === value
    );
    if (selectedMaterial) {
      setValue(`bomLines.${index}.materialId`, value);
      setValue(`bomLines.${index}.materialName`, selectedMaterial.label);
    }
  };

  //   const submitForm = (data: any) => {
  //     const {
  //       materialCategory,
  //       materialName,
  //       hsnCode,
  //       quantity,
  //       unitPrice,
  //       igst,
  //       cgst,
  //       description,
  //       uomId,
  //       needByDate,
  //       attachment,
  //       deliveryAddressId,
  //     } = data;
  //     let _cess: Cess[] = [];
  //     let cessTotal = 0;
  //     taxConfiguration?.cess?.forEach((cessItem: any, index: any) => {
  //       if (!cessItem.cessName) return;
  //       const cessPercentage = data[cessItem.cessName];
  //       const cessAmount = (cessPercentage * quantity * unitPrice) / 100;
  //       cessTotal += cessAmount;
  //       _cess.push({
  //         cessName: cessItem.cessName,
  //         cessType: cessItem.cessType,
  //         cessPercentage,
  //         cessAmount: cessAmount?.toString(),
  //       });
  //     });
  //     const item = {
  //       materialName,
  //       hsnCode,
  //       quantity,
  //       unitPrice,
  //       amount: quantity * unitPrice,
  //       description,
  //       igst: 0,
  //       igstPercentage: 0,
  //       igstAmount: 0,
  //       cgst: 0,
  //       cgstPercentage: 0,
  //       cgstAmount: 0,
  //       sgst: 0,
  //       sgstPercentage: 0,
  //       sgstAmount: 0,
  //       lineTotal: 0,
  //     };
  //     item.igst = 0;
  //     item.igstAmount = 0;
  //     item.igstPercentage = 0;
  //     item.cgst = 0;
  //     item.cgstAmount = 0;
  //     item.cgstPercentage = 0;
  //     item.sgst = 0;
  //     item.sgstAmount = 0;
  //     item.sgstPercentage = 0;
  //     let selectedTax;
  //     switch (taxConfiguration?.taxType) {
  //       case "IGST":
  //         item.igst = igst;
  //         selectedTax = TAX_SLAP_DROPDOWN.find(
  //           (dropdownItem) => dropdownItem.value === igst.toString()
  //         );
  //         if (selectedTax) {
  //           const igstRate = parseFloat(selectedTax.label.replace("%", "")) || 0;
  //           item.igstPercentage = igstRate;
  //           item.igstAmount = (igstRate * item.amount) / 100;
  //         } else {
  //           item.igstPercentage = 0;
  //           item.igstAmount = 0;
  //         }
  //         break;
  //       case "CGST":
  //         item.cgst = cgst;
  //         item.sgst = cgst;
  //         selectedTax = TAX_SLAP_DROPDOWN.find(
  //           (dropdownItem) => dropdownItem.value === cgst.toString()
  //         );

  //         if (selectedTax) {
  //           const cgstRate = parseFloat(selectedTax.label.replace("%", "")) || 0;
  //           item.cgstPercentage = cgstRate / 2;
  //           item.sgstPercentage = cgstRate / 2;
  //           item.cgstAmount = (item.cgstPercentage * item.amount) / 100;
  //           item.sgstAmount = (item.sgstPercentage * item.amount) / 100;
  //         } else {
  //           item.cgstPercentage = 0;
  //           item.sgstPercentage = 0;
  //           item.cgstAmount = 0;
  //           item.sgstAmount = 0;
  //         }
  //         break;
  //       default:
  //         break;
  //     }

  //     if (igst) {
  //       item.igst = igst;
  //       const selectedTax = TAX_SLAP_DROPDOWN.find(
  //         (dropdownItem) => dropdownItem.value === igst.toString()
  //       );
  //       if (selectedTax) {
  //         const igstRate = parseFloat(selectedTax.label.replace("%", "")) || 0;
  //         item.igstPercentage = igstRate;
  //         item.igstAmount = (igstRate * item.amount) / 100;
  //       } else {
  //         item.igstPercentage = 0;
  //         item.igstAmount = 0;
  //       }
  //     }
  //     if (cgst) {
  //       item.cgst = cgst;
  //       item.sgst = cgst;
  //       const selectedTax = TAX_SLAP_DROPDOWN.find(
  //         (dropdownItem) => dropdownItem.value === cgst.toString()
  //       );

  //       if (selectedTax) {
  //         const cgstRate = parseFloat(selectedTax.label.replace("%", "")) || 0;
  //         item.cgstPercentage = cgstRate / 2;
  //         item.sgstPercentage = cgstRate / 2;
  //         item.cgstAmount = (item.cgstPercentage * item.amount) / 100;
  //         item.sgstAmount = (item.sgstPercentage * item.amount) / 100;
  //       } else {
  //         item.cgstPercentage = 0;
  //         item.sgstPercentage = 0;
  //         item.cgstAmount = 0;
  //         item.sgstAmount = 0;
  //       }
  //     }
  //     console.log(item);

  //     item.lineTotal =
  //       item.amount +
  //       item.igstAmount +
  //       item.cgstAmount +
  //       item.sgstAmount +
  //       cessTotal;
  //     const materialLabel = materialOptions.find(
  //       (item) => item.value == materialName
  //     )?.label;
  //     const uomLabel = uomOptions.find((item) => item.value == uomId)?.label;
  //     const formResult = {
  //       categoryId: materialCategory,
  //       materialId: materialName,
  //       materialName: materialLabel,
  //       hsnCode,
  //       uomId,
  //       description,
  //       file: attachment,
  //       quantity,
  //       unitPrice,
  //       deliveryAddressId,
  //       needByDate: dayjs(needByDate).format("DD-MMM-YYYY"),
  //       uomShortCode: uomLabel,
  //       amount: item.amount.toString(),
  //       // igst: item.igst.toString(),
  //       igstPercentage: item.igstPercentage.toString(),
  //       igst: item.igstAmount.toString(),
  //       // cgst: item.cgst.toString(),
  //       cgstPercentage: item.cgstPercentage.toString(),
  //       cgst: item.cgstAmount.toString(),
  //       // sgst: item.sgst.toString(),
  //       sgstPercentage: item.sgstPercentage.toString(),
  //       sgst: item.sgstAmount.toString(),
  //       cess: _cess,
  //       netAmount: item.lineTotal,
  //       attachment: attachment?.length ? true : false,
  //     };
  //     if (formData.action == "add" || !formData.index) {
  //       append({
  //         uuid: nanoid(),
  //         lineNo: null,
  //         ...formResult,
  //       });
  //     } else {
  //       update(formData.index, {
  //         uuid: formData.index,
  //         lineNo: formData.lineNo,
  //         ...formResult,
  //       });
  //     }
  //     closeModal();
  //   };
  //   const ShowAddressPopOver = ({ id, text, options, matchKey }: any) => {
  //     if (!id) {
  //       return <></>;
  //     }
  //     let address = options?.find((item: any) => item[matchKey] == id);
  //     if (!address) {
  //       return <></>;
  //     }
  //     return (
  //       <Popover
  //         content={
  //           <ul>
  //             <li>
  //               {address.addressLine1} {address.addressLine2}
  //             </li>
  //             <li>
  //               {address.city}, {address?.stateName}, {address?.countryName} -{" "}
  //               {address.pincode}
  //             </li>
  //           </ul>
  //         }
  //         title="Selected Address"
  //       >
  //         <a>{text}</a>
  //       </Popover>
  //     );
  //   };
  useEffect(() => {
    fetchMaterials();
    fetchCategorys();
  }, []);

  const submitForm = (dataForm: any) => {
    console.log("formData", formData.item);
    // Log the incoming data for debugging
    console.log("dataForm", dataForm);

    // Extract the existing data for the current item being edited
    const existingData = formData.item || {};

    // Merge existing data with the updated form data
    const updatedData = {
      ...existingData,
      ...dataForm,
    };

    delete updatedData.boms;
    // updatedData.boms = updatedData.bomLines;
    // delete updatedData.bomLines;
    console.log("Updated Data", updatedData);
    if (updatedData.bomLines.length > 0) {
      updatedData.bomLines = updatedData.bomLines.map((bom: any) => {
        bom.quantity = parseInt(bom.quantity);
        bom.allocated = parseInt(bom.allocated);
        return bom;
      });
    }

    updatedData.budgetValue = parseInt(updatedData.budgetValue);

    updatedData.startDate = dayjs(updatedData.startDate).toDate();
    updatedData.endDate = dayjs(updatedData.endDate).toDate();
    console.log("Updated Data", updatedData);

    if (formData.action === "add" || !formData.index) {
      // Append the new line if it's a new entry
      append({
        ...updatedData,
      });
    } else {
      // Update the existing line with the merged data
      update(formData.index, {
        ...updatedData,
      });
    }

    // Close the modal after the update
    closeModal();
  };

  return (
    <Modal isOpen={modal} toggle={closeModal} size="xl">
      <ModalBody>
        <h5 className="title">
          {formData.action == "add" || formData.index == null
            ? "Add New Line"
            : "Update Line"}
        </h5>
        <div style={{ marginTop: "15px" }}></div>
        {alertInfo.message && (
          <div className="mb-3 mt-1">
            <Alert
              color={alertInfo.type === "error" ? "danger" : "success"}
              className="alert-icon"
            >
              <strong>{alertInfo.message}</strong>
              <Icon
                name={
                  alertInfo.type === "error" ? "alert-circle" : "check-circle"
                }
              />
              {alertInfo.errors.length > 0 ? (
                <ul>
                  {alertInfo.errors.map((err: any, index: any) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </Alert>
          </div>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitForm)}>
            <Row className="gy-4 mt-2">
              <Col lg="6">
                <FormInput
                  name="budgetLineName"
                  label="Budget Line Name"
                  required={true}
                  placeholder="Enter Budget Line Name"
                />
              </Col>

              <Col lg="6">
                <FormInput
                  name="budgetValue"
                  label="Budget Value"
                  required={true}
                  placeholder="Enter Budget Value"
                />
              </Col>
              <Col lg="6">
                <FormDatePicker
                  name="startDate"
                  label="Start Date"
                  placeholder="Select Start Date"
                  required={false}
                />
              </Col>
              <Col lg="6">
                <FormDatePicker
                  name="endDate"
                  label="End Date"
                  placeholder="Select End Date"
                  required={false}
                />
              </Col>
              <Col lg="6">
                <FormSelect
                  name="bom"
                  label="Bill Of Material (BOM)"
                  options={[
                    { label: "Yes", value: "Y" },
                    { label: "No", value: "N" },
                  ]}
                  required={true}
                  placeholder="Select BOM"
                  onChange={(e) => {
                    console.log("e.target.value", e);
                    if (e === "Y" && fields.length === 0) {
                      append({
                        categoryId: "",
                        categoryName: "",
                        materialId: "",
                        materialName: "",
                        quantity: "",
                        allocated: "",
                      });
                    } else if (e === "N") {
                      reset({
                        ...methods.getValues(),
                        bomLines: [],
                      });
                    }
                  }}
                />
              </Col>
              <Col lg="6">
                <FormSelect
                  name="allocation"
                  label="BOM Allocation"
                  options={[
                    { label: "Value", value: "value" },
                    { label: "%", value: "percentage" },
                  ]}
                  required={true}
                  placeholder="Select BOM Allocation"
                />
              </Col>
              <Col lg="12">
                <FormTextArea
                  name="lineDescription"
                  label="Budget Line Description"
                  required={true}
                  placeholder="Enter Budget Line Description"
                />
              </Col>
            </Row>

            {billOfMaterialWatch === "Y" && (
              <Row className="gy-4 mt-2">
                {fields.map((field, index) => (
                  <Row className="mt-2" key={field.id}>
                    <Col lg="3">
                      <FormSelect
                        name={`bomLines.${index}.categoryId`}
                        label="Category"
                        options={categoryOptions}
                        required={true}
                        onChange={(value) => handleCategoryChange(index, value)}
                      />
                    </Col>
                    <Col lg="3">
                      <FormSelect
                        name={`bomLines.${index}.materialId`}
                        label="Material Name"
                        options={materialOptions}
                        required={true}
                        onChange={(value) => handleMaterialChange(index, value)}
                      />
                    </Col>
                    <Col lg="2">
                      <FormInput
                        name={`bomLines.${index}.quantity`}
                        label="Qty"
                        required={true}
                      />
                    </Col>
                    <Col lg="2">
                      <FormInput
                        name={`bomLines.${index}.allocated`}
                        label="Allocation"
                        required={true}
                      />
                    </Col>

                    <Col lg="2" className="d-flex align-items-center">
                      <a
                        onClick={() => remove(index)}
                        className="danger"
                        style={{ fontSize: 16, color: "red", marginTop: 14 }}
                      >
                        <MinusCircleOutlined /> Remove
                      </a>
                    </Col>
                  </Row>
                ))}
                <Row className="mt-2">
                  <Col lg="12">
                    <a
                      onClick={addNewDocument}
                      className="primary"
                      style={{ fontSize: 16 }}
                    >
                      <PlusCircleOutlined /> Add More
                    </a>
                  </Col>
                </Row>
              </Row>
            )}
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  {formData.action === "edit" && status !== "Rejected" && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Update
                      </Button>
                    </li>
                  )}
                  {formData.action == "add" && status !== "Rejected" && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Save
                      </Button>
                    </li>
                  )}
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default MaterialModal;
