import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Badge, Button, Card, Col, Row } from "reactstrap";
import InvoiceAside from "./InvoiceAside";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  PaperClipOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
  PlusSquareOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Table,
  Collapse as AntCollapse,
  Button as AntButton,
  Descriptions,
  Modal as AntModal,
  Spin,
  Flex,
  Drawer,
  Upload,
} from "antd";
import dayjs from "dayjs";
import InvoiceComponent from "./InvoiceComponent";
function ViewInvoiceDetails() {

  let { id }: any = useParams();
  const navigate = useNavigate();
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);


  return (
    <>
      <Head title="Material Invoice" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg 
                ${sm ? "content-active" : ""}
              `}
            >
              <InvoiceAside updateSm={setSm} sm={sm} />
            </div>
              <InvoiceComponent invoiceNumber={id} previousPath={"/view-invoice-list"}/>
          </div>
        </Card>
      </Content>
    </>
  );
}

export default ViewInvoiceDetails;
