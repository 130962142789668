import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component"; // Assuming these are properly typed elsewhere
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";

interface PurchaseRequestAsideProps {
    updateSm: (value: boolean) => void;  // Function to update state
    sm: boolean;  // Boolean state
}

const QuotationsAside: FC<PurchaseRequestAsideProps> = ({ updateSm, sm }) => {
    const [profileName, setProfileName] = useState<string>("Abu Bin Ishtiak");
    const location = useLocation();

    useEffect(() => {
        sm ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{ fontSize: 14 }}>Quotations</h6>

                </div>
            </div>

            <div className="card-inner p-0">
                <ul className="link-list-menu">
                    <li onClick={() => updateSm(false)}>
                        <Link to="/analytics-rfq" className={isActive(`${process.env.PUBLIC_URL}/analytics-rfqs`)}>
                            <Icon name="growth-fill" />

                            <span>Analytics</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/new-rfq" className={isActive(`${process.env.PUBLIC_URL}/new-rfq`)}>
                            <Icon name="plus-fill-c" />
                            <span>Create RFQ</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/rfq-approvals" className={isActive(`${process.env.PUBLIC_URL}/rfq-approvals`)}>
                            <Icon name="check-fill-c" />
                            <span>Pending Approvals RFQ</span>
                        </Link>
                    </li>
                    {/* <li onClick={() => updateSm(false)}>
                        <Link to="/rfq-supplier-responses" className={isActive(`${process.env.PUBLIC_URL}/rfq-supplier-responses`)}>
                            <Icon name="chat" />
                            <span>Supplier Responses</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/rfq-comparison" className={isActive(`${process.env.PUBLIC_URL}/rfq-comparison`)}>
                            <Icon name="col-view" />
                            <span>Comparison</span>
                        </Link>
                    </li> */}
                    {/* <li onClick={() => updateSm(false)}>
                        <Link to="/rfq-conversion" className={isActive(`${process.env.PUBLIC_URL}/rfq-conversion`)}>
                            <Icon name="swap" />
                            <span>Conversion</span>
                        </Link>
                    </li> */}
                    <li onClick={() => updateSm(false)}>
                        <Link to="/view-rfqs" className={isActive(`${process.env.PUBLIC_URL}/view-rfqs`)}>
                            <Icon name="eye-fill" />
                            <span>View RFQ</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/qc-approvals" className={isActive(`${process.env.PUBLIC_URL}/qc-approvals`)}>
                            <Icon name="check-round-cut" />
                            <span>Pending Approvals QC</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/ready-to-award" className={isActive(`${process.env.PUBLIC_URL}/ready-to-award`)}>
                            <Icon name="award" />
                            <span>Ready to Award</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/track-rfqs" className={isActive(`${process.env.PUBLIC_URL}/track-rfqs`)}>
                            <Icon name="activity-round-fill" />
                            <span>Tracking</span>
                        </Link>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default QuotationsAside;