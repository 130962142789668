import React from 'react';
import { Avatar } from 'antd';
import { CheckCircleOutlined, FormOutlined, AuditOutlined, FileProtectOutlined, ShoppingCartOutlined, SafetyCertificateOutlined, FileDoneOutlined, RocketOutlined, CarOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { colors } from '../../utils/Utils';
const P2PWidget: React.FC = () => {
    const timelineData = [
        { title: 'Create PR', icon: <FormOutlined />, avgDays: 'N/A' },
        { title: 'Quotation Requested', icon: <AuditOutlined />, avgDays: '2 Days' },
        { title: 'Quoted', icon: <FileProtectOutlined />, avgDays: '3 Days' },
        { title: 'Quotation Finalized', icon: <CheckCircleOutlined />, avgDays: '1 Day' },
        { title: 'Purchase Order Raised', icon: <ShoppingCartOutlined />, avgDays: '2 Days' },
        { title: 'Purchase Order Approved', icon: <SafetyCertificateOutlined />, avgDays: '1 Day' },
        { title: 'Invoiced', icon: <FileDoneOutlined />, avgDays: '4 Days' },
        { title: 'Dispatched', icon: <RocketOutlined />, avgDays: '5 Days' },
        { title: 'Delivered', icon: <CarOutlined />, avgDays: '3 Days' },
        { title: 'Fully Paid', icon: <DollarCircleOutlined />, avgDays: '2 Days' },
    ];
    // const colors = [
    //     "#549CFB", // Base Blue (Darker)
    //     "#5A91F9", // Blue Shade 1
    //     "#6086F7", // Blue Shade 2
    //     "#6676FF", // Blue Shade 3
    //     "#6E7AF5", // Blue Shade 4
    //     "#757EEE", // Blue Shade 5
    //     "#7B83E8", // Blue Shade 6
    //     "#8179FF", // Blue Shade 7
    //     "#8A79FF", // Blue Shade 8
    //     "#937BFF", // Blue Shade 9
    //     "#9C7DFF", // Blue Shade 10
    //     "#A682FF", // Blue Shade 11
    //     "#B088FF", // Blue Shade 12
    //     "#BA8EFF", // Blue Shade 13
    //     "#C497FF"  // Lightest Blue
    // ]
    const stepWidth = '150px';  // Fixed width for each step

    return (
        <div>
            <div className="card-title-group mb-1">
                <div className="card-title">
                    <h6 className="title">Procure to Pay Cycle</h6>
                    <p>Tracking the end-to-end procurement process</p>
                </div>
            </div>
            <div style={{ position: 'relative' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {timelineData.map((item, index) => (
                        <React.Fragment key={index}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center', width: 100, height: '100px' }}>
                                <Avatar
                                    style={{backgroundColor: colors[index], color: '#fff', marginBottom: '8px', width: 50, height: 50 }}
                                    icon={item.icon}
                                />
                                <div>{item.title}</div>
                            </div>
                            {index < timelineData.length - 1 && (
                                <div style={{ flex: 1, height: '2px', backgroundColor: '#E5E9F2', alignSelf: 'center', marginBottom: '24px' }} />
                            )}
                        </React.Fragment>
                    ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', top:10, left:20 }}>
                    {timelineData.map((item, index) => (
                        index > 0 && (
                            <div key={index} style={{ width: stepWidth, textAlign: 'center' }}>
                                <span style={{ color: '#888' }}>{item.avgDays}</span>
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
};

export default P2PWidget;
