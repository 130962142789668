import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ProductContextProvider } from "../pages/pre-built/products/ProductContext";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";
import { ProtectedRoute } from "./ProtectedRoute";

import Invest from "../pages/Invest";

import Faq from "../pages/others/Faq";
import Regularv1 from "../pages/others/Regular-1";
import Regularv2 from "../pages/others/Regular-2";
import Terms from "../pages/others/Terms";
import UserListRegular from "../pages/pre-built/user-manage/UserListRegular";
import UserContactCard from "../pages/pre-built/user-manage/UserContactCard";
import UserDetails from "../pages/pre-built/user-manage/UserDetailsRegular";
import UserListCompact from "../pages/pre-built/user-manage/UserListCompact";
import UserProfileRegular from "../pages/pre-built/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/pre-built/user-manage/UserProfileSetting";
import UserProfileNotification from "../pages/pre-built/user-manage/UserProfileNotification";
import UserProfileActivity from "../pages/pre-built/user-manage/UserProfileActivity";
import KycListRegular from "../pages/pre-built/kyc-list-regular/KycListRegular";
import KycDetailsRegular from "../pages/pre-built/kyc-list-regular/kycDetailsRegular";
import TransListBasic from "../pages/pre-built/trans-list/TransListBasic";
import TransListCrypto from "../pages/pre-built/trans-list/TransListCrypto";
import ProductCard from "../pages/pre-built/products/ProductCard";
import ProductList from "../pages/pre-built/products/ProductList";
import ProductDetails from "../pages/pre-built/products/ProductDetails";

import InvoicePrint from "../pages/pre-built/invoice/InvoicePrint";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Success from "../pages/auth/Success";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";


//Procurex Pages

import AddNewPurchaseRequest from "../pages/purchase-requests/AddNewPurchaseRequest";
import ViewPurchaseRequests from "../pages/purchase-requests/ViewPurchaseRequests";
import PurchaseRequestAprrovals from "../pages/purchase-requests/PurchaseRequestApprovals";
import PurchaseRequestAnalytics from "../pages/purchase-requests/PurchaseRequestAnalytics";
import TrackPurchaseRequests from "../pages/purchase-requests/TrackPurchaseRequests";

import ViewBudgetList from "../pages/budget/ViewBudget";
import TrackBudget from "../pages/budget/TraclBudget";
import BudgetApprovals from "../pages/budget/BudgetApprovals";
import BudgetAnalytics from "../pages/budget/BudgetAnalytics";

import RFQCreation from "../pages/quotations/RFQCreations";
import RFQAnalytics from "../pages/quotations/RFQAnalytics";
import RFQApprovals from "../pages/quotations/RFQApprovals";
import RFQComparison from "../pages/quotations/RFQComparison";
import RFQConversion from "../pages/quotations/RFQConversion";
import RFQSupplierResponses from "../pages/quotations/RFQSupplierResponses";
import RFQView from "../pages/quotations/RFQView";
import RFQTracking from "../pages/quotations/RFQTracking";
import RFQFullView from "../pages/quotations/quotationView/RFQFullView";

import FACreations from "../pages/forward-auction/FACreations";
import FAApprovals from "../pages/forward-auction/FAApprovals";
import FAComparison from "../pages/forward-auction/FAComparison";
import FASupplierResponses from "../pages/forward-auction/FASupplierResponses";
import FAView from "../pages/forward-auction/FAView";
import FAHistory from "../pages/forward-auction/FAHistory";
import FAAnalytics from "../pages/forward-auction/FAAnalytics";

import RACreations from "../pages/reverse-auction/RACreation";
import RAApprovals from "../pages/reverse-auction/RAApprovals";
import RASupplierResponses from "../pages/reverse-auction/RASupplierResponses";
import RAComparison from "../pages/reverse-auction/RAComparison";
import RAConversion from "../pages/reverse-auction/RAConversion";
import RAView from "../pages/reverse-auction/RAView";
import RAHistory from "../pages/reverse-auction/RAHistory";
import RAAnalytics from "../pages/reverse-auction/RAAnalytics";

import OrgSetup from "../pages/masters/orgmaster/OrgSetup";
import CreateOrganisation from "../pages/masters/orgmaster/CreateOrganisation"
import SitesSetup from "../pages/masters/sites/SitesSetup";
import AddSites from "../pages/masters/sites/CreateSites";
import SitesViewDetails from "../pages/masters/sites/SitesViewDetails";
import Departments from "../pages/masters/departments/Departments";
import Roles from "../pages/masters/roles/Roles";
import ReportingHeirarchy from "../pages/masters/roles/ReportingHeirarchy";
import SupplierCategory from "../pages/masters/supplier/SupplierCategory";
import Suppliers from "../pages/masters/supplier/Suppliers";
import Contractors from "../pages/masters/contractors/Contractors";
import ViewSuppliersDetails from "../pages/masters/supplier/ViewSuppliersDetails";
import AddContractors from "../pages/masters/contractors/AddContractors";
import ViewContractorDetails from "../pages/masters/contractors/ViewContractorDetails"
import ApprovalHeirarchy from "../pages/masters/contractors/ApprovalHeirarchy";
import MaterialCategory from "../pages/masters/materials/MaterialCategory";
import Materials from "../pages/masters/materials/Material";

import CostCenter from "../pages/masters/cost-center/CostCenter";
import GeneralLedger from "../pages/masters/general-ledger/GeneralLedger";
import Consultants from "../pages/masters/consultants/Consultants";
import Users from "../pages/masters/Users/Users";
import CreateUsers from "../pages/masters/Users/CreateUsers";
import AddSupplier from "../pages/masters/supplier/AddSupplier";
import AddConsultants from "../pages/masters/consultants/AddConsultants";
import AddMaterial from "../pages/masters/materials/AddMaterial";
import ViewMaterialDetails from "../pages/masters/materials/ViewMaterialDetails";
import ViewConsultantDetails from "../pages/masters/consultants/ViewConsultantDetails";
import CreateRole from "../pages/masters/roles/CreateRole";
import UserViewDetails from "../pages/masters/Users/ViewUser";
import RoleViewDetails from "../pages/masters/roles/ViewRole";
import ViewOrgPage from "../pages/masters/orgmaster/ViewOrg";
import SetPassword from "../pages/auth/SetPassword";
import SuccessMain from "../pages/auth/SuccessMain";
import EmailError from "../pages/auth/EmailError";
import ResetPassword from "../pages/auth/ResetPassword";
import OrgLegalInformation from "../pages/masters/orgmaster/OrgLegalInformation";
import OrgContactInformation from "../pages/masters/orgmaster/orgContactInformation";
import OrgBankInformation from "../pages/masters/orgmaster/OrgBankInformation";

import PurchaseOrderMain from "../pages/purchase-orders/PurchaseOrderMain";
import DeliveryAddress from "../pages/masters/delivery-address/DeliveryAddress";
import ViewPurchaseOrdersList from "../pages/purchase-orders/ViewPurchaseOrder";
import TrackPurchaseOrder from "../pages/purchase-orders/TrackPurchaseOrder";
import PurchaseOrderAnalytics from "../pages/purchase-orders/PurchaseOrderAnalytics";
import PurchaseOrderAprrovals from "../pages/purchase-orders/PurchaseOrderApprovals";
import BudgetCreation from "../pages/budget/BudgetCreations";
import PendingApprovalBudget from "../pages/budget/PendingApprovalBudget";
import ViewOrderDetails from "../pages/purchase-orders/ViewOrderDetails";
import ViewInvoiceList from "../pages/invoice/ViewAllInvoices";
import ViewInvoiceDetails from "../pages/invoice/ViewInvoiceDetails";
import InvoiceApproval from "../pages/invoice/InvoiceApproval";
import TrackInvoice from "../pages/invoice/TrackInvoice";
import InvoiceAnalytics from "../pages/invoice/InvoiceAnalytics";
import QCApprovals from "../pages/quotations/QCApprovals";
import ReadyToAward from "../pages/quotations/ReadyToAward";
import ViewDetailsEdit from "../pages/quotations/ViewDetailsEdit";
import ViewAllDispatch from "../pages/dispatch/ViewAllDispatch";
import ShowDispatchDetailsWrapper from "../pages/dispatch/showDispatchDetailsWrapper";
import ViewAllDeliveredGRN from "../pages/dispatch/ViewAllDeliveredGRN";
import InvoicePayables from "../pages/payments/invoicePayables";
import PendingPaymentApprovals from "../pages/payments/pendingPaymentApprovals";
import RunPayments from "../pages/payments/runPayments";
import ViewPayments from "../pages/payments/viewPayments";
import Main from "../pages/credit-debit-note/Main";
import ViewAllCN from "../pages/credit-debit-note/ViewAllCN";
import ViewPendingApprovalCN from "../pages/credit-debit-note/ViewPendingApprovalCN";



const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}`} element={<Layout />}>
        {/* <Route element={<ProtectedRoute />}> */}
        <Route>
          <Route path="dashboard" element={<Invest />}></Route>
          <Route element={<UserContextProvider />} >
            <Route path="user-list-regular" element={<UserListRegular />}></Route>
            <Route path="user-list-compact" element={<UserListCompact />}></Route>
            <Route path="user-contact-card" element={<UserContactCard />}></Route>
            <Route path="user-details-regular/:userId" element={<UserDetails />}></Route>
          </Route>

          <Route >
            <Route path="user-profile-notification" element={<UserProfileNotification />} ></Route>
            <Route path="user-profile-regular" element={<UserProfileRegular />}></Route>
            <Route path="user-profile-activity" element={<UserProfileActivity />}></Route>
            <Route path="user-profile-setting" element={<UserProfileSetting />}></Route>
          </Route>
          {/* Dashboard Starts */}

          <Route >
            <Route path="organisation" element={<ViewOrgPage />} ></Route>
            <Route path="orgLegalInformation" element={<OrgLegalInformation />} ></Route>
            <Route path="orgContactInformation" element={<OrgContactInformation />} ></Route>
            <Route path="orgBankInformation" element={<OrgBankInformation />} ></Route>


            <Route path="sites" element={<SitesSetup />}></Route>
            <Route path="createSites" element={<AddSites />}></Route>
            <Route path="delivery-address" element={<DeliveryAddress/>}></Route>
            <Route path="sitesViewDetails/:siteId" element={<SitesViewDetails />}></Route>
            <Route path="departments" element={<Departments />}></Route>
            <Route path="roles" element={<Roles />}></Route>
            <Route path="ViewRoleDetails/:roleID" element={<RoleViewDetails />}></Route>
            <Route path="reporting-hierarchy" element={<ReportingHeirarchy />}></Route>
            <Route path="supplier-category" element={<SupplierCategory />}></Route>
            <Route path="suppliers" element={<Suppliers />}></Route>
            <Route path="addSuppliers" element={<AddSupplier />}></Route>
            <Route path="vewSuppliersDetails/:siteId" element={<ViewSuppliersDetails />}></Route>
            <Route path="general-contractors" element={<Contractors />}></Route>
            <Route path="viewContractorDetails/:siteId" element={<ViewContractorDetails />}></Route>
            <Route path="addContractors" element={<AddContractors />}></Route>
            <Route path="addConsultants" element={<AddConsultants />}></Route>
            <Route path="viewConsultantDetails/:consultantId" element={<ViewConsultantDetails />}></Route>
            <Route path="addMaterials" element={<AddMaterial />}></Route>
            <Route path="vewMaterialDetails/:materialId" element={<ViewMaterialDetails />}></Route>
            <Route path="consultants" element={<Consultants />}></Route>
            <Route path="users" element={<Users />}></Route>
            <Route path="createUsers" element={<CreateUsers />}></Route>
            <Route path="userProfile/:userId" element={<UserViewDetails />}></Route>
            <Route path="createRole" element={<CreateRole />}></Route>
            <Route path="approval-heirarchy" element={<ApprovalHeirarchy />}></Route>
            <Route path="materail-category" element={<MaterialCategory />}></Route>
            <Route path="materails" element={<Materials />}></Route>
            <Route path="cost-center" element={<CostCenter />}></Route>
            <Route path="general-ledger" element={<GeneralLedger />}></Route>
          </Route>
          {/* Dashboard Starts */}


          {/* Purchase Requests */}
          <Route >
            <Route path="add-new-purchase-request" element={<AddNewPurchaseRequest />} ></Route>
            <Route path="approvals-purchase-request" element={<PurchaseRequestAprrovals />}></Route>
            <Route path="view-purchase-requests" element={<ViewPurchaseRequests />}></Route>
            <Route path="track-purchase-requests" element={<TrackPurchaseRequests />}></Route>
            <Route path="analytics-purchase-requests" element={<PurchaseRequestAnalytics />}></Route>
          </Route>
          {/* Purchase Requests */}

          {/* Budget Related Screens Starts */}
          <Route >
            <Route path="new-budget" element={<BudgetCreation />} ></Route>
            <Route path="budget-approvals" element={<BudgetApprovals />}></Route>
            <Route path="view-budget" element={<ViewBudgetList />}></Route>
            <Route path="pendingApproval-budget" element={<PendingApprovalBudget />}></Route>
            <Route path="track-budget" element={<TrackBudget />}></Route>
            <Route path="analytics-budget" element={<BudgetAnalytics />}></Route>
          </Route>
          {/* Budget Related Screens Ends */}

          {/* RFQ Screens Starts */}
          <Route >
            <Route path="new-rfq" element={<RFQCreation />} ></Route>
            <Route path="rfq-approvals" element={<RFQApprovals />}></Route>
            <Route path="qc-approvals" element={<QCApprovals />}></Route>
            <Route path="view-rfqs" element={<RFQView />}></Route>
            <Route path="track-rfqs" element={<RFQTracking />}></Route>
            <Route path="analytics-rfq" element={<RFQAnalytics />}></Route>
            <Route path="rfq-comparison" element={<RFQComparison />}></Route>
            <Route path="rfq-conversion" element={<RFQConversion />}></Route>
            <Route path="rfq-supplier-responses" element={<RFQSupplierResponses />}></Route>
            <Route path="rfq-full-view" element={<RFQFullView />}></Route>
            <Route path="ready-to-award" element={<ReadyToAward />}></Route>
            <Route path="view/:qid" element={<ViewDetailsEdit />}></Route>
          </Route>
          {/* RFQ Screens Ends */}

          {/* FA Screens Starts */}
          <Route >
            <Route path="forward-auction-new" element={<FACreations />} ></Route>
            <Route path="forward-auction-approvals" element={<FAApprovals />}></Route>
            <Route path="forward-auction-view" element={<FAView />}></Route>
            <Route path="forward-auction-comparision" element={<FAComparison />}></Route>
            <Route path="forward-history" element={<FAHistory />}></Route>
            <Route path="forward-auction-supplier-responses" element={<FASupplierResponses />}></Route>
            <Route path="forward-auction-anlaytics" element={<FAAnalytics />}></Route>

          </Route>
          {/* FA Screens Ends */}

          {/* RA Screens Starts */}
          <Route >
            <Route path="reverse-auction-new" element={<RACreations />} ></Route>
            <Route path="reverse-auction-approvals" element={<RAApprovals />}></Route>
            <Route path="reverse-auction-view" element={<RAView />}></Route>
            <Route path="reverse-auction-history" element={<RAHistory />}></Route>
            <Route path="reverse-auction-analytics" element={<RAAnalytics />}></Route>
            <Route path="reverse-auction-comparison" element={<RAComparison />}></Route>
            <Route path="reverse-auction-conversion" element={<RAConversion />}></Route>
            <Route path="reverse-auction-supplier-responses" element={<RASupplierResponses />}></Route>
          </Route>
          
          <Route >
            <Route path="purchase-order-new" element={<PurchaseOrderMain />} ></Route>
            <Route path="approvals-purchase-order" element={<PurchaseOrderAprrovals/>}></Route>
            <Route path="view-purchase-order" element={<ViewPurchaseOrdersList />}></Route>
            <Route path="view-purchase-order/:orderNumber" element={<ViewOrderDetails />}></Route>
            <Route path="track-purchase-order" element={<TrackPurchaseOrder />}></Route>
            <Route path="analytics-purchase-order" element={<PurchaseOrderAnalytics />}></Route>
            <Route path="reverse-auction-approvals" element={<RAApprovals />}></Route>
            <Route path="reverse-auction-view" element={<RAView />}></Route>
            <Route path="reverse-auction-history" element={<RAHistory />}></Route>
            <Route path="reverse-auction-analytics" element={<RAAnalytics />}></Route>
            <Route path="reverse-auction-comparison" element={<RAComparison />}></Route>
            <Route path="reverse-auction-conversion" element={<RAConversion />}></Route>
            <Route path="reverse-auction-supplier-responses" element={<RASupplierResponses />}></Route>
          </Route>

          <Route>
            <Route path="credit-debit-note-new" element={<Main/>} ></Route>
            <Route path="all-credit-debit-notes" element={<ViewAllCN/>} ></Route>
            <Route path="pending-approval-credit-debit-notes" element={<ViewPendingApprovalCN/>} ></Route>

          </Route>

          {/* Payments Related Screens */}
          <Route>
            <Route path="invoice-payables" element={<InvoicePayables/>} ></Route>
            <Route path="pending-payment-approvals" element={<PendingPaymentApprovals/>} ></Route>
            <Route path="run-payments" element={<RunPayments/>} ></Route>
            <Route path="view-payments" element={<ViewPayments/>} ></Route>
          </Route>


          <Route>

            <Route path="view-invoice-list" element={<ViewInvoiceList />}></Route>
            <Route path="view-invoice/:id" element={<ViewInvoiceDetails />}></Route>
            <Route path="approvals-purchase-invoice" element={<InvoiceApproval />}></Route>
            <Route path="track-purchase-invoice" element={<TrackInvoice />}></Route>
            <Route path="analytics-purchase-invoice" element={<InvoiceAnalytics />}></Route>

            <Route path="view-dispatch-list" element={<ViewAllDispatch/>}></Route>
            <Route path="delivered-dispatch-list" element={<ViewAllDeliveredGRN/>}></Route>
            <Route path="view-dispatch-details/:id" element={<ShowDispatchDetailsWrapper/>}></Route>

          </Route>






          <Route path="kyc-list-regular" element={<KycListRegular />}></Route>
          <Route path="kyc-details-regular/:kycId" element={<KycDetailsRegular />}></Route>
          <Route path="transaction-basic" element={<TransListBasic />}></Route>
          <Route path="transaction-crypto" element={<TransListCrypto />}></Route>
          <Route element={<ProductContextProvider />}>
            <Route path="product-list" element={<ProductList />}></Route>
            <Route path="product-card" element={<ProductCard />}></Route>
            <Route path="product-details/:productId" element={<ProductDetails />}></Route>
          </Route>

          <Route path="pages">
            <Route path="terms-policy" element={<Terms />}></Route>
            <Route path="faq" element={<Faq />}></Route>
            <Route path="regular-v1" element={<Regularv1 />}></Route>
            <Route path="regular-v2" element={<Regularv2 />}></Route>
          </Route>
        </Route>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="auth-success" element={<Success />}></Route>
        <Route path="auth-reset" element={<ResetPassword />}></Route>
        <Route path="auth-register" element={<Register />}></Route>
        <Route index element={<Login />}></Route>
        <Route path="setPassword" element={<SetPassword />}></Route>
        <Route path="success" element={<SuccessMain />}></Route>
        <Route path="errorEmail" element={<EmailError />}></Route>



        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>

        <Route path="invoice-print/:invoiceId" element={<InvoicePrint />}></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
