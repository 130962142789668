import React, { useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  TooltipComponent,
} from "../components/Component";
import { BalanceBarChart, DepositBarChart, WithdrawBarChart } from "../components/partials/charts/invest/InvestChart";
import PRSummaryWidget from "./dashboard/PRSummaryWidget";
import TopPurchaseCategoriesWidget from "./dashboard/TopPurchseCategoriesWidget";
import TopSupplierSavingsWidget from "./dashboard/TopSupplierSavingsWidget";
import BudgetOverRunWidget from "./dashboard/BudgetOverRunWidget";
import P2PWidget from "./dashboard/P2PWidget";
import { formatCurrency2,  formatCurrency} from '../utils/Utils';
import { Tooltip } from "antd"; // Import Tooltip from Ant Design
import { InfoCircleOutlined } from '@ant-design/icons'; // Import Info Icon from Ant Design
const InvestHomePage = () => {
  const [sm, updateSm] = useState(false);
  return (
    <React.Fragment>
      <Head title="Procurex Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Dashboard</BlockTitle>
              <BlockDes className="text-soft">
                <p>Welcome to Procurex.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Button color="primary" outline className="btn-dim btn-white">
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                    <li>
                      <Button color="primary" outline className="btn-dim btn-white">
                        <Icon name="reports"></Icon>
                        <span>Reports</span>
                      </Button>
                    </li>
                    <li className="nk-block-tools-opt">
                      <UncontrolledDropdown>
                        <DropdownToggle color="transparent" className="dropdown-toggle btn btn-icon btn-primary">
                          <Icon name="plus"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                href="#adduser"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="user-add-fill"></Icon>
                                <span>Add User</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                href="#addorder"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="coin-alt-fill"></Icon>
                                <span>Add Order</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                href="#addpage"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="note-add-fill-c"></Icon>
                                <span>Add Page</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Row className="g-gs">
            <Col md="4">
              <PreviewAltCard className="card-full">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Total Purchase Orders</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-deposit"
                      text="Total Purchase Orders"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="card-amount">
                  <span className="amount">
                    {formatCurrency2(20157643999999999, 'SGD')}
                    <Tooltip title={formatCurrency(20157643, 'INR')}>
                      <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                    </Tooltip>
                  </span>
                </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">This Month</div> 
                      <span className="amount">
                        {formatCurrency2(294000.59, 'EUR')}
                        <Tooltip title={formatCurrency(2940.59, 'EUR')}>
                          <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                        </Tooltip>
                      </span>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">This Week</div>
                      <span className="amount">
                        {formatCurrency2(125900.28, 'EUR')}
                        <Tooltip title={formatCurrency(1259.28, 'USD')}>
                          <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                  <div className="invest-data-ck">
                    <DepositBarChart />
                  </div>
                </div>
              </PreviewAltCard>
            </Col>

            <Col md="4">
              <PreviewAltCard className="card-full">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Total Orders Received</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-withdraw"
                      text="Total Orders Received"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="card-amount">
                  <span className="amount">
                    {formatCurrency2(49595453.34, 'INR')}
                    <Tooltip title={formatCurrency(49595345.34, 'INR')}>
                      <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                    </Tooltip>
                  </span>
                </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">This Month</div>
                      <div className="amount">
                        {formatCurrency2(2940.59, 'INR')}
                        <Tooltip title={formatCurrency(294032.59, 'INR')}>
                          <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">This Week</div>
                      <div className="amount">
                        {formatCurrency2(2940.59, 'INR')}
                        <Tooltip title={formatCurrency(294021.59, 'INR')}>
                          <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className="invest-data-ck">
                    <WithdrawBarChart />
                  </div>
                </div>
              </PreviewAltCard>
            </Col>

            <Col md="4">
              <PreviewAltCard className="card-full">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">Payments</h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-balance"
                      text="Total amount paid"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="card-amount">
                  <span className="amount">
                    
                    {formatCurrency2(19358990.50 , 'INR')}
                    <Tooltip title={formatCurrency(.5, 'INR')}>
                      <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                    </Tooltip>
                  </span>
                </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">This Month</div>
                      <div className="amount">
                        2,940.59 <span className="currency currency-usd">INR</span>
                        <Tooltip title={formatCurrency(294067.59, 'INR')}>
                          <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">This Week</div>
                      <div className="amount">
                        1,259.28 <span className="currency currency-usd">INR</span>
                        <Tooltip title={formatCurrency(125932.28, 'INR')}>
                          <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className="invest-data-ck">
                    <BalanceBarChart />
                  </div>
                </div>
              </PreviewAltCard>
            </Col>
            
            {/* Rest of the widgets */}
            <Col md="6">
              <PreviewAltCard className="card-full">
                <PRSummaryWidget></PRSummaryWidget>
              </PreviewAltCard>
            </Col>

            <Col md="6">
              <PreviewAltCard className="card-full">
                <TopPurchaseCategoriesWidget></TopPurchaseCategoriesWidget>
              </PreviewAltCard>
            </Col>

            <Col md="6">
              <PreviewAltCard className="card-full">
                <TopSupplierSavingsWidget></TopSupplierSavingsWidget>
              </PreviewAltCard>
            </Col>
            <Col md="6">
              <PreviewAltCard className="card-full">
                <BudgetOverRunWidget></BudgetOverRunWidget>
              </PreviewAltCard>
            </Col>
            <Col xl="12">
              <PreviewAltCard className="card-full" >
                <P2PWidget></P2PWidget>
              </PreviewAltCard>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default InvestHomePage;
