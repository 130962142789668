import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../layout/head/Head";
import { Block } from "../../components/Component";
import QuotationsAside from "./QuotationsAside";
import { Steps, Step } from "react-step-builder";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import RFQHeader from "./newQuotations/RFQHeader";
import RFQGeneralDetails from "./newQuotations/RFQGeneralDetails";
import RFQMaterials from "./newQuotations/RFQMaterials";
import RFQOthers from "./newQuotations/RFQOthers";
import { FormDataProvider } from './newQuotations/FormDataContext';
import RFQPreview from "./newQuotations/RFQPreview";
const config = {
  before: RFQHeader as React.ComponentType<{}> as () => React.ReactElement,
};

const RFQCreation: FC = () => {
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const navigate = useNavigate();
  const formMethods = useForm({ mode: "onChange" });

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document.getElementsByClassName("nk-header")[0]?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document.getElementsByClassName("nk-header")[0]?.removeEventListener("click", headerClick);
    };
  }, []);

  return (
    <FormDataProvider> {/* Wrap everything inside the provider */}
      <Head title="RFQ - Create New" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""}`}
            >
              <QuotationsAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => setSm(!sm)}></div>}
              <Block>
                <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                  <FormProvider {...formMethods}>
                    <Steps config={config}>
                      <Step component={RFQGeneralDetails} />
                      <Step component={RFQMaterials} />
                      <Step component={RFQOthers} />
                      <Step component={RFQPreview} />
                    </Steps>
                  </FormProvider>
                </div>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </FormDataProvider>
  );
};

export default RFQCreation;
