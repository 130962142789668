import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Select, { SingleValue } from "react-select";
import { Row, Col, Button, Spinner } from "reactstrap";
import { Drawer } from 'antd';
import rolesApi from "../../../api/master/roles";
interface Employee {
  name: string;
  id: string;
}

interface Role {
  roleName: string;
  employees: Employee[];
  roleID: string;
  level: string;
}

export interface ApprovalData {
  roleId: string;
  level: string;
  userId: string;
  userName: string;
  roleName: string;
}

interface CustomDrawerProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ApprovalData[]) => void;
  setApprovalSubmissionStatus: (status: boolean) => void;
  approvalData: ApprovalData[];
  stateIds:any
}

// const dummyRoles: Role[] = [
//   {
//     roleName: 'Business Owner',
//     employees: [
//       { name: 'Alice', id: '1' },
//       { name: 'Bob', id: '2' },
//     ],
//     level: 1,
//     roleID: '2'
//   },
//   {
//     roleName: 'Procurement Head',
//     employees: [
//       { name: 'Charlie', id: '3' },
//       { name: 'David', id: '4' },
//     ],
//     level: 2,
//     roleID: '3'
//   },
//   {
//     roleName: 'Finance Manager',
//     employees: [
//       { name: 'Eve', id: '5' },
//       { name: 'Frank', id: '6' },
//     ],
//     level: 3,
//     roleID: '4'
//   },
//   {
//     roleName: 'Operations Lead',
//     employees: [
//       { name: 'Grace', id: '7' },
//       { name: 'Heidi', id: '8' },
//     ],
//     level: 4,
//     roleID: '5'
//   },
//   {
//     roleName: 'CEO',
//     employees: [
//       { name: 'Ivan', id: '9' },
//       { name: 'Judy', id: '10' },
//     ],
//     level: 6,
//     roleID: '7'
//   },
// ];

interface FormValues {
  [key: string]: string;
}

const QuotationApprovalDrawer: React.FC<CustomDrawerProps> = ({ open, onClose, onSubmit, setApprovalSubmissionStatus, approvalData,stateIds }) => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormValues>();
  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const moduleID = 5;
  const getHeirachyDetails = async (moduleID: number) => {
    try {
      const response = await rolesApi.fetchHierarchyUsersByModuleId(moduleID,stateIds)
      console.log(response);
      const { data } = response;
      
      if (!data) { setLoading(false); return; }

      setRoles(data)
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
console.log("moduleID",moduleID)
    getHeirachyDetails(moduleID);
  }, []);

  useEffect(() => {
    roles.forEach((role, index) => {
      register(role.level, { required: "This field is required" });
    });
  }, [roles, register]);

  useEffect(() => {
    if (approvalData && approvalData.length > 0) {
      approvalData.forEach((data, index) => {
        setValue(data.level, data.userId);
      });
    }
  }, [approvalData, setValue]);

  const submitApprovals: SubmitHandler<FormValues> = (data) => {
    const approvalData: ApprovalData[] = roles.map((role, index) => {
      const userId = data[role.level];
      const employee = role.employees.find(emp => emp.id === userId);
      return {
        roleId: role.roleID,
        level: role.level,
        userId,
        userName: employee ? employee.name : '',
        roleName: role.roleName
      };
    });

    console.log(approvalData); // Logging the transformed data
    onSubmit(approvalData);
    setApprovalSubmissionStatus(true);
  };

  const handleSelectChange = (name: string, value: SingleValue<{ value: string; label: string }>) => {
    if (value && value.value) {
      setValue(name, value.value, { shouldValidate: true });
    }
  };

  const getDefaultValue = (role: Role) => {
    const approval = approvalData?.find(data => data.level === role.level);
    if (approval) {
      const employee = role.employees?.find(emp => emp.id === approval.userId);
      if (employee) {
        return { value: employee.id, label: employee.name };
      }
    }
    return null;
  };

  return (
    <Drawer
      title="Set Quotation Approvals"
      placement="right"
      onClose={onClose}
      open={open}
    >
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Spinner color="primary" />
        </div>
      ) : (
        <div>
          {
            roles?.length !== 0 ?
              <>
                <p>There are {roles.length} levels of approvals set for this process</p>

                <form onSubmit={handleSubmit(submitApprovals)}>
                  {roles.map((role, index) => (
                    <Row className="mt-2" key={index}>
                      <Col lg="12">
                        <label>{` (Role: ${role.roleName})`}</label>
                        <label> <span style={{ textTransform: 'capitalize' }}>{role.level}</span> {` (Role: ${role.roleName})`}</label>
                        <Select
                          classNamePrefix="react-select"
                          options={role.employees?.map((employee) => ({
                            value: employee.id,
                            label: employee.name,
                          }))}
                          onChange={(selectedOption) =>
                            handleSelectChange(`${role.level}`, selectedOption)
                          }
                          placeholder="Select"
                          defaultValue={getDefaultValue(role)}
                        />
                        {errors[`${role.level}`] && (
                          <p className="mandatory">{errors[`${role.level}`]?.message}</p>
                        )}
                      </Col>
                    </Row>
                  ))}
                  <Button type="submit" color="primary" className="mt-3">
                    Save
                  </Button>
                </form>
              </>

              : <>
              <p className="mandatory">There is no approval hierarchy set for this module. Please contact the organization administrator.</p>
              </>
          }

        </div>
      )}
    </Drawer>
  );
};

export default React.memo(QuotationApprovalDrawer);
