import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import FormTextArea from "../../form-components/TextAreaComponent";
import { Icon, Button } from "../../../components/Component";
import moment from "moment";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import { AddressDetails, SelectOptions } from './types'; // Import your types
import adminOrgApis from "../../../api/master/adminOrg";
import dayjs from "dayjs";
import supplierApis from "../../../api/master/supplier";
import materialsApis from "../../../api/master/materials";

interface FormData {
    category: string;
    material: string;
    qty: string;
    uom: string;
    needByDate: any;
    description: string;
    deliveryAddress?: string;
    file?: FileList;
}

function parseValue(value: any) {
    const [id, name] = value.split('/');
    return {
        id,
        name
    };
}

const AddNewMaterial: FC<any> = ({
    modal,
    closeModal,
    addRow,
    currentMaterial,
    deliveryAddressLevel
}) => {
    const methods = useForm<FormData>({
        defaultValues: {
            category: '',
            material: '',
            qty: '',
            uom: '',
            needByDate: null,
            description: '',
            deliveryAddress: '',
            file: undefined,
        },
        mode: "onChange",
    });

    const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = methods;
    const [fileName, setFileName] = useState<string | undefined>(undefined);

    const fileWatch = watch('file');
    const [addressList, setAddressList] = useState<AddressDetails[]>([]);
    const [addressOptions, setAddressOptions] = useState<SelectOptions[]>([]);

    const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
    const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
    const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);

    useEffect(() => {
        if (currentMaterial) {
            console.log("currentMaterial:::", currentMaterial);
            reset({
                category: `${currentMaterial.categoryID}`,
                material: `${currentMaterial.materialID}`,
                qty: currentMaterial.qty,
                uom: `${currentMaterial.uomID}/${currentMaterial.uomName}`,
                needByDate: currentMaterial.needByDate ? dayjs(currentMaterial.needByDate) : null,
                description: currentMaterial.description,
                deliveryAddress: currentMaterial.deliveryAddressID ? `${currentMaterial.deliveryAddressID}/${currentMaterial.deliveryAddressName}` : '',
                file: currentMaterial.fileName,
            });
            setFileName(currentMaterial.fileName); // Set the file name in the state
        } else {
            reset({
                category: '',
                material: '',
                qty: '',
                uom: '',
                needByDate: null,
                description: '',
                deliveryAddress: '',
                file: undefined,
            });
            setFileName(undefined); // Clear the file name in the state
        }
    }, [currentMaterial, reset]);

    const getMaterialName = (id: any) => {
        console.log("idd::", id, materialCategoryOptions)
        const materialName: any = materialCategoryOptions.find((item: any) => item.materialID == id)
        console.log("materialName", materialName?.productName)
        const label = materialName?.productName;
        return label;
    }

    const getCategoryName = (id: any) => {
        console.log("idd::", id, categoryOptions)
        const categoryName: any = categoryOptions.find((item: any) => item.value == id);
        console.log("categoryOptions", categoryName)
        console.log("getCategoryName", categoryName?.label)
        const label = categoryName?.label;
        return label;
    }

    const submitAction = (data: FormData) => {
        console.log("data:::", "data:::", data);
        const material = getMaterialName(data.material);
        const category = getCategoryName(data.category);
        const uom = parseValue(data.uom);
        const deliveryAddress = data.deliveryAddress ? parseValue(data.deliveryAddress) : { id: '', name: '' };
        const file = data.file ? data.file[0] : undefined;

        const selectedAddress = addressList.find(addr => addr.id.toString() === deliveryAddress.id) || {};

        addRow({
            categoryID: data.category,
            categoryName: category,
            materialID: data.material,
            materialName: material,
            uomID: uom.id,
            uomName: uom.name,
            qty: data.qty,
            needByDate: data.needByDate ? dayjs(data.needByDate).format('DD-MMM-YYYY') : '',
            description: data.description,
            deliveryAddressID: deliveryAddress.id,
            deliveryAddressName: deliveryAddress.name,
            deliveryAddressDetails: selectedAddress,
            file,
            fileName: file ? file.name : undefined,
        });

        // Reset the form after submission
        reset({
            category: '',
            material: '',
            qty: '',
            uom: '',
            needByDate: null,
            description: '',
            deliveryAddress: '',
            file: undefined,
        });
        setFileName(undefined); // Clear the file name in the state
    };

    const handleFileChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement & {
            files: FileList
        };
        if (target && target.files.length > 0) {
            setFileName(target.files[0].name);
            setValue('file', target.files);
        }
    };

    // Watch the file input to trigger re-render


    const fetchAddresses = async () => {
        try {
            const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
            if (status) {
                const addressOptions = data.map((item: any) => ({
                    label: item.deliveryLocationName,
                    value: `${item.id}/${item.deliveryLocationName}`,
                }));
                setAddressList(data);
                setAddressOptions(addressOptions);
            }
        } catch (error) {
            console.log(error);
        }
    };



    const fetchCategorys = async () => {
        try {
            const { data, status } = await supplierApis.fetchSupplierCategory();
            if (status) {
                const categoryOption = data.map((item: any) => ({
                    label: item.category,
                    value: item.id.toString(),
                }));
                setCategoryOptions(categoryOption);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const fetchMaterials = async () => {
        try {
            const { data, status } = await materialsApis.fetchProduct();
            if (status) {
                setMaterialCategoryOptions(data);
                const materialOption = data.map((item: any) => ({
                    label: item.productName,
                    value: item.materialID.toString(),
                }));
                setMaterialOptions(materialOption);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const fetchUOMOPtions = async () => {
        try {
            const { data, status, message } = await adminOrgApis.fetchUom();
            if (status) {
                setUomOptions(
                    data.map((item: any) => {
                        return {
                            label: item.uom_name,
                            value: `${item.id.toString()}/${item.uom_name}`,
                        };
                    })
                );
            } else {
                console.log(message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const materialCategory = watch("category");

    // useEffect(() => {
    //     console.log("materialCategory", materialCategory);
    //     fetchMaterialDetails(materialCategory);
    // }, [materialCategory]);

    useEffect(() => {
        fetchAddresses();
        // fetchCategoryOptions();
        fetchCategorys();
        fetchMaterials();
        fetchUOMOPtions();
    }, []);

    return (
        <Modal
            isOpen={modal}
            toggle={closeModal}
            className="modal-dialog-centered"
            size="lg"
            backdrop="static"
        >
            <ModalBody>
                <a
                    href="#cancel"
                    onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                    }}
                    className="close"
                >
                    <Icon name="cross-sm"></Icon>
                </a>
                <div className="p-2">
                    <h5 className="title">Add New Material to the quotation</h5>

                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(submitAction)}>
                            <Row className="mt-2">
                                <Col lg='6'>
                                    <FormSelect
                                        name="category"
                                        label="Category"
                                        options={categoryOptions}
                                        required={true}
                                        placeholder="Select"
                                    />
                                </Col>
                                <Col lg='6'>
                                    <FormSelect
                                        name="material"
                                        label="Material Name"
                                        options={materialCategoryOptions?.filter(
                                            (item: any) => item.category == materialCategory
                                        )?.map((item: any) => ({
                                            label: item.productName,
                                            value: item.materialID,
                                        }))}
                                        required={true}
                                        placeholder="Select"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg='6'>
                                    <Row>
                                        <Col>
                                            <FormInput
                                                name="qty"
                                                label="Quantity"
                                                required={true}
                                                type="number"
                                                placeholder="Enter"
                                            />
                                        </Col>
                                        <Col>
                                            <FormSelect
                                                name="uom"
                                                label="UOM"
                                                options={uomOptions}
                                                required={true}
                                                placeholder="Select"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="6">
                                    <FormDatePicker
                                        name='needByDate'
                                        label="Need By Date"
                                        required={true}
                                        placeholder="Select Date"
                                        minDate={new Date()}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                {deliveryAddressLevel === 'LINE_LEVEL' ?
                                    <Col lg="6">
                                        <FormSelect
                                            name="deliveryAddress"
                                            label="Delivery Address"
                                            options={addressOptions}
                                            required={true}
                                            placeholder="Select"
                                        />
                                    </Col>
                                    : null}
                                <Col lg="6">
                                    <label htmlFor="file" className="form-label">Upload File</label>
                                    <input
                                        type="file"
                                        id="file"
                                        className={`form-control ${errors.file ? 'is-invalid' : ''}`}
                                        accept=".doc, .docx, .png, .pdf, .jpeg, .jpg, .gif"
                                        onChange={handleFileChange}
                                    />
                                    {fileName && (
                                        <div>
                                            <span>{fileName}</span>
                                        </div>
                                    )}
                                    {errors.file && <p className="error">{errors.file.message}</p>}
                                </Col>
                            </Row>
                            {/* <Row className="mt-4">
                                <Col lg='12'>
                                    <FormTextArea
                                        name='description'
                                        label="Description"
                                        required={true}
                                    />
                                </Col>
                            </Row> */}
                            <Row className="mt-4">
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button
                                                className="btn btn-primary btn-md"
                                                type="submit"
                                                color="primary"
                                            >
                                                <span>Submit</span>
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    closeModal();
                                                }}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </form>
                    </FormProvider>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default AddNewMaterial;
