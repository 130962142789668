import React, { useState, useEffect, FC, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Card,
  Badge,
  Row,
  Collapse,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  TooltipComponent,
} from "../../components/Component";
import BudgetAside from "./BudgetAside";
import FormatDate from "../masters/DateFormater";
import supplierApis from "../../api/master/supplier";
import costCenterApis from "../../api/master/costCenter";
import sites from "../../api/master/sites";
import GeneralLedgerApis from "../../api/master/generalLedger";
import departments from "../../api/master/departments";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  PaperClipOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Form,
  TableColumnsType,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Select,
  Divider,
  Space,
  Timeline,
  Collapse as AntCollapse,
  Button as AntButton,
  Descriptions,
  List,
  Modal as AntModal,
} from "antd";
import { nanoid } from "nanoid";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import FormDatePicker from "../form-components/DatePicker";
import FormTextArea from "../form-components/TextAreaComponent";
import misc from "../../api/misc/dropDowns";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import adminOrgApis from "../../api/master/adminOrg";
// import EditPoModal from "./EditPoModal";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import { InfoCircleOutlined } from "@ant-design/icons";
import type { StepsProps } from "antd";
import { Popover, Steps } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
// import MaterialModal from "./EditPOMaterialModal";
import CropperComponent from "../components/CropperComponent";
// import SignatureModal from "./SignatureModal";
import rolesApi from "../../api/master/roles";
import { error } from "console";
import dayjs from "dayjs";
import budgetApis from "../../api/master/budget";
import MaterialModal from "./EditBudgetMaterialModal";
import Dragger from "antd/es/upload/Dragger";
import Misc from "../masters/Misc";
// import QuotationApprovalDrawer from "./QuotationApprovalDrawer";
// import ChangeToBuyerModal from "./ChangeToBuyerModal";
// import ChangeToBuyerDrawer from "./ChangeToBuyerModal";
import { UploadFile } from "antd/es/upload/interface";
const { Panel } = AntCollapse;
const { FormatCurrency, beforeUpload } = Misc;

interface LineItem {
  lineId: number;
  budgetLineName: string;
  lineDescription: string;
  budgetValue: string;
  startDate: string;
  endDate: string;
  bom: string;
  allocation: string;
}

interface EventChain {
  id: number;
  orderNumber: number;
  orgId: number;
  eventType: string;
  eventName: string;
  error: string | null;
  eventDate: string;
  createdBy: number;
}

interface Item {
  orderNumber: number;
  referenceId: string;
  mode: string;
  version: number;
  orderDate: string;
  needByDateType: string;
  needByDate: string | null;
  orgId: number;
  siteId: number;
  supplierId: number;
  costCenterId: number;
  description: string;
  ledgerId: number;
  departmentId: number;
  budgetId: number;
  paymentTerms: string;
  advancePercentage: number;
  creditDays: number;
  paymentTermsDescription: string | null;
  inwardRequired: number;
  autoRelease: number;
  autoPoDoc: number;
  billingAddressId: number;
  deliveryAddressType: string;
  deliveryAddressId: string | null;
  currencyId: number;
  basicAmount: string;
  discount: string;
  netAmount: string;
  sgst: string;
  cgst: string;
  igst: string;
  additionalCharges: string;
  grossTotal: string;
  createdDate: string;
  createdBy: number;
  modifiedDate: string | null;
  modifiedBy: number | null;
  archieved: number;
  rejectedReason: string;
  approvalStatus: string;
  status: string;
  deliveryStatus: string;
  lineItems: LineItem[];
  eventChains: EventChain[];
  supplierName: string;
  siteName: string;
  costCenterName: string;
  createdUserName: string;
  createdUserId: number;
  currencyCode: string;
  currency: string;
  currencySymbol: string;
}

// interface ApiResponse {
//   status: boolean;
//   message: string;
//   response: Item;
// }

interface SelectOptions {
  label: string;
  value: string;
}

const ViewBudgetDetails: FC<any> = ({ BudgetData, refresh }) => {
  const [sm, setSm] = useState(false);
  const [tab, setTab] = useState("1");
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState("1");
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [materialData, setMaterialData] = useState<any>({});
  const [attachments, setAttachments] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);

  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
    []
  );
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);

  const [departmentOptions, setDepartmentOptions] = useState<SelectOptions[]>(
    []
  );

  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
  const [DMS, setDMS] = useState<any>([]);
  const [editLineRow, setEditLineRow] = useState<any>();
  const [changeToBuyerData, setChangeToBuyerData] = useState<any>();
  const [showSubmitBtm, setShowSubmitBtm] = useState(false);
  const [generalInformationEdit, setGeneralInformationEdit] = useState(false);
  const navigate = useNavigate();
  const [deletedAttachments, setDeletedAttachments] = useState<any>([]);
  const [newAttachments, setNewAttachments] = useState<any>([]);

  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  const fetchBudgetDetails = async () => {
    console.log("BudgetData", BudgetData);
    try {
      const res = await budgetApis.fetchBudgetById(BudgetData.headerId);
      const { response, status, message } = res;
      if (status) {
        setDMS(response.docs || []);
        setMaterialData(response);
        console.log("response.lines", response.lines);
        setData(response.lines || []);
        setShowSubmitBtm(false);
        setDeletedAttachments([]);
        setNewAttachments([]);
        setAttachments(
          response.docs.map((attachment: any) => ({
            docId: attachment.docId,
            name: attachment.fileName,
            link: attachment.filePath,
            moduleFileType: attachment.moduleFileType,
            module: attachment.module,
            label: "attachments",
            isActive: true,
          })) || []
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBudgetDetails();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const fetchCostCenter = async () => {
    try {
      const { data, status, message } = await costCenterApis.fetchCostCenter();
      if (status) {
        let costCenterOptions = data.map((item: any) => {
          return { label: item.costCenterName, value: item.id.toString() };
        });
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSites = async () => {
    try {
      const res = await sites.fetchSites();
      const { data, status, message } = res;
      if (status) {
        let sitesOptions = data.map((item: any) => {
          return { label: item.name, value: item.siteID.toString() };
        });
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDepartment = async () => {
    try {
      const { data, status, message } = await departments.fetchDeptData();
      if (status) {
        let departmentOptions = data.map((item: any) => {
          return { label: item.deptName, value: item.deptID.toString() };
        });
        setDepartmentOptions(departmentOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchModuleConfig();
    // fetchBillingAddress();
    // fetchDeliveryAddress();
    // fetchSupplier();
    fetchCostCenter();
    fetchSites();
    // fetchGeneralLedger();
    fetchDepartment();
    // fetchCurrencyData();
    // fetchUOMOPtions();
    fetchCategoryOptions();
  }, []);

  const [columns, setColumns] = useState<TableColumnsType<Item>>([
    // {
    //   ...Table.EXPAND_COLUMN,
    //   fixed: "left",
    // },
    {
      title: "Budget Line",
      dataIndex: "budgetLineName",
      key: "budgetLineName",
      fixed: "left" as const,
      width: 130,
    },
    {
      title: "Budget Line Description",
      dataIndex: "lineDescription",
      key: "lineDescription",
      // fixed: 'left' as const,
      width: 200,
    },
    {
      title: "Budget Value",
      dataIndex: "budgetValue",
      key: "budgetValue",
      width: 150,
      render: (text) => {
        const value = parseFloat(text);
        return isNaN(value) ? "0.00" : value.toFixed(2);
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 200,
      render: (text) => moment(text).format("DD-MMM-YYYY"),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => moment(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Bill Of Material (BOM)",
      dataIndex: "bom",
      key: "bom",
    },
    {
      title: "BOM Allocation",
      dataIndex: "allocation",
      key: "allocation",
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right" as const,
      width: 100,
      render: (text, record: any, index) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              console.log("record", record);
              setEditLineRow(record);
            }}
          >
            <Icon name="edit" />
          </Button>
        </>
      ),
    },
  ]);

  useEffect(() => {
    console.log("lines", editLineRow);
    if (editLineRow) {
      setAddNewRowModel(true);
    }
  }, [editLineRow]);

  const fetchCategoryOptions = async () => {
    try {
      const { data, status, message } = await adminOrgApis.fetchAdminMaterial();
      const { data: materialCategoryOptions } =
        await adminOrgApis.fetchAdminMaterialCategory();
      if (status) {
        setMaterialCategoryOptions(data);
        setCategoryOptions(
          materialCategoryOptions.map((item: any) => {
            return {
              label: item.category,
              value: item.id,
            };
          })
        );
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApprovalHierarchyUsersByModuleId = async (moduleID: number) => {
    try {
      // const res = await rolesApi.fetchHierarchyUsersByModuleId(moduleID);
      // if (!res) return;
      // const { data, status } = res;
      // if (status) {
      //   setRoles(data);
      // }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchModuleConfig = async () => {
    try {
      const res = await rolesApi.getModuleConfig();
      const moduleID = res?.data?.find(
        (x: any) => x.moduleName == "Purchase Order"
      )?.moduleID;
      console.log(moduleID);
      await fetchApprovalHierarchyUsersByModuleId(moduleID);
    } catch (err) {
      throw err;
    }
  };

  const ApprovalTimelineContent = () => {
    const approvalsData = materialData?.approvals;
    if (!approvalsData || approvalsData?.length === 0)
      return <Badge color="outline-danger">No</Badge>;
    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
           {approvalsData?.map((approval: any, index: number) => {
              const isApproved = approval.status == "Approved";
              return (
                <Timeline.Item
                  key={index}
                  dot={
                    isApproved ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : undefined
                  }
                >
                  {approval?.userName}
                  <div className="designation">{approval?.roleName}</div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          // onClick={() => {
          //   setApprovals({
          //     open: true,
          //     data: approvalsData,
          //   });
          // }}
        >
          Yes
        </span>
      </Popover>
    );
  };

  const append = (data: any) => {
    setMaterialData((prevData: any) => {
      let newData = { ...prevData };
      newData?.lineItems?.push(data);
      return newData;
    });
    setData((prevData: any) => [...prevData, data]);
  };

  // Update existing row handler
  const update = (id: any, formData: any) => {
    console.log("lines", id, formData, data);
    if (id === formData.lineId) {
      // Find the index of the line item that matches lineId
      const index = data.findIndex((item: any) => item.lineId === id);
      if (index !== -1) {
        // Update the line item with the new data
        data[index] = { ...data[index], ...formData };
      }
      console.log("data", data);
    }
  };

  const onSubmit = async (data: any, tab: any) => {
    console.log(data);
    const updatedResponse = {
      ...materialData, // Spread the existing response data
      ...data, // Spread the edited form data to overwrite the necessary fields
    };
    console.log("updatedResponse", updatedResponse);
    setMaterialData(updatedResponse);
    setGeneralInformationEdit(false);
    return;
    const _formData = new FormData();
    let submitData: any = {
      mode: "U",

      combinedLineItems: {
        existingLineItems: materialData?.budgetLines?.map((item: any) => {
          const obj: any = {
            lineId: item.lineId,
            // uuid: item.uuid,
            budgetId: item.budgetId,
          };

          return obj;
        }),
        updatedLineItems: [],
        newLineItems: [],
        deletedLineItems: [],
      },
    };

    const addIfDifferent = (
      key: string,
      dataValue: any,
      materialValue: any
    ) => {
      if (dataValue != materialValue) {
        submitData[key] = dataValue;
      }
    };

    const checkAndAdd = (key: string, dataValue: any, materialValue: any) => {
      if (data.hasOwnProperty(key)) {
        addIfDifferent(key, dataValue, materialValue);
      }
    };
    checkAndAdd(
      "budgetName",
      Number(data.budgetName),
      Number(materialData.budgetName)
    );
    checkAndAdd("startDate", data.startDate, materialData.startDate);
    checkAndAdd("endDate", Number(data.endDate), Number(materialData.endDate));
    checkAndAdd(
      "description",
      Number(data.description),
      Number(materialData.description)
    );
    checkAndAdd(
      "budgetFor",
      Number(data.budgetFor),
      Number(materialData.budgetFor)
    );
    checkAndAdd("site", Number(data.site), Number(materialData.site));
    checkAndAdd("lob", Number(data.lob), Number(materialData.lob));
    checkAndAdd(
      "costCenter",
      Number(data.costCenter),
      Number(materialData.costCenter)
    );
    checkAndAdd(
      "department",
      Number(data.department),
      Number(materialData.department)
    );

    _formData.append(
      "filesData",
      JSON.stringify({
        newfiledata: [],
        deletedFileData: [],
        updatedFileData: [],
      })
    );
    _formData.append("files", JSON.stringify([]));
    _formData.append("orderData", JSON.stringify(submitData));

    const res = await purchaseOrderApis.purchaseOrderUpdate(
      BudgetData.headerId,
      _formData
    );
    const { status, message } = res;
    if (status) {
      Swal.fire("Updated!", message, "success");
      setGeneralInformationEdit(false);
      setAddNewRowModel(false);
      setEditLineRow(null);
      fetchBudgetDetails();
    } else {
      Swal.fire("Error!", message, "error");
    }
  };

  const updateBudgetLines = (data: any) => {
    console.log("updateBudgetLines", data);
    setMaterialData((prevData: any) => {
      let newData = { ...prevData };
      newData?.budgetLines?.push(data);
      return newData;
    });
    setData((prevData: any) => [...prevData, data]);
    console.log("materialData", materialData, data);
  };

  const handleAttachmentChange = (info: { fileList: UploadFile[] }) => {
    console.log("setDMS", DMS, attachments, newAttachments);
    setNewAttachments((prevNewAttachments: any[]) => {
      const updatedAttachments = [...prevNewAttachments, ...info.fileList];
      console.log("Updated attachments:", updatedAttachments); // This will show the updated attachments
      return updatedAttachments;
    });
    console.log("attachments", newAttachments);

    setShowSubmitBtm(true);
  };

  const submitSave = () => {
    console.log("materialData3333333:final", deletedAttachments);
    if (deletedAttachments.length > 0) {
      materialData.docs = materialData.docs.filter(
        (doc: any) =>
          !deletedAttachments.some(
            (deleted: any) => deleted.docId === doc.docId
          )
      );
    }
    console.log(
      "materialData3333333:final",
      materialData,
      newAttachments,
      materialData.docs
    );

    // Map the new attachments to the desired format
    const mappedNewAttachments = newAttachments.map(
      (file: any, index: number) => ({
        fileIndex: index, // Continue the index after existing attachments
        module: "Budget",
        moduleFileType: "Special Instructions", // Adjust as necessary
        level: "HEADER_LEVEL", // Adjust as necessary
        // budgetId: BudgetData.budgetId, // Assuming you have access to BudgetData
      })
    );
    materialData.docs = [...materialData.docs, ...mappedNewAttachments];
    console.log("updatedDocs", materialData.docs);

    const { lines, docs, approvals, ...remainingData } = materialData;
    console.log("Lines:", lines);
    console.log("Docs:", docs);
    console.log("Approvals:", approvals);
    console.log("Remaining Data:", remainingData);
    const submissionData = {
      header: remainingData,
      lines: lines,
      approvals: approvals,
      docKeys: docs,
    };
    const formDataToSend = new FormData();
    formDataToSend.append("header", JSON.stringify(submissionData.header));
    formDataToSend.append("lines", JSON.stringify(submissionData.lines));
    formDataToSend.append(
      "approvals",
      JSON.stringify(submissionData.approvals)
    );
    formDataToSend.append("docKeys", JSON.stringify(submissionData.docKeys));
    const deletedDocIds = deletedAttachments.map(
      (deleted: any) => deleted.docId
    );
    formDataToSend.append(
      "deletedId",
      JSON.stringify(deletedDocIds.length > 0 ? deletedDocIds : [])
    );
    newAttachments.forEach((attachment: any) => {
      const file = new File([attachment], attachment.name, {
        type: attachment.type,
        lastModified: attachment.lastModified,
      });
      formDataToSend.append("files", file);
    });
    BudgetCreation(formDataToSend);
  };

  const BudgetCreation = async (data: any) => {
    try {
      const response: any = await budgetApis.budgetCreationMethod(data);
      console.log(response);
      const { status, message } = response;
      if (status) {
        Swal.fire("Updated!", "Budget has been Updated", "success");
        navigate(`/view-budget`);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  return (
    <>
      <Head title="Budget - Track" />
      <Modal
        isOpen={generalInformationEdit}
        toggle={() => setGeneralInformationEdit(false)}
        className="modal-dialog-centered"
        size="xl"
        backdrop="static"
      >
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setGeneralInformationEdit(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <ModalBody>
          {/* {tab === "1" && ( */}
          <>
            <h5 className="title">Update General Information</h5>
            <div style={{ marginTop: "15px" }}></div>

            <FormProvider {...methods}>
              <form
                className="content clearfix"
                onSubmit={handleSubmit((data) => {
                  onSubmit(data, 1);
                })}
              >
                <Row className="gy-4">
                  <Col lg="4">
                    <FormInput
                      name="budgetName"
                      label="Budget Name"
                      required={true}
                      placeholder="Enter Budget Name"
                    />
                  </Col>
                  <Col lg="4">
                    <FormDatePicker
                      name="startDate"
                      label="Start Date"
                      required={false}
                    />
                  </Col>
                  <Col lg="4">
                    <FormDatePicker
                      name="endDate"
                      label="End Date"
                      required={false}
                    />
                  </Col>
                  <Col lg="12">
                    <FormTextArea
                      name="description"
                      label="Budget Description"
                      required={false}
                      placeholder="Enter Budget Description"
                    />
                  </Col>
                  <Col lg="3">
                    <FormSelect
                      name="budgetFor"
                      label="Budget for"
                      defaultValue={{
                        label: "Site Procurement",
                        value: "Procurement",
                      }}
                      options={[
                        { label: "Site Procurement", value: "Procurement" },
                        { label: "Site Operations", value: "Operations" },
                      ]}
                      required={false}
                      placeholder="Select"
                    />
                  </Col>
                  <Col lg="3">
                    <FormSelect
                      name="site"
                      label="Site Name"
                      options={sitesOptions}
                      required={false}
                      placeholder="Select"
                    />
                  </Col>
                  <Col lg="3">
                    <FormSelect
                      name="lob"
                      label="Select LOB"
                      defaultValue={{
                        label: "Construction",
                        value: "Construction",
                      }}
                      options={[
                        { label: "Construction", value: "Construction" },
                        { label: "Others", value: "Others" },
                      ]}
                      required={false}
                      placeholder="Select"
                    />
                  </Col>
                  <Col lg="3">
                    <FormSelect
                      name="costCenter"
                      label="Cost Center"
                      options={costCenterOptions}
                      required={false}
                      placeholder="Select"
                    />
                  </Col>
                  <Col lg="3">
                    <FormSelect
                      name="department"
                      label="Department"
                      options={departmentOptions}
                      required={false}
                      placeholder="Select"
                    />
                  </Col>

                  {/* <Col lg="3">
                    <FormSelect
                      name="approvalRequired"
                      label="Approval Required ?"
                      options={[
                        { label: "Yes", value: "Y" },
                        { label: "No", value: "N" },
                      ]}
                      placeholder="Select"
                      required={true}
                    />
                    <div style={{ display: "none" }}>
                      <FormInput name="approvalsData" label="" type="text" />
                    </div>
                    {watch("approvalRequired") == "Y" && (
                      <Popover
                        content="Click here to set required approvals"
                        title="Approval Required"
                      >
                        <a
                          href="#"
                          className="success"
                          onClick={(e) => {
                            e.preventDefault();
                            setApprovals((pre) => {
                              return {
                                ...pre,
                                open: true,
                              };
                            });
                          }}
                        >
                          <EditOutlined /> Set Approvals &nbsp;
                          {approvals?.data?.length == 0 ? (
                            <span className="mandatory">
                              Please set approvals
                            </span>
                          ) : (
                            <></>
                          )}
                        </a>
                      </Popover>
                    )}
                  </Col>
                  <Col lg="3">
                    <FormSelect
                      name="docRequired"
                      label="Require Documents?"
                      options={[
                        { label: "Yes", value: "Y" },
                        { label: "No", value: "N" },
                      ]}
                      required={false}
                      placeholder="Select"
                      // onChange={(val) =>
                      //   updateFormState({ showAskDocs: val === "Y" })
                      // }
                    />
                    {formState.showAskDocs && (
                      <Popover
                        content="Click here to ask required documents"
                        title="Documents Required"
                      >
                        <a
                          href="#"
                          className="success"
                          onClick={(e) => {
                            e.preventDefault();
                            showAskDocumentsDrawer();
                          }}
                        >
                          <EditOutlined /> Ask Required Documents &nbsp;
                          {formState.documentsPopoverVisible ? (
                            <span className="mandatory">
                              Please set documents
                            </span>
                          ) : (
                            <></>
                          )}
                        </a>
                      </Popover>
                    )}
                  </Col> */}
                </Row>
                <Row className="mt-4">
                  <Col lg="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          className="btn btn-primary btn-md"
                          type="submit"
                          color="primary"
                        >
                          Save
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setGeneralInformationEdit(false);
                            reset();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </form>
            </FormProvider>
          </>
          {/* )} */}
        </ModalBody>
      </Modal>
      <div>
        <Button
          className="toggle"
          color="primary"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-3.4%",
            marginLeft: "82%",
            marginBottom: "1%",
          }}
          onClick={() => submitSave()}
        >
          <Icon name="check" />
          <span>Submit Save</span>
        </Button>
      </div>
      <div className="content clearfix">
        <AntCollapse defaultActiveKey={["1", "2", "3"]}>
          <Panel header="General Information" key="1">
            <span
              className="cursor-pointer position-absolute"
              style={{
                right: 43,
                top: 73,
                fontSize: "18px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (tab === "1") {
                  setValue("budgetName", materialData?.budgetName);
                  setValue("startDate", dayjs(materialData?.startDate));
                  setValue("endDate", dayjs(materialData?.endDate));
                  setValue("description", materialData?.description);
                  setValue("budgetFor", materialData?.budgetFor);
                  setValue("site", materialData?.site);
                  setValue("lob", materialData?.lob);
                  setValue("approvalRequired", materialData?.approvalRequired);
                  setValue("costCenter", materialData?.costCenter);
                  setValue("department", materialData?.department);
                }
                setGeneralInformationEdit(true);
              }}
            >
              <Icon name="edit" />
            </span>
            <div>
              <Col className="justify-start w-100  flex-wrap  p-3">
                <div className="w-35 border-end ">
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-30">Budget Name</div>
                    <div className="data-value w-70 ">
                      {materialData?.budgetName}
                    </div>
                  </div>
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-30">Start Date</div>
                    <div className="data-value w-70 ">
                      {moment(materialData?.startDate).format("DD-MMM-YYYY")}
                    </div>
                  </div>
                  <div className="data-col align-center pt-1">
                    <div className="data-label w-30">End Date</div>
                    <div className="data-value w-70">
                      {moment(materialData?.endDate).format("DD-MMM-YYYY")}
                    </div>
                  </div>
                  <div className="data-col align-center pt-1">
                    <div className="data-label w-30">Budget Description</div>
                    <div className="data-value w-70">
                      {materialData?.description}
                      {/* <ApprovalTimelineContent /> */}
                    </div>
                  </div>
                </div>
                <div className="w-30 border-end paddingLeft10 ">
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-30">Budget for</div>
                    <div className="data-value w-70 ">
                      {materialData?.budgetFor}
                    </div>
                  </div>

                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-30">Site</div>
                    <div className="data-value w-70 ">
                      {materialData?.siteName}
                    </div>
                  </div>
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-30">LOB</div>
                    <div className="data-value w-70 ">{materialData?.lob}</div>
                  </div>
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-30">Cost Center</div>
                    <div className="data-value w-70 ">
                      {materialData?.costCenterName}
                    </div>
                  </div>
                </div>
                <div className="w-35 paddingLeft10">
                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-30">Department</div>
                    <div className="data-value w-70 ">
                      {materialData?.departmentName}
                    </div>
                  </div>

                  <div className="data-col align-center pt-1 ">
                    <div className="data-label w-30">Approval Required</div>
                    <div className="data-value w-70 ">
                    <ApprovalTimelineContent />
                      {/* {materialData?.approvalRequired
                        ? "Y" && (
                            <>
                              <ApprovalTimelineContent />
                            </>
                          )
                        : "N" && "No"} */}
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Panel>
          <Panel header="Material Informations" key="2">
            <div className="table-responsive poTable">
              <div
                style={{
                  width: screenSize - 380 + "px",
                  margin: "15px auto",
                  // minHeight: "200px",
                }}
              >
                {data && (
                  <Table
                    className="customTable"
                    columns={columns}
                    dataSource={data?.map((item: any) => ({
                      ...item,
                      key: item?.lineNo,
                    }))}
                    scroll={{ x: 1000 }}
                    expandable={{
                      expandedRowRender: (record: any) => (
                        <div className="expandableBody">
                          <div style={{ flex: 1, textAlign: "left" }}>
                            <p style={{ margin: 0 }}>{record.description}</p>
                            {record.file && (
                              <div>
                                <PaperClipOutlined /> &nbsp;
                                <a
                                  href={record?.file?.fileName}
                                  download={record?.file?.fileName}
                                >
                                  {record?.file?.fileName}
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      ),
                    }}
                    locale={{
                      emptyText: (
                        <span
                          style={{
                            height: "300px",
                            color: "#333",
                            fontSize: "16px",
                          }}
                        >
                          Please click on "Add New Item" to add purchase order
                          lines.
                        </span>
                      ),
                    }}
                    bordered
                    size="middle"
                    pagination={false}
                  />
                )}
              </div>
              {data?.length > 0 && (
                <Row
                  style={{
                    margin: "20px",
                  }}
                >
                  <a
                    onClick={() => {
                      setAddNewRowModel(true);
                      setEditLineRow(null);
                    }}
                    href="#add"
                    className="addNewItem"
                  >
                    <Icon name="plus-round" />
                    <span>Add New Item</span>
                  </a>
                </Row>
              )}
            </div>
          </Panel>
          <Panel header="Other Information" key="3">
            <Descriptions title="" className="custom-description-title">
              <Descriptions.Item label="Instructions">
                <span
                  style={{
                    color: "#2263b3",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setInstructionsVisible(true)}
                >
                  View Instructions
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Terms and Conditions">
                <span
                  style={{
                    color: "#2263b3",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setTermsVisible(true)}
                >
                  View Terms and Conditions
                </span>
              </Descriptions.Item>
              {attachments?.length > 0 && (
                <Descriptions.Item label="">
                  <div>
                    <strong className="">
                      Attachments &nbsp;
                      <PaperClipOutlined />
                    </strong>
                    <div>
                      {attachments?.map((attachment: any, index: number) => (
                        <div
                          key={index}
                          className="w-100 d-flex justify-content-between"
                        >
                          <div>
                            <strong>{index + 1}.</strong>
                            &nbsp;
                            {attachment.name}
                            &nbsp; &nbsp; &nbsp; &nbsp;
                          </div>
                          <div>
                            <a
                              href={attachment.link}
                              download={attachment.link}
                              style={{ color: "black", fontSize: "18px" }}
                            >
                              <DownloadOutlined />
                            </a>
                            &nbsp; &nbsp;
                            <div
                              style={{
                                display: "inline",
                                cursor: "pointer",
                                color: "red",
                                fontSize: "16px",
                              }}
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default anchor behavior
                                setDeletedAttachments((prev: any) => [
                                  ...prev,
                                  { docId: attachment?.docId },
                                ]);
                                let updatedAttachmentsList =
                                  attachments?.filter(
                                    (item: any, i: number) => i !== index
                                  );
                                setAttachments(updatedAttachmentsList);
                                setShowSubmitBtm(true);
                              }}
                            >
                              <DeleteOutlined />
                            </div>
                          </div>
                        </div>
                      ))}
                      {newAttachments?.map((attachment: any, index: number) => (
                        <div
                          key={index}
                          className="w-100 d-flex justify-content-between"
                        >
                          <div>
                            <strong>{attachments?.length + index + 1}.</strong>
                            &nbsp;
                            {attachment.name}
                            &nbsp; &nbsp; &nbsp; &nbsp;
                          </div>
                          <div>
                            <a
                              href={attachment.link}
                              download={attachment.link}
                              style={{
                                color: "black",
                                fontSize: "18px",
                              }}
                            >
                              <DownloadOutlined />
                            </a>
                            &nbsp; &nbsp;
                            <div
                              style={{
                                display: "inline",
                                cursor: "pointer",
                                color: "red",
                                fontSize: "16px",
                              }}
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default anchor behavior
                                setNewAttachments((prev: any) =>
                                  prev.filter(
                                    (item: any, i: number) => i !== index
                                  )
                                );
                                setShowSubmitBtm(true);
                              }}
                            >
                              <DeleteOutlined />
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="mt-1">
                        <Dragger
                          beforeUpload={beforeUpload}
                          fileList={[]}
                          onChange={handleAttachmentChange}
                        >
                          <a onClick={() => {}} className="addNewItem">
                            <Icon name="plus-round" />
                            <span>Add Attachment</span>
                          </a>
                        </Dragger>
                      </div>
                    </div>
                  </div>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Panel>
        </AntCollapse>

        {addNewRowModel && (
          <MaterialModal
            modal={addNewRowModel}
            closeModal={() => {
              setAddNewRowModel(false);
              setEditLineRow(null);
            }}
            onSubmit={updateBudgetLines}
            status={materialData?.orderStatus}
            formData={{
              action: editLineRow == null ? "add" : "edit",
              index: editLineRow?.lineId,
              item: editLineRow,
            }}
            appenddata={append}
            update={(id: any, data: any) => {
              update(id, data);
            }}
            // needByDateType={materialData?.needByDateType}
            // deliveryAddressType={materialData?.deliveryAddressType}
          />
        )}

        {/* <Modal
            title="Instructions"
            visible={instructionsVisible}
            footer={null}
            onCancel={() => setInstructionsVisible(false)}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: previousFormData?.instructions || "",
              }}
            />
          </Modal>
          <Modal
            title="Terms and Conditions"
            visible={termsVisible}
            footer={null}
            onCancel={() => setTermsVisible(false)}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: previousFormData?.termsAndConditions || "",
              }}
            />
          </Modal> */}
        {/* <div className="actions clearfix">
            <ul>
              <li>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </li>
              <li>
                <Button
                  color="primary"
                  onClick={() => {
                    props.prev();
                  }}
                  className="btn-dim btn btn-secondary"
                >
                  Previous
                </Button>
              </li>
            </ul>
          </div> */}
      </div>
      {/* <QuotationApprovalDrawer
        open={approvals.open}
        onClose={() => {
          setApprovals((pre) => {
            return {
              ...pre,
              open: false,
            };
          });
        }}
        onSubmit={(data: any) => {
          ApprovalsUpdate(data);
          setApprovals({
            open: false,
            data: data,
          });
        }}
        setApprovalSubmissionStatus={(status: boolean) => {}}
        approvalData={approvals.data} // Pass the current approval data
      /> */}
    </>
  );
};

export default ViewBudgetDetails;
