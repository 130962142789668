import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Card,
  Badge,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  DataTable,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  PaginationComponent,
} from "../../components/Component";
import UserProfileAside from "./PurchaseRequestAside";
import { DoubleBar } from "../../components/partials/charts/default/Charts";
import sites from "../../api/master/sites";
import FormatDate from "../masters/DateFormater";

const orderStatusCards: any = {
  // draft: {
  //   color: "#e85347",
  //   lable: "Draft Purchase Order",
  // },

  pending: {
    color: "#09c2de",
    lable: "Pending Approvals",
  },
  withSuppliers: {
    color: "#364a63",
    lable: "With Suppliers",
  },
  invoiced: {
    color: "#1ee0ac",
    lable: "Invoiced",
  },
  deliveredGrn: {
    color: "#2263b3",
    lable: "Delivered GRN",
  },
  shortClosed: {
    color: "rgb(249, 140, 69)",
    lable: "Short Closed",
  },
};

const ViewPurchaseRequests: FC = () => {
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [rowData, setRowData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = rowData.slice(indexOfFirstItem, indexOfLastItem);
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  useEffect(() => {
    viewChange();
    fetchPurchaseOrders();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const fetchPurchaseOrders=()=>{
    setRowData([
      {
        id: 1,
        date: "2021-10-25",
        supplier: "John Doe",
        site: "New York",
        costCenter:"Finance",
        poValue: "100.00",
        poVersion: 1,
        createdBy: "2021-10-25",
        inWardStatus: "Pending",
      },
      {
        id: 2,
        date: "2021-10-25",
        supplier: "John Doe",
        site: "New York",
        costCenter:"Finance",
        poValue: "100.00",
        poVersion: 1,
        createdBy: "2021-10-25",
        inWardStatus: "Pending",
      },
      {
        id: 3,
        date: "2021-10-25",
        supplier: "John Doe",
        site: "New York",
        costCenter:"Finance",
        poValue: "100.00",
        poVersion: 1,
        createdBy: "2021-10-25",
        inWardStatus: "Pending",
      },
    ]);
  }

  return (
    <>
      <Head title="Purchase Requests - View" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <UserProfileAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Purchase Orders</BlockTitle>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block>
                <div className="nk-order-ovwg">
                  <Row className="g-4 align-center justify-center">
                    <Col xxl="4">
                      <Row className="g-4 align-center justify-around">
                        {Object.keys(orderStatusCards).map((key) => (
                          <Col xxl="12" sm="2">
                            <div
                              className="nk-order-ovwg-data"
                              style={{
                                borderColor: orderStatusCards[key].color,
                                opacity: 0.8,
                              }}
                            >
                              <div
                                className="title"
                                style={{
                                  color: orderStatusCards[key].color,
                                  // color:"#1f2b3a",
                                  textAlign: "center",
                                }}
                              >
                                {orderStatusCards[key].lable}
                              </div>
                              <div
                                className="amount"
                                style={{
                                  // color: orderStatusCards[key].color,
                                  color: "#1f2b3a",
                                  // opacity: 0.8,
                                  textAlign: "center",
                                }}
                              >
                                {Math.floor(Math.random() * (100 - 10 + 1)) +
                                  10}
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <DataTable
                    className="card-stretch"
                    bodyClassName={undefined}
                    title={undefined}
                  >
                    <DataTableBody
                      compact
                      className={undefined}
                      bodyclass={undefined}
                    >
                      <DataTableHead>
                        <DataTableRow className={undefined} size={undefined}>
                          <span className="sub-text">#</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span className="sub-text">Date</span>
                        </DataTableRow>
                        <DataTableRow size="sm" className={undefined}>
                          <span className="sub-text">Supplier</span>
                        </DataTableRow>
                        <DataTableRow size="sm" className={undefined}>
                          <span className="sub-text">Site</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span className="sub-text">Cost Center</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span className="sub-text">Po Value</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span className="sub-text">Po Version</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span className="sub-text">Created By</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span className="sub-text">Inward Status</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          {/* <span className="sub-text">Details</span> */}
                        </DataTableRow>
                      </DataTableHead>
                      {/*Head*/}
                      {currentItems.length > 0
                        ? currentItems.map((item: any, index: any) => {
                            return (
                              <DataTableItem
                                key={item.id}
                                className={undefined}
                                style={{ color: "black" }}
                              >
                                <DataTableRow size="md" className={undefined}>
                                  <span>{index + 1}</span>
                                </DataTableRow>
                                <DataTableRow size="md" className={undefined}>
                                  <span>{FormatDate(item.date)}</span>
                                </DataTableRow>
                                <DataTableRow size="sm" className={undefined}>
                                  <span>{item.supplier}</span>
                                </DataTableRow>
                                <DataTableRow size="sm" className={undefined}>
                                  <span>{item.site}</span>
                                </DataTableRow>
                                <DataTableRow size="md" className={undefined}>
                                  <span>{item.costCenter}</span>
                                </DataTableRow>
                                <DataTableRow size="md" className={undefined}>
                                  <span>{item.poValue}</span>
                                </DataTableRow>
                                <DataTableRow size="md" className={undefined}>
                                  <span>{item.poVersion}</span>
                                </DataTableRow>
                                <DataTableRow size="md" className={undefined}>
                                  <span>{item.createdBy}</span>
                                </DataTableRow>
                                <DataTableRow size="md" className={undefined}>
                                  <span
                                    className={`tb-status text-${
                                      item.status === "Active"
                                        ? "success"
                                        : "danger"
                                    }`}
                                  >
                                    {item.inWardStatus}
                                  </span>
                                </DataTableRow>
                                <DataTableRow size="md" className={undefined}>
                                  <ul className="nk-tb-actions">
                                    <li
                                      className="nk-tb-action-hidden"
                                      onClick={() => {
                                        alert("edit");
                                      }}
                                    >
                                      <TooltipComponent
                                        tag="a"
                                        containerClassName="btn btn-trigger btn-icon"
                                        id={"edit" + item.id}
                                        icon="edit-alt-fill"
                                        direction="top"
                                        text="Edit"
                                        iconClass={undefined}
                                      />
                                    </li>
                                    <li>
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          tag="a"
                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                        >
                                          <Icon name="more-h"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                          <ul className="link-list-opt no-bdr">
                                            <li
                                              onClick={() => {
                                                alert("edit");
                                              }}
                                            >
                                              <DropdownItem
                                                tag="a"
                                                href="#edit"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                }}
                                              >
                                                <Icon name="edit"></Icon>
                                                <span>Edit</span>
                                              </DropdownItem>
                                            </li>
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#edit"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  alert("view");
                                                }}
                                              >
                                                <Icon name="focus"></Icon>
                                                <span>View Details</span>
                                              </DropdownItem>
                                            </li>
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#edit"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  alert("delete");
                                                }}
                                              >
                                                <Icon name="trash"></Icon>
                                                <span>Delete</span>
                                              </DropdownItem>
                                            </li>
                                          </ul>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </li>
                                  </ul>
                                </DataTableRow>
                              </DataTableItem>
                            );
                          })
                        : null}
                    </DataTableBody>
                    <div className="card-inner">
                      {currentItems.length > 0 ? (
                        <PaginationComponent
                          itemPerPage={itemPerPage}
                          totalItems={rowData.length}
                          paginate={paginate}
                          currentPage={currentPage}
                        />
                      ) : (
                        <div className="text-center">
                          <span className="text-silent">No data found</span>
                        </div>
                      )}
                    </div>
                  </DataTable>
                </div>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default ViewPurchaseRequests;
