import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component"; // Assuming these are properly typed elsewhere

interface DispatchRequestAsideProps {
    updateSm: (value: boolean) => void;  // Function to update state
    sm: boolean;  // Boolean state
}

const DispatchAside: FC<DispatchRequestAsideProps> = ({ updateSm, sm }) => {
   
    const location = useLocation();

    useEffect(() => {
        sm ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{fontSize: 14 }}>GRN</h6>
                   
                </div>
            </div>
            <div className="card-inner p-0">
                <ul className="link-list-menu">
                   
                    <li onClick={() => updateSm(false)}>
                        <Link to="/view-dispatch-list" className={isActive(`${process.env.PUBLIC_URL}/view-dispatch-list`)}>
                            <Icon name="eye-fill" />
                            <span>Pending GRN</span>
                        </Link>
                    </li>

                    <li onClick={() => updateSm(false)}>
                        <Link to="/delivered-dispatch-list" className={isActive(`${process.env.PUBLIC_URL}/delivered-dispatch-list`)}>
                            <Icon name="check-fill-c" />
                            <span>Delivered </span>
                        </Link>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default DispatchAside;
