import React, { useEffect } from 'react';
import * as echarts from 'echarts';

interface StatusData {
    name: string;
    value: number;
}

const PRSummaryWidget: React.FC = () => {
    const data: StatusData[] = [
        { name: 'Draft', value: 15 },
        { name: 'Open', value: 25 },
        { name: 'Quotations Requested', value: 20 },
        { name: 'Quotations Finalized', value: 10 },
        { name: 'POs Raised', value: 8 },
        { name: 'Invoiced', value: 7 },
        { name: 'Delivered', value: 5 },
        { name: 'In-transit', value: 10 },
        { name: 'Fully Paid', value: 5 },
        { name: 'Partialy Paid', value: 5 },
        { name: 'Not Paid', value: 2 },
    ];

    useEffect(() => {
        const chartDom = document.getElementById('pr-summary-widget') as HTMLElement;
        const myChart = echarts.init(chartDom);

        const option = {
            tooltip: {
                trigger: 'item',
            },
            series: [
                {
                    name: 'PR Status',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: true,
                        formatter: '{b|{b}}\n{c|{c} ({d}%)}',
                        rich: {
                            b: {
                                fontSize: 14,
                                color: '#333',
                                lineHeight: 24,
                            },
                            c: {
                                fontSize: 12,
                                color: '#999',
                                lineHeight: 20,
                            },
                        },
                    },
                    labelLine: {
                        show: true,
                        length: 15,
                        length2: 10,
                        smooth: true,
                    },
                    itemStyle: {
                        borderColor: '#E0E4FF',  // Border color
                        borderWidth: 1,       // Border width
                    },
                    data: data.map((item) => ({ value: item.value, name: item.name })),
                },
            ],
            //color: ['#173F5F', '#20639B', '#3CAEA3', '#F6D55C', '#ED553B', '#e81e63', '#03a9f4', '#cddc39', '#9c27b0'],
            color : ['#173F5F', '#20639B', '#3CAEA3', '#F6D55C', '#ED553B', '#e81e63', '#03a9f4', '#cddc39', '#9c27b0', '#ffeb3b'],
            // color: [
            //     "#549CFB", // Base Blue (Darker)
            //     "#5A91F9", // Blue Shade 1
            //     "#6086F7", // Blue Shade 2
            //     "#6676FF", // Blue Shade 3
            //     "#6E7AF5", // Blue Shade 4
            //     "#757EEE", // Blue Shade 5
            //     "#7B83E8", // Blue Shade 6
            //     "#8179FF", // Blue Shade 7
            //     "#8A79FF", // Blue Shade 8
            //     "#937BFF", // Blue Shade 9
            //     "#9C7DFF", // Blue Shade 10
            //     "#A682FF", // Blue Shade 11
            //     "#B088FF", // Blue Shade 12
            //     "#BA8EFF", // Blue Shade 13
            //     "#C497FF"  // Lightest Blue
            // ]
        };
        myChart.setOption(option);

        return () => {
            myChart.dispose();
        };
    }, [data]);

    // return <div id="pr-summary-widget" style={{ width: '100%', height: '400px' }} />;
    return (
        <>
            <div className="card-title-group mb-1">
                <div className="card-title">
                    <h6 className="title">Purchase Request Summary</h6>
                    <p>
                        Tracking the Journey from Request to Delivery
                        {/* <a
                            href="#all"
                            onClick={(ev) => {
                                ev.preventDefault();
                            }}
                        >
                            All
                        </a> */}
                    </p>
                </div>
            </div>
            <div id="pr-summary-widget" style={{ width: '100%', height: '300px' }} />;
        </>
    )
};

export default PRSummaryWidget;
