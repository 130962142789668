import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button, Badge } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
  Tooltip,
  Form,
  InputNumber,
  Drawer,
  Collapse,
  Upload,
  Popover,
  DatePicker,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import {
  SearchOutlined,
  InfoCircleOutlined,
  PaperClipOutlined,
  UploadOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import statusMapper from "./StatusMapper";
import dispatchApis from "../../api/dispatch/dispatch";
import DispatchAside from "./paymentsNav";
import invoiceApis from "../../api/Invoice/invoice";
import { formatCurrency, formatCurrency2 } from "../../utils/Utils";
import ReactQuill from "react-quill";
import PaymentsApprovalDrawer from "./PaymentsApprovalDrawer";
import paymentApis from "../../api/payments/payments";
import dayjs from "dayjs";
const { Option } = Select;
const { Panel } = Collapse;

interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: any) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={`payableAmount_${record.invoiceNumber}`}
          style={{ margin: 0 }}
          rules={[
            {
              type: "number",
              min: 0.01,
              message: `${title} must > 0`,
            },
          ]}
          initialValue={
            Number(record?.grossTotal) -
            Number(record?.paidAmount) -
            Number(record?.createdPayableAmount)
          }
        >
          <InputNumber
            defaultValue={
              Number(record?.grossTotal) -
              Number(record?.paidAmount) -
              Number(record?.createdPayableAmount)
            }
            className="w-100"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
            onKeyPress={(event) => {
              const regex = /^[0-9\b\.]+$/;
              if (!regex.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={0.01}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditCN = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: any) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={`utilizedAmount_${record.noteNumber}`}
          style={{ margin: 0 }}
          rules={[
            {
              type: "number",
              min: 0.01,
              max: Number(record?.unUtilizedAmount),
              message: `${title} must > 0`,
            },
          ]}
          initialValue={Number(record?.unUtilizedAmount)}
        >
          <InputNumber
            defaultValue={Number(record?.unUtilizedAmount)}
            className="w-100"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
            onKeyPress={(event) => {
              const regex = /^[0-9\b\.]+$/;
              if (!regex.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={0.01}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const InvoicePayables: FC = () => {
  const navigate = useNavigate();
  const [CNError, setCNError] = useState<any>(null);
  const [selectedCNValueTotal, setSelectedCNValueTotal] = useState(0);
  const [selectedCNValueUtilized, setSelectedCNValueUtilized] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sm, setSm] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [tab, setTab] = useState(null);
  const [matchingLoad, setMatchingLoad] = useState(true);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [originalData, setOriginalData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [editingKeys, setEditingKeys] = useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedCnKeys, setSelectedCnKeys] = useState<React.Key[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [payableLines, setPayableLines] = useState<any>([]);
  const [approvalRequired, setApprovalRequired] = useState("true");
  const [approvals, setApprovals] = useState({
    open: false,
    data: [],
  });
  const [paymentMode, setPaymentMode] = useState<string>("neft");
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const isEditing = (record: any) => editingKeys.includes(record.invoiceNumber);
  const [editingCn, setEditingCn] = useState<any>([]);
  const isCnEditing = (record: any) => editingCn.includes(record.noteNumber);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [supplierList, setSupplierList] = useState<any>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<any>(null);
  const [CN, setCN] = useState<any>([]);

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowKeys([record.key]); // Only allow one expanded row at a time
      setTab(record.key);
    } else {
      setExpandedRowKeys([]); // Collapse all rows
    }
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    viewChange();
    fetchAllInvoices();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const columns: any = [
    {
      title: "Invoice #",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 100,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "3 Way Match",
      key: "#",
      dataIndex: "threeWayMatch",
      width: 100,
      hidden: true,
      fixed: "left",
      render: (text: any) => {
        const { isThreeWayMatched, message } = text;
        if (isThreeWayMatched) {
          return <Badge color="outline-success">Matched</Badge>;
        }
        return <Badge color="outline-danger">Not Matched</Badge>;
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 100,
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 200,
    },
    {
      title: "Outstanding Amt",
      key: "outstandingAmount",
      width: 140,
      render: (text: any, record: any) => {
        return (
          <span>
            {formatCurrency2(
              Number(record?.grossTotal) -
                Number(record?.paidAmount) -
                Number(record?.createdPayableAmount),
              "INR"
            )}
            <Tooltip
              title={formatCurrency(
                Number(record?.grossTotal) -
                  Number(record?.paidAmount) -
                  Number(record?.createdPayableAmount),
                "INR"
              )}
            >
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: "payableAmount",
      key: "payableAmount",
      width: 200,
      editable: true,
      render: (text: any, record: any) => {
        return <span></span>;
      },
    },
    {
      title: "InProcess Amt",
      key: "createdPayableAmount",
      width: 200,
      render: (text: any, record: any) => {
        return (
          <span>
            {formatCurrency2(record?.createdPayableAmount, "INR")}
            <Tooltip
              title={formatCurrency(record?.createdPayableAmount, "INR")}
            >
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "INWARD STATUS",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "invoiceStatus",
      key: "invoiceStatus",
      width: 200,
      fixed: "right",
      render: (status: any) => {
        const _status = statusMapper[status]?.seller;
        return (
          <div className="justify-start align-center">
            <h6
              className={`tb-status ms-1`}
              style={{
                color: `${_status?.color}`,
                fontSize: "14px",
              }}
            >
              {_status?.icon} {_status?.title || status}
            </h6>
          </div>
        );
      },
    },
  ];

  const cNColumns: any = [
    {
      title: "Note #",
      dataIndex: "noteNumber",
      key: "noteNumber",
      width: 100,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Note Amt",
      key: "unUtilizedAmount",
      width: 140,
      render: (text: any, record: any) => {
        return (
          <span>
            {formatCurrency2(Number(record?.unUtilizedAmount), "INR")}
            <Tooltip
              title={formatCurrency(Number(record?.unUtilizedAmount), "INR")}
            >
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: "Utilized Amount",
      key: "utilizedAmount",
      width: 200,
      editable: true,
      render: (text: any, record: any) => {
        return <span></span>;
      },
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (!newSelectedRowKeys) {
      editingKeys.forEach((key) => {
        const row = filteredData.find((item: any) => item.invoiceNumber == key);
        form.setFieldsValue({ [`payableAmount_${row.invoiceNumber}`]: null });
      });
      setEditingKeys(() => []);
      form.resetFields();
    }
    const unSelectedRowKeys = selectedRowKeys.filter(
      (key) => !newSelectedRowKeys.includes(key)
    );
    unSelectedRowKeys?.forEach((key) => {
      const row = filteredData.find((item: any) => item.invoiceNumber == key);
      form.setFieldsValue({ [`payableAmount_${row.invoiceNumber}`]: null });
    });
    const editableRowKeys: any = [];
    newSelectedRowKeys?.forEach((key) => {
      const row = filteredData.find((item: any) => item.invoiceNumber == key);
      if (row) {
        form.setFieldsValue({ ...row });
        editableRowKeys.push(row.invoiceNumber);
      }
    });
    setEditingKeys(editableRowKeys);
  };

  const onCNSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedCnKeys(newSelectedRowKeys);
    if (!newSelectedRowKeys) {
      editingCn.forEach((key: any) => {
        const row = CN.find((item: any) => item.noteNumber == key);
        form3.setFieldsValue({
          [`utilizedAmount_${row.noteNumber}`]:
            Number(row?.noteAmount) - Number(row?.utilizedAmount),
        });
      });
      setEditingCn(() => []);
      form3.resetFields();
    }
    const unSelectedRowKeys = selectedCnKeys.filter(
      (key) => !newSelectedRowKeys.includes(key)
    );
    unSelectedRowKeys?.forEach((key) => {
      const row = CN.find((item: any) => item.noteNumber == key);
      form3.setFieldsValue({
        [`utilizedAmount_${row.noteNumber}`]:
          Number(row?.noteAmount) - Number(row?.utilizedAmount),
      });
    });
    const editableRowKeys: any = [];
    newSelectedRowKeys?.forEach((key) => {
      const row = CN.find((item: any) => item.noteNumber == key);
      if (row) {
        form3.setFieldsValue({ ...row });
        editableRowKeys.push(row.noteNumber);
      }
    });
    setEditingCn(editableRowKeys);
  };

  const fetchAllOrgUnUtilizedCreditDebitNotes = async () => {
    try {
      const res = await paymentApis.fetchAllOrgUnUtilizedCreditDebitNotes(
        selectedSupplier
      );
      const { data, status, message } = res;
      if (status) {
        setCN(data);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const showDrawer = async () => {
    try {
      const rows = await form.validateFields();
      fetchAllOrgUnUtilizedCreditDebitNotes();
      const invoiceLines: any = [];
      filteredData?.forEach((item: any) => {
        if (
          editingKeys.includes(item.invoiceNumber) &&
          rows[`payableAmount_` + item.invoiceNumber] > 0
        ) {
          const payableAmount = Number(
            rows[`payableAmount_` + item.invoiceNumber]
          );
          invoiceLines.push({
            ...item,
            payableAmount,
          });
        }
      });
      if (invoiceLines.length > 0) {
        setTab(invoiceLines[0]?.invoiceNumber);
      }
      setPayableLines(invoiceLines);
      setDrawerVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (tab) {
      setMatchingLoad(true);
      fetchInvoiceDetails(tab);
    }
  }, [tab]);

  const fetchInvoiceDetails = async (invoiceNumber: any) => {
    try {
      const res = await invoiceApis.fetchInvoice(invoiceNumber);
      const { data, status, message } = res;
      if (status) {
        setInvoiceData(data?.invoiceLines);
        setMatchingLoad(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const cnRowSelection = {
    selectedRowKeys: selectedCnKeys,
    onChange: onCNSelectChange,
  };

  useEffect(() => {
    let _filterData = originalData;
    if (selectedSupplier) {
      _filterData = _filterData.filter(
        (item: any) => item.supplierId == selectedSupplier
      );
    }
    setFilteredData(_filterData);
  }, [selectedSupplier, originalData]);

  const fetchAllInvoices = async () => {
    try {
      const res = await invoiceApis.fetchPayableInvoicesForPayments();
      const { data, status, message } = res;
      if (status) {
        setOriginalData(data);
        setFilteredData(data);
        const _supplierList = data?.map((item: any) => {
          return {
            label: item.supplierName,
            value: item.supplierId,
          };
        });
        setSupplierList(_supplierList);
      } else {
        Swal.fire("Error", message, "error");
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const submit = async (data: any) => {
    try {
      const lineItems: any = payableLines?.map((item: any) => {
        return {
          invoiceNumber: item.invoiceNumber,
          payableAmount: item.payableAmount,
        };
      });
      const paymentData: any = {
        paymentDate: data?.paymentDate?.format("YYYY-MM-DD"),
        remarks: data?.remarks,
        lineItems,
      };

      let paymentApprovalHierarchy: any = [];
      if (data?.approvals == "true") {
        paymentData.autoRelease = false;
        paymentApprovalHierarchy = approvals?.data;
      } else {
        paymentData.autoRelease = true;
        paymentData.paymentMode = data?.paymentMode;
        paymentData.transactionNumber = data?.transactionNumber?.toString();
      }

      const res = await paymentApis.submitPayment({
        paymentData,
        paymentApprovalHierarchy,
        creditDebitNotes: selectedCNValueUtilized,
      });
      const { status, message } = res;
      if (status) {
        Swal.fire("Success", message, "success");
        navigate("/view-payments");
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const invoiceColumns: any = [
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
      fixed: "left",
      render: (material: any) => <div className="align-left">{material}</div>,
    },
    {
      title: "Ordered Qty",
      dataIndex: "quantityOrdered",
      key: "quantityOrdered",
      width: 150,
      render: (text: any, record: any) => (
        <div className="align-left">{Number(text) + " " + record?.uom}</div>
      ),
    },
    {
      title: "Delivered Qty",
      dataIndex: "quantityDelivered",
      key: "quantityDelivered",
      width: 150,
      render: (text: any, record: any) => {
        const isHighlighted = Number(record?.quantityOrdered) < Number(text);
        return (
          <div
            className="align-left"
            style={{
              backgroundColor: isHighlighted ? "lightcoral" : "transparent", // Highlight with light red if quantities differ
              color: isHighlighted ? "white" : "inherit", // Optionally change text color when highlighted
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {Number(text) + " " + record?.uom}
          </div>
        );
      },
    },
    {
      title: "Invoiced Qty",
      dataIndex: "quantityInvoiced",
      key: "quantityInvoiced",
      width: 150,
      render: (text: any, record: any) => {
        const isHighlighted = Number(record?.quantityDelivered) != Number(text);
        return (
          <div
            className="align-left"
            style={{
              backgroundColor: isHighlighted ? "lightcoral" : "transparent", // light red color for highlighting
              color: isHighlighted ? "white" : "inherit", // optional: change text color when highlighted
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {Number(text) + " " + record?.uom}
          </div>
        );
      },
    },
    {
      title: "Order Price",
      dataIndex: "orderPrice",
      key: "orderPrice",
      width: 150,
      render: (text: any) => (
        <>
          {formatCurrency2(Number(text), "INR")}
          <span>
            <Tooltip title={formatCurrency(Number(text), "INR")}>
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip>
          </span>
        </>
      ),
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 150,
      render: (text: any, record: any) => {
        const isHighlighted = Number(record?.orderPrice) < Number(text);
        return (
          <div
            style={{
              backgroundColor: isHighlighted ? "lightcoral" : "transparent", // Highlight if orderPrice < unitPrice
              color: isHighlighted ? "white" : "inherit", // Optional: change text color when highlighted
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {formatCurrency2(Number(text), "INR")}
            <span>
              <Tooltip title={formatCurrency(Number(text), "INR")}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      title: "Need By Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 150,
      render: (text: any) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      key: "netAmount",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text).toFixed(2)}%`,
        },
        {
          title: "Amount",
          dataIndex: "cgst",
          key: "cgst",
          width: 150,
          render: (text: any) => <p className="justify-end">{text}</p>,
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text).toFixed(2)}%`,
        },
        {
          title: "Amount",
          dataIndex: "sgst",
          key: "sgst",
          width: 150,
          render: (text: any) => <p className="justify-end">{text}</p>,
        },
      ],
    },
    {
      title: "IGST",
      children: [
        {
          title: "%",
          dataIndex: "igstPercentage",
          key: "igstPercentage",
          width: 100,
          render: (text: any) => `${Number(text).toFixed(2)}%`,
        },
        {
          title: "Amount",
          dataIndex: "igst",
          key: "igst",
          width: 150,
          render: (text: any) => <p className="justify-end">{text}</p>,
        },
      ],
    },
    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
  ];

  return (
    <>
      <Head title="Invoice Payables - View" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <DispatchAside updateSm={setSm} sm={sm} />
            </div>
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}

                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">Invoice Payables</BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>

                {originalData.length == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Empty description="No Invoice Payables" />
                  </div>
                ) : (
                  <div>
                    {filteredData.length == 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "200px",
                        }}
                      >
                        <Empty description="No Data for selected filters" />
                      </div>
                    ) : (
                      <>
                        <div className="d-flex justify-content-between m-0 p-0">
                          <div className="w-70 d-flex align-center">
                            <Form.Item
                              name="supplier"
                              label=""
                              className="mb-0"
                              style={{ width: 200 }}
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select a supplier"
                                optionFilterProp="children"
                                onChange={(data: any) => {
                                  setSelectedSupplier(data);
                                }}
                                onSearch={(data: any) => {}}
                                filterOption={(input, option: any) =>
                                  (option?.label ?? "")
                                    ?.toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {supplierList?.map((item: any) => (
                                  <Option value={item?.value} key={item?.value}>
                                    {item?.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <Button
                            color="primary"
                            className="m-0"
                            style={{ height: 40 }}
                            onClick={showDrawer}
                            disabled={selectedRowKeys.length === 0}
                          >
                            Preview Payment
                          </Button>
                        </div>
                        <div
                          style={{
                            width: screenSize - 330 + "px",
                            minHeight: "200px",
                            marginTop: "20px",
                          }}
                        >
                          <Form form={form} component={false}>
                            <Table
                              className="customTable"
                              rowSelection={
                                selectedSupplier ? rowSelection : undefined
                              }
                              columns={columns.map((col: any) => {
                                if (!col.editable) {
                                  return col;
                                }
                                return {
                                  ...col,
                                  onCell: (record: any) => ({
                                    record,
                                    inputType:
                                      col.dataIndex === "payableAmount"
                                        ? "number"
                                        : "text",
                                    dataIndex: col.dataIndex,
                                    title: col.title,
                                    editing: isEditing(record),
                                  }),
                                };
                              })}
                              components={{
                                body: {
                                  cell: EditableCell,
                                },
                              }}
                              dataSource={filteredData?.map((item: any) => ({
                                ...item,
                                key: item.invoiceNumber,
                              }))}
                              pagination={{
                                pageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ["10", "20", "30", "50"],
                              }}
                              bordered
                              size="middle"
                              scroll={{ x: 1400 }}
                            />
                          </Form>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </Card>
      </Content>
      {drawerVisible && (
        <Drawer
          title={
            <div className="d-flex justify-content-between align-items-center">
              <strong>Payment Preview</strong>
              <div style={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    form2.submit();
                  }}
                  color="primary"
                  className="m-3 mt-0 mb-0"
                  disabled={CNError ? true : false}
                >
                  Submit
                </Button>
              </div>
            </div>
          }
          placement="right"
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          width="85vw"
          style={{
            position: "absolute",
            zIndex: 1100,
          }}
          styles={{ body: { paddingBottom: 80 } }}
        >
          <Form
            form={form2}
            layout="vertical"
            onFinish={(data) => {
              Swal.fire({
                title: "Are you sure?",
                text: "You are about to payment for selected invoices",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Create !",
              }).then((result) => {
                if (result.isConfirmed) {
                  submit(data);
                }
              });
            }}
            requiredMark={false}
          >
            <h6 className="d-flex justify-content-between">
              <div>Invoice Payables</div>
              <div className="p-3 pt-0 pb-0">
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <div className="justify-end">
                      <strong>Payable Amount Total &nbsp; &nbsp; </strong>
                    </div>
                    <strong className="fw-bold">
                      {formatCurrency(
                        payableLines.reduce(
                          (a: any, b: any) => a + Number(b?.payableAmount),
                          0
                        ),
                        "INR"
                      )}
                    </strong>
                  </div>
                </div>
              </div>
            </h6>
            <div className="table-responsive poTable">
              <div
                style={{
                  width: screenSize - 320 + "px",
                  margin: "0px auto 15px auto",
                }}
              >
                <Table
                  className="customTable"
                  columns={columns.map((col: any) => {
                    if (col.key == "payableAmount") {
                      return {
                        ...col,
                        editable: false,
                        render: (text: any, record: any) =>
                          `${formatCurrency(
                            Number(record?.payableAmount),
                            "INR"
                          )}`,
                      };
                    } else if (col.dataIndex === "invoiceStatus") {
                      return {
                        title: "Status",
                        dataIndex: "invoiceStatus",
                        key: "invoiceStatus",
                        width: 200,
                        render: (status: any) => {
                          const _status = statusMapper[status]?.seller;
                          return (
                            <div className="justify-start align-center">
                              <h6
                                className={`tb-status ms-1`}
                                style={{
                                  color: `${_status?.color}`,
                                  fontSize: "14px",
                                }}
                              >
                                {_status?.icon} {_status?.title || status}
                              </h6>
                            </div>
                          );
                        },
                      };
                    } else if (col.dataIndex === "threeWayMatch") {
                      return {
                        ...col,
                        hidden: false,
                      };
                    }
                    return {
                      ...col,
                    };
                  })}
                  dataSource={payableLines?.map((item: any) => ({
                    ...item,
                    key: item.invoiceNumber,
                  }))}
                  scroll={{ x: 1000 }}
                  bordered
                  size="middle"
                  pagination={false}
                  expandable={{
                    onExpand: handleExpand,
                    expandedRowKeys: Array.isArray(expandedRowKeys)
                      ? expandedRowKeys
                      : [],
                    expandedRowRender: (record: any) => (
                      <div>
                        {matchingLoad ? (
                          <>
                            <Flex
                              align="center"
                              gap="middle"
                              style={{ height: "200px" }}
                            >
                              <Spin size="small" className="m-auto" />
                            </Flex>
                          </>
                        ) : (
                          <div className="table-responsive poTable p-0 m-0">
                            <div
                              style={{
                                width: screenSize - 400 + "px",
                                minHeight: "200px",
                                padding: "0px",
                              }}
                              className="m-auto"
                            >
                              <p className="fw-bold mb-0">
                                {record?.threeWayMatch?.message}
                              </p>
                              <Table
                                className="customTable m-0 p-0 custom-innerTable"
                                columns={invoiceColumns}
                                dataSource={invoiceData}
                                scroll={{ x: 1000 }}
                                bordered
                                size="middle"
                                pagination={false}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ),
                  }}
                />
              </div>
            </div>

            {approvalRequired == "false" ? (
              <div className="table-responsive poTable">
                <div
                  style={{
                    width: screenSize - 400 + "px",
                    margin: "0px auto 15px auto",
                  }}
                >
                  <Form
                    form={form3}
                    component={false}
                    onFinish={(data) => {
                      let selectedCNValueTotal = 0;
                      const _selectedCNValues: any = [];
                      Object.keys(data).map((key) => {
                        if (key.includes("utilizedAmount")) {
                          selectedCNValueTotal += Number(data[key]);
                          _selectedCNValues.push({
                            noteNumber: Number(key.split("_")[1]),
                            utilizedAmount: Number(data[key]),
                          });
                        }
                      });

                      setSelectedCNValueUtilized(_selectedCNValues);
                      if (
                        selectedCNValueTotal >
                        payableLines.reduce(
                          (a: any, b: any) => a + Number(b?.payableAmount),
                          0
                        )
                      ) {
                        setCNError(
                          "Utilized Amount is greater than Payable Amount"
                        );
                      } else {
                        setCNError(null);
                      }
                      setSelectedCNValueTotal(selectedCNValueTotal);
                    }}
                  >
                    <Table
                      title={() => (
                        <div className="d-flex justify-content-between">
                          {CNError ? (
                            <strong style={{ color: "red" }}>{CNError}</strong>
                          ) : (
                            <strong>Select Credit Notes (if needed)</strong>
                          )}
                          {selectedCnKeys.length > 0 && (
                            <Button
                              color="primary"
                              className="m-0"
                              style={{ height: "30px" }}
                              onClick={() => {
                                form3.submit();
                              }}
                            >
                              Use
                            </Button>
                          )}
                        </div>
                      )}
                      footer={() => (
                        <div className="w-25" style={{ marginLeft: "auto" }}>
                          <div className="d-flex justify-content-between">
                            <strong>Payable Amount</strong>
                            <strong>
                              {formatCurrency(
                                payableLines.reduce(
                                  (a: any, b: any) =>
                                    a + Number(b?.payableAmount),
                                  0
                                ),
                                "INR"
                              )}
                            </strong>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <strong>Credit Note Amount</strong>
                            <strong>
                              {formatCurrency(selectedCNValueTotal, "INR")}
                            </strong>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <strong>Remaining Amount</strong>
                            <strong>
                              {formatCurrency(
                                payableLines.reduce(
                                  (a: any, b: any) =>
                                    a + Number(b?.payableAmount),
                                  0
                                ) - selectedCNValueTotal,
                                "INR"
                              )}
                            </strong>
                          </div>
                        </div>
                      )}
                      className="customTable"
                      rowSelection={cnRowSelection}
                      columns={cNColumns?.map((col: any) => {
                        if (!col.editable) {
                          return col;
                        }
                        return {
                          ...col,
                          onCell: (record: any) => ({
                            record,
                            inputType: "number",
                            dataIndex: col.dataIndex,
                            title: col.title,
                            editing: isCnEditing(record),
                          }),
                        };
                      })}
                      dataSource={CN?.map((field: any) => {
                        return {
                          ...field,
                          key: field.noteNumber,
                        };
                      })}
                      scroll={{ x: "max-content" }}
                      bordered
                      size="small"
                      pagination={false}
                      components={{
                        body: {
                          cell: EditCN,
                        },
                      }}
                    />
                  </Form>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="">
              <div className="tab-content mt-0 w-70 paddingLeft10 m-auto">
                <div className="tab-content mt-0">
                  <Col className=" w-90  flex-wrap  p-0">
                    <div className="w-100 p-1 pt-0 pb-0">
                      <div className="data-col p-0 pt-1 align-start">
                        <div className="data-label w-25">
                          Approval Required ?
                        </div>
                        <div className="data-value w-75">
                          <Form.Item
                            name="approvals"
                            label={undefined}
                            className="w-100 paddingLeft10 p-0 m-0"
                            rules={[
                              {
                                required: true,
                                message: "Please select approvals",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Approvals"
                              value={approvalRequired}
                              onChange={(e) => {
                                form2.setFieldValue("approvals", e);
                                setApprovalRequired(() => e);
                              }}
                            >
                              <Option value="true">Yes</Option>
                              <Option value="false">No</Option>
                            </Select>

                            {approvalRequired == "true" && (
                              <Popover
                                content="Click here to set required approvals"
                                title="Approval Required"
                              >
                                <span className="m-1">
                                  <a
                                    href="#"
                                    className="success m-0 p-0"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setApprovals((pre) => {
                                        return {
                                          ...pre,
                                          open: true,
                                        };
                                      });
                                    }}
                                  >
                                    <EditOutlined /> Set Approvals &nbsp;
                                    {approvals?.data?.length == 0 ? (
                                      <span className="mandatory">
                                        Please set approvals
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </a>
                                </span>
                              </Popover>
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 p-1 pt-0 pb-0">
                      <div className="data-col p-0 pt-1">
                        <div className="data-label w-25">Payment Date</div>
                        <div className="data-value w-75">
                          <Form.Item
                            name="paymentDate"
                            label={undefined}
                            className="w-100 paddingLeft10  p-0 m-0"
                            rules={[
                              {
                                required: true,
                                message: "Please select payment Date",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              placeholder="Payment Date"
                              maxDate={dayjs().add(1, "day")}
                              allowClear
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    {approvalRequired != "true" && (
                      <>
                        <div className="w-100 p-1 pt-0 pb-0">
                          <div className="data-col p-0 pt-1">
                            <div className="data-label w-25">Payment Mode</div>
                            <div className="data-value w-75">
                              <Form.Item
                                name="paymentMode"
                                label={undefined}
                                className="w-100 paddingLeft10  p-0 m-0"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select payment mode",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select Invoice Type"
                                  onChange={(e) => {
                                    setPaymentMode(e);
                                  }}
                                >
                                  <Option value="neft">
                                    National Electronic Fund Transfer (NEFT)
                                  </Option>
                                  <Option value="rtgs">
                                    Real Time Gross Settlement (RTGS)
                                  </Option>
                                  <Option value="imps">
                                    Immediate Payment Services (IMPS)
                                  </Option>
                                  <Option value="cheque">CHEQUE</Option>
                                  <Option value="upi">UPI</Option>
                                  <Option value="cash">CASH</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="w-100 p-1 pt-0 pb-0">
                          {["neft", "rtgs", "imps"].includes(paymentMode) ? (
                            <div className="data-col p-0 pt-1">
                              <div className="data-label w-25">UTR</div>
                              <div className="data-value w-75">
                                <Form.Item
                                  name="transactionNumber"
                                  label={undefined}
                                  className="w-100 paddingLeft10 p-0 m-0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter UTR",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="Enter UTR"
                                    className="w-100"
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ""
                                      )
                                    }
                                    parser={(value: any) =>
                                      value!.replace(/\$\s?|(,*)/g, "")
                                    }
                                    onKeyPress={(event) => {
                                      if (!/^[0-9]+$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          ) : ["upi"].includes(paymentMode) ? (
                            <div className="data-col p-0 pt-1">
                              <div className="data-label w-25">
                                Transaction ID
                              </div>
                              <div className="data-value w-75">
                                <Form.Item
                                  name="transactionNumber"
                                  label={undefined}
                                  className="w-100 paddingLeft10 p-0 m-0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter Transaction ID",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="Enter Transaction ID"
                                    className="w-100"
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ""
                                      )
                                    }
                                    parser={(value: any) =>
                                      value!.replace(/\$\s?|(,*)/g, "")
                                    }
                                    onKeyPress={(event) => {
                                      if (!/^[0-9]+$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          ) : ["cheque"].includes(paymentMode) ? (
                            <div className="data-col p-0 pt-1">
                              <div className="data-label w-25">Cheque #</div>
                              <div className="data-value w-75">
                                <Form.Item
                                  name="transactionNumber"
                                  label={undefined}
                                  className="w-100 paddingLeft10 p-0 m-0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter Cheque Number",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="Enter Cheque Number"
                                    className="w-100"
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ""
                                      )
                                    }
                                    parser={(value: any) =>
                                      value!.replace(/\$\s?|(,*)/g, "")
                                    }
                                    onKeyPress={(event) => {
                                      if (!/^[0-9]+$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                    <div className="w-100 p-1 pt-0 pb-0">
                      <div className="data-col p-0 pt-1">
                        <div className="data-label w-25">Remark</div>
                        <div className="data-value w-75">
                          <Form.Item
                            name="remarks"
                            label={undefined}
                            className="w-100 paddingLeft10  p-0 m-0"
                          >
                            <Input.TextArea
                              rows={3}
                              placeholder="Enter Remark"
                              onChange={(e) => {}}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </Form>
          <PaymentsApprovalDrawer
            open={approvals.open}
            siteId={null}
            onClose={() => {
              setApprovals((pre) => {
                return {
                  ...pre,
                  open: false,
                };
              });
            }}
            onSubmit={(data: any) => {
              setApprovals({
                open: false,
                data: data,
              });
            }}
            setApprovalSubmissionStatus={(status: boolean) => {}}
            approvalData={approvals.data} // Pass the current approval data
          />
        </Drawer>
      )}
    </>
  );
};

export default InvoicePayables;
