import React, { useState, useEffect, useMemo, FC, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Alert, Badge, Card } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  RSelect,
  UserAvatar,
  TooltipComponent,
} from "../../../components/Component";
import { Col } from "reactstrap";
import axios from "../../../axios";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import sites from "../../../api/master/sites";
import { Modal, ModalBody } from "reactstrap";
import DatePicker from "react-datepicker";
import { getDateStructured } from "../../../utils/Utils";
import FormInput from "../../form-components/InputComponent";
import FormDatePicker from "../../form-components/DatePicker";
import FormSelect from "../../form-components/SelectComponent";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import FormCheckbox from "../../form-components/CheckboxComponent";
import rolesApi from "../../../api/master/roles";
import FormTextArea from "../../form-components/TextAreaComponent";
import departments from "../../../api/master/departments";
import usersApis from "../../../api/master/users";
import FormatDate from "../DateFormater";
import { getStatesByCountry } from "../../../api/misc/dropDowns";
import moment from "moment";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
import dayjs from "dayjs";
interface Accessability {
  module: string;
  create: boolean;
  view: boolean;
  edit: boolean;
  cancle: boolean;
  approve: boolean;
  reject: boolean;
}
interface SelectOptions {
  label: string;
  value: string;
}
const UserViewDetails: FC = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let { userId } = useParams();
  const [data, setData] = useState<any>();
  const [modalGI, setGIModal] = useState(false);
  const [modalSS, setSSModal] = useState(false);
  const [modalTab, setModalTab] = useState("1");
  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);
  const [formData, setFormData]: any = useState({
    name: "",
    referenceID: "",
    email: "",
    phoneNumber: "",
    DOB: "",
    DOJ: "",
    bloodGroup: "",
    area: "",
    city: "",
    district: "",
    state: "",
    country: "",
    userOrganisation: "",
    department: "",
    role: "",
    reportingManagerRole: "",
    reportingManager: "",
    PIN: "",
    accessibility: [],
    siteAccessibility: "",
  });
  const methods = useForm({
    mode: "onChange",
  });

  const [selectedsiteAccessibility, setSelectedsiteAccessibility] =
    useState<any>();
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
  } = methods;
  const [accessebilityKeys, setaccessebilityKeys] = useState([]);
  const updatedAccessibilityOptions = [
    {
      label: "Sub Feature",
      value: "Sub Feature",
    },
    {
      label: "Create / Add",
      value: "ADD",
    },
    {
      label: "View",
      value: "VIEW",
    },
    {
      label: "Edit / Update",
      value: "EDIT",
    },
    {
      label: " Delete / Inactive",
      value: "CANCEL",
    },
    {
      label: "Approve / Reject",
      value: "APPROVE",
    },
  ];
  const readOnlyAddModules = ["Dashboard"];
  const readOnlyUpdateModules = ["Dashboard"];
  const readOnlyRejectModules = ["Dashboard", "GRN"];
  const readOnlyDeleteModules = [
    "Dashboard",
    "Purchase Request",
    "Quotation",
    "Purchase Order",
    "Invoice",
    "GRN",
    "Payments",
    "Credit Note",
  ];

  const [roleData, setRoleData] = useState<SelectOptions[]>([]);
  const [departmentData, setDepartmentData] = useState<SelectOptions[]>([]);
  const [organisationData, setOrganisationData] = useState<SelectOptions[]>([]);
  const [userOrganisationOptions, setUserOrganisationOptions] = useState<
    SelectOptions[]
  >([
    { label: "Select Organisation", value: "" },
    { label: "Organisation 1", value: "1" },
    { label: "Organisation 2", value: "2" },
  ]);
  const [workingSites, setWorkingSites] = useState<SelectOptions[]>([]);
  const [modalEditBlockName, setModalEditBlockName]: any = useState("");
  const [reportingManagerDropDownData, setReportingManagerDropDownData] =
    useState<SelectOptions[]>([]);
  const [showreporting, setShowreporting] = useState(false);
  const [allRolesData, setAllRolesData] = useState<any[]>([]);
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [originalData, setOriginalData] = useState<any>();
  const getCountries = async () => {
    try {
      const _countries = await usersApis.getCountrys();
      console.log(_countries);
      if (_countries.status) {
        if (_countries.data.length > 0) {
          const transformedData = _countries.data.map((item: any) => ({
            value: item.countryId.toString(),
            label: item.countryName,
          }));
          setCountries(transformedData);
          if (selectedCountry) {
            setValue("country", selectedCountry.value);
          }
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) {
          return;
        }
        setValue("state", state.value);
      }
    } catch (error) {}
  };

  const getDistricts = async (stateID: any, districtsID?: any) => {
    try {
      const districts = await usersApis.getDistrictsByState(stateID);
      // console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
          if (districtsID) {
            setValue("district", districtsID);
          } else {
            setValue("district", districts.data[0].districtId.toString());
          }
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const fetchWorkingSites = async () => {
    try {
      const { data, status, message } = await sites.fetchSites();
      if (status) {
        setWorkingSites(
          data.map((item: any) => ({
            label: item.name,
            value: item.siteID,
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };
  const onStateChange = (e: any) => {
    getDistricts(e);
  };

  const fetchUsersByRoleId = async (id: any) => {
    const { data, status, message } = await usersApis.fetchUsersByRoleId(id);
    if (status && data) {
      let options = data.map((obj: any) => ({
        label: obj.name,
        value: obj.userID,
      }));
      console.log("options", options);
      setReportingManagerDropDownData(options);
      // setValue("reportingManager",data.map((obj: any) => obj.reportingManager));
    } else {
      setAlertInfo({
        type: "error",
        message:
          "USER CANNOT BE CREATED AS THERE ARE No ACTIVE USER FOR THE SELECTED REPOTING MANAGER ROLE",
        errors: [],
      });
    }
  };

  const selectedCountry = watch("country");
  const selectedState = watch("state");
  const reportingManagerWatch = watch("reportingManager");

  const onInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (data: any) => {
    console.log("data", data);
    console.log("departmentData", departmentData);
    if (
      departmentData.find((obj: any) => obj.value == originalData.department)
    ) {
      data.department = departmentData.find(
        (obj: any) => obj.value == originalData.department
      )?.value;
      data.department = parseInt(data.department);
    } else {
      data.department = parseInt(originalData.department);
    }
    console.log("reportingManagerRole", reportingManagerDropDownData);
    if (
      reportingManagerDropDownData.find(
        (obj: any) => obj.value == originalData.reportingManagerRole
      )
    ) {
      data.reportingManagerRole = reportingManagerDropDownData.find(
        (obj: any) => obj.value == originalData.reportingManagerRole
      )?.value;
      data.reportingManagerRole = parseInt(data.reportingManagerRole);
    } else {
      data.reportingManagerRole = parseInt(originalData.reportingManagerRole);
    }

    const accessebility: {
      module: string;
      add: boolean;
      view: boolean;
      edit: boolean;
      cancel: boolean;
      approve: boolean;
      reject: boolean;
    }[] = [];
    accessebilityKeys.forEach((key: SelectOptions) => {
      accessebility.push({
        module: key.value,
        add: data[`${key.value}_add`],
        view: data[`${key.value}_view`],
        edit: data[`${key.value}_edit`],
        cancel: data[`${key.value}_cancel`],
        approve: data[`${key.value}_approve`],
        reject: data[`${key.value}_reject`],
      });
      delete data[`${key.value}_add`];
      delete data[`${key.value}_view`];
      delete data[`${key.value}_edit`];
      delete data[`${key.value}_cancel`];
      delete data[`${key.value}_approve`];
      delete data[`${key.value}_reject`];
    });
    data.accessebility = accessebility;
    console.log("111", data);
    data.DOB = moment(new Date(data.DOB)).format("YYYY-MM-DD");
    data.DOJ = moment(new Date(data.DOJ)).format("YYYY-MM-DD");
    console.log("userSybmitDat", data);
    // return;
    try {
      const { status, message } = await usersApis.updateBuyerById(userId, data);
      if (status) {
        Swal.fire("Updated!", "User has been updated", "success");
        getViewDetails(userId);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {}
  };

  const fetchModuleConfig = async () => {
    try {
      const res = await usersApis.getModuleConfig();
      const data = res.data;
      setaccessebilityKeys(
        data.map((obj: any) => ({
          label: obj.moduleName,
          value: obj.moduleID.toString(),
        }))
      );
    } catch (err) {
      throw err;
    }
  };
  const fetchRoles = async () => {
    try {
      const { data, status, message } = await usersApis.fetchRolesData();
      if (status) {
        setRoleData(
          data.map((obj: any) => ({
            label: obj.roleName,
            value: obj.roleID.toString(),
          }))
        );
        setAllRolesData(data);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  const fetchDept = async () => {
    try {
      const { data, status, message } = await usersApis.getDeptData();
      if (status) {
        setDepartmentData(
          data.map((obj: any) => ({
            label: obj.deptName,
            value: obj.deptID.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };

  const fetchOrgs = async () => {
    const contractorData = await usersApis.fetchContractor();
    const consultantData = await usersApis.fetchConsultant();
    console.log("usersApis", contractorData);
    setOrganisationData(() => {
      const newContractorData = contractorData["data"].map((obj: any) => ({
        label: obj.companyName,
        value: obj.contractorID.toString(),
      }));
      const newConsultantData = consultantData["data"].map((obj: any) => ({
        label: obj.companyName,
        value: obj.consultantID.toString(),
      }));

      return [...newContractorData, ...newConsultantData];
    });
  };
  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  const handleMultiSelectChange = (selectedOptions: any, name: any) => {
    const value = selectedOptions.map((option: any) => ({
      siteID: option.value,
      siteName: option.label,
    }));
    setValue("siteAccessibility", value);
  };

  const fetchfetchReportingHierarchy = async (role: any) => {
    const { data, status, message } = await rolesApi.fetchReportingHierarchy();
    if (status && data) {
      let Data = data.find(
        (obj: any) => obj.reportingRoleID == role && obj.leader == false
      ).reportingToRoleID;
      console.log("Data:::JJJJJJ", Data);
      fetchUsersByRoleId(Data);
    }
  };

  const getViewDetails = async (id: any) => {
    try {
      const { data, status, message } = await usersApis.fetchBuyerById(id);
      console.log("status", data);
      if (status) {
        console.log("status", data);
        const newData = data;
        setData(newData); // Update data with fetched response
        setOriginalData(newData);
        setSelectedsiteAccessibility(
          newData?.siteAccessibility?.map((siteAccessibilitys: any) =>
            Number(siteAccessibilitys.siteID)
          )
        );
        setFormData({
          name: newData.name,
          referenceID: newData.referenceID,
          workingSite: newData.workingSite,
          email: newData.email,
          phoneNumber: newData.phoneNumber,
          PIN: newData.PIN,
          siteAccessibility: newData.siteAccessibility,
          //   DOB: newData.DOB,
          //   DOJ: newData.DOJ,
          bloodGroup: newData.bloodGroup,
          area: newData.area,
          district: newData.district,
          state: newData.state,
          country: newData.country,
          userOrganisation: newData.userOrganisation,
          department: newData.department,
          role: newData.role,
          reportingManagerRole: newData.reportingManagerRole,
          reportingManager: newData.reportingManager,
          accessibility: [],
        });
        // console.log("accessibility", newData.accessibility);
        data?.accessibility?.forEach((moduleObj: any) => {
          console.log("accessibility", moduleObj);
          let moduleName = moduleObj?.module?.toString();
          setValue(`${moduleName}_add`, moduleObj.add);
          setValue(`${moduleName}_view`, moduleObj.view);
          setValue(`${moduleName}_edit`, moduleObj.edit);
          setValue(`${moduleName}_cancel`, moduleObj.cancel);
          setValue(`${moduleName}_approve`, moduleObj.approve);
          setValue(`${moduleName}_reject`, moduleObj.reject);
        });

        fetchfetchReportingHierarchy(data.role);

        setValue("DOB", dayjs(data.DOB));
        setValue("DOJ", dayjs(data.DOJ));
        setValue("name", data.name);
        setValue("referenceID", data.referenceID);
        setValue(
          "siteAccessibility",
          data.siteAccessibility.map((item: any) => item.siteID)
        );
        setValue("email", data.email);
        setValue("phoneNumber", data.phoneNumber);
        setValue("bloodGroup", data.bloodGroup);
        setValue("area", data.area);
        setValue("district", data.district.districtId);
        setValue("PIN", data.PIN);
        setValue("state", data.state.stateId);
        setValue("country", data.country.countryId);
        setValue("userOrganisation", data.userOrganisation);
        setValue("department", data.departmentName);
        setValue("role", data.role);
        setValue("reportingManagerRole", data.reportingManagerRoleName);
        setValue("reportingManager", data.reportingManager);
        console.log("data.reportingManager;0000", data.reportingManager);
        setGIModal(false);
        fetchStatesByCountry(newData.country.countryId, newData.state.stateId);
        getDistricts(newData.state.stateId, newData.district.districtId);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (err: any) {
      console.log("loggg", err);
      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchDept();
    fetchOrgs();
    fetchWorkingSites();
    viewChange();
    getCountries();
    fetchModuleConfig();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    const id = userId;
    console.log("id", id);
    if (id !== undefined || null || "") {
      getViewDetails(id);
    }
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [userId]);

  const openEditModal = (modalEditBlockName: any) => {
    setGIModal(true);
    setModalEditBlockName(modalEditBlockName);
  };

  const fetchReportingManagerRole = async (selectRole: any) => {
    let role: any = allRolesData.find((obj: any) => obj.roleID == selectRole);
    setValue("department", role.deptName);
    // console.log("selectRole", selectRole);
    try {
      const { data, status, message } =
        await rolesApi.fetchReportingHierarchy();
      if (status) {
        console.log("data000111", data);
        // let findData = data.find((obj: any) => obj.reportingRoleID == selectRole && obj.leader == true);
        // console.log("findData",findData),
        // console.lo
        if (
          data.find(
            (obj: any) =>
              obj.reportingRoleID == selectRole && obj.leader == true
          )
        ) {
          setShowreporting(false);
        } else if (
          data.find(
            (obj: any) =>
              obj.reportingRoleID == selectRole && obj.leader == false
          )
        ) {
          let Data = data.find(
            (obj: any) =>
              obj.reportingRoleID == selectRole && obj.leader == false
          ).reportingToRoleID;
          console.log("Data6666", Data, allRolesData);
          setShowreporting(true);
          if (allRolesData.find((obj: any) => obj.roleID == Data)) {
            console.log("75");
            let value = allRolesData.find(
              (obj: any) => obj.roleID == Data
            )?.roleName;
            console.log("value", value);
            setValue("reportingManagerRole", value);
            fetchUsersByRoleId(Data);
          }
        }
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  return (
    <React.Fragment>
      <Head title="Site Details - Regular"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    User Details -{" "}
                    <strong className="text-primary small">
                      {data["name"]}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      <li>
                        Created At:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  {/* <Button
                                        outline
                                        className="bg-white d-none d-sm-inline-flex"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => openEditModal()}
                                    >
                                        <Icon name="edit-alt-fill"></Icon>
                                        <span>Edit</span>
                                    </Button> */}
                  <Link to={`${process.env.PUBLIC_URL}/users`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Row className="gy-5">
                <Col
                  lg="5"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Personal Information</BlockTitle>
                        <p>
                          Basic info, like your name and address, that you use
                          on Nio Platform.
                        </p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("PersonalInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">User ID</div>
                          <div className="data-value">{data.referenceID}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">User Name</div>
                          <div className="data-value">{data.name}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Site Accessibility</div>
                          <div className="data-value">
                            {data.siteAccessibility
                              .map(
                                (siteAccessibility: any) =>
                                  siteAccessibility.siteName
                              )
                              .join(", ")}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Email Address</div>
                          <div className="data-value">{data.email}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Phone Number</div>
                          <div className="data-value">{data.phoneNumber}</div>
                        </div>
                      </li>

                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">country</div>
                          <div className="data-value">
                            {data.country.countryName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">State</div>
                          <div className="data-value">
                            {data.state.stateName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">District</div>
                          <div className="data-value">
                            {data.district.districtName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Area</div>
                          <div className="data-value">{data.area}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Pin Code</div>
                          <div className="data-value">{data.PIN}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Date of Birth</div>
                          <div className="data-value">
                            {FormatDate(data.DOB)}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Date of Joining</div>
                          <div className="data-value">
                            {FormatDate(data.DOJ)}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Blood Group</div>
                          <div className="data-value">{data.bloodGroup}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Heirarchy Setup </BlockTitle>
                        <p>Here is the current status of heirarchy setup.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("HeirarchySetup")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      {/* <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">User Organisation</div>
                          <div className="data-value">
                            {data.userOrganisation}
                          </div>
                        </div>
                      </li> */}
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Department</div>
                          <div className="data-value">
                            {
                              departmentData.find(
                                (item) => item.value == data.department
                              )?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Role</div>
                          <div className="data-value">
                            {
                              roleData.find((item) => item.value == data.role)
                                ?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Reporting Manager Role
                          </div>
                          <div className="data-value">
                            {data.reportingManagerRoleName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Reporting Manager</div>
                          <div className="data-value">
                            {data.reportingManagerName}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>

                <Col
                  lg="7"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Accessability </BlockTitle>
                        <p>User Accessability Details etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("Accessability")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <table className="table table-hover" id="accessesbility">
                      <thead>
                        <tr>
                          {updatedAccessibilityOptions.map((item: any) => {
                            return <th key={item.value}>{item.label}</th>;
                          })}
                        </tr>
                      </thead>
                      {accessebilityKeys && (
                        <tbody>
                          {accessebilityKeys?.map((item: any) => {
                            let row = data?.accessibility?.find(
                              (element: any) => element.module == item.value
                            );
                            return (
                              <tr key={item.value}>
                                <td>{item.label}</td>
                                <td>
                                  {row?.add ? (
                                    <Icon
                                      name="check-circle-fill"
                                      className="text-success"
                                    />
                                  ) : (
                                    <Icon
                                      name="cross-circle-fill"
                                      className="text-danger"
                                    />
                                  )}
                                </td>
                                <td>
                                  {row?.view ? (
                                    <Icon
                                      name="check-circle-fill"
                                      className="text-success"
                                    />
                                  ) : (
                                    <Icon
                                      name="cross-circle-fill"
                                      className="text-danger"
                                    />
                                  )}
                                </td>
                                <td>
                                  {row?.edit ? (
                                    <Icon
                                      name="check-circle-fill"
                                      className="text-success"
                                    />
                                  ) : (
                                    <Icon
                                      name="cross-circle-fill"
                                      className="text-danger"
                                    />
                                  )}
                                </td>
                                <td>
                                  {row?.cancel ? (
                                    <Icon
                                      name="check-circle-fill"
                                      className="text-success"
                                    />
                                  ) : (
                                    <Icon
                                      name="cross-circle-fill"
                                      className="text-danger"
                                    />
                                  )}
                                </td>
                                <td>
                                  {row?.approve ? (
                                    <Icon
                                      name="check-circle-fill"
                                      className="text-success"
                                    />
                                  ) : (
                                    <Icon
                                      name="cross-circle-fill"
                                      className="text-danger"
                                    />
                                  )}
                                </td>
                                {/* <td>
                                  {row?.reject ? (
                                    <Icon
                                      name="check-circle-fill"
                                      className="text-success"
                                    />
                                  ) : (
                                    <Icon
                                      name="cross-circle-fill"
                                      className="text-danger"
                                    />
                                  )}
                                </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                  </Card>
                </Col>
              </Row>
            </Block>

            <Modal
              isOpen={modalGI}
              className="modal-dialog-centered"
              size="xl"
              toggle={() => setGIModal(false)}
              backdrop="static"
            >
              <a
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setGIModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              {modalEditBlockName === "PersonalInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Personal Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Row className="gy-4">
                            <Col md="4">
                              <FormInput
                                name="referenceID"
                                label="User ID"
                                required={true}
                                disabled
                                type="text"
                                maxLength={10}
                                placeholder="Enter User ID"
                              />
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="name"
                                label="User Name"
                                required={true}
                                type="text"
                                maxLength={50}
                                placeholder="Enter User Name"
                              />
                            </Col>

                            <Col md="4">
                              <FormMultiSelect
                                name="siteAccessibility"
                                label="Site Accessibility"
                                required={true}
                                options={workingSites}
                                placeholder="Select Site Accessibility"
                              />
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="email"
                                label="Email Address"
                                type="email"
                                required={true}
                                maxLength={50}
                                placeholder="Enter Email Address"
                              />
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="phoneNumber"
                                label="Phone Number"
                                type="text"
                                required={true}
                                maxLength={10}
                                pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
                                placeholder="Enter Phone Number"
                              />
                            </Col>
                            <Col md="4">
                              <FormSelect
                                name="country"
                                label="Country"
                                options={countries}
                                required={true}
                                placeholder="Enter Country"
                                defaultValue={countries.find(
                                  (option: any) =>
                                    option.value == formData.country.countryId
                                )}
                              />
                            </Col>
                            <Col md="4">
                              <FormSelect
                                name="state"
                                label="State"
                                options={states}
                                placeholder="Select State"
                                required={true}
                                defaultValue={states.find(
                                  (option: any) =>
                                    option.value == formData.state.stateId
                                )}
                                onChange={(ev: any) => onStateChange(ev)}
                              />
                            </Col>
                            <Col md="4">
                              <FormSelect
                                name="district"
                                label="District"
                                options={districtOptions}
                                required={true}
                                placeholder="Enter District"
                                defaultValue={districtOptions.find(
                                  (option: any) =>
                                    option.value == formData.district.districtId
                                )}
                              />
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="area"
                                label="Area"
                                placeholder="Enter Area"
                                required={true}
                              />
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="PIN"
                                label="PIN"
                                required={true}
                                type="number"
                                maxLength={6}
                                placeholder="Enter PIN"
                              />
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormDatePicker
                                  name="DOB"
                                  label="Date of Birth"
                                  maxDate={new Date()}
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormDatePicker
                                  name="DOJ"
                                  label="Date of Joining"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="bloodGroup"
                                label="Blood Group"
                                type="text"
                                placeholder="Enter Blood Group"
                              />
                            </Col>
                          </Row>
                          <Row className="gy-4 mt-2">
                            <Col md="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}
              {modalEditBlockName === "HeirarchySetup" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Heirarchy Setup </h5>
                    </div>
                    {alertInfo.message && (
                      <div className="mb-3 mt-1">
                        <Alert
                          color={
                            alertInfo.type === "error" ? "danger" : "success"
                          }
                          className="alert-icon"
                        >
                          <strong>{alertInfo.message}</strong>
                          <Icon
                            name={
                              alertInfo.type === "error"
                                ? "alert-circle"
                                : "check-circle"
                            }
                          />
                          {alertInfo.errors.length > 0 ? (
                            <ul>
                              {alertInfo.errors.map((err: any, index: any) => (
                                <li key={index}>{err}</li>
                              ))}
                            </ul>
                          ) : (
                            ""
                          )}
                        </Alert>
                      </div>
                    )}
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                          <Row className={undefined} >
                            <Col lg="4">
                              <FormSelect
                                name="role"
                                label="Role"
                                options={roleData}
                                required={true}
                                placeholder="Select Role"
                                onChange={(e) => {
                                  console.log("selectRole111", e);
                                  fetchReportingManagerRole(e);
                                }}
                              />
                            </Col>
                            <Col lg="4">
                              <FormInput
                                name="department"
                                label="Department"
                                required={true}
                                placeholder="Select Department"
                              />
                            </Col>
                            {(showreporting || data.reportingManagerRole) && (
                              <Col lg="4">
                                <FormInput
                                  name="reportingManagerRole"
                                  label="Reporting Manager Role"
                                  required={true}
                                  placeholder="Select"
                                />
                              </Col>
                            )}
                            {(showreporting || data.reportingManager) && (
                              <Col lg="4">
                                <FormSelect
                                  name="reportingManager"
                                  label="Reporting Manager"
                                  options={reportingManagerDropDownData}
                                  required={true}
                                  placeholder="Select"
                                />
                              </Col>
                            )}
                          
                            <Col lg="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}
              {modalEditBlockName === "Accessability" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Accessability </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                          <Row className="gy-4">
                            <Col size="12">
                              <table
                                className="table table-hover"
                                id="accessesbility"
                              >
                                <thead>
                                  <tr>
                                    {updatedAccessibilityOptions.map(
                                      (item: any) => {
                                        return (
                                          <th key={item.value}>{item.label}</th>
                                        );
                                      }
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {accessebilityKeys.map((item: any) => {
                                    return (
                                      <tr>
                                        <td>{item.label}</td>
                                        <td>
                                          <FormCheckbox
                                            disabled={readOnlyAddModules.includes(
                                              item.label
                                            )}
                                            name={`${item.value}_add`}
                                            optionLabel=""
                                            noGap={true}
                                            size={"small"}
                                          />
                                        </td>
                                        <td>
                                          <FormCheckbox
                                            name={`${item.value}_view`}
                                            optionLabel=""
                                            noGap={true}
                                            size={"small"}
                                          />
                                        </td>
                                        <td>
                                          <FormCheckbox
                                            disabled={readOnlyUpdateModules.includes(
                                              item.label
                                            )}
                                            name={`${item.value}_edit`}
                                            optionLabel=""
                                            noGap={true}
                                            size={"small"}
                                          />
                                        </td>
                                        <td>
                                          <FormCheckbox
                                            disabled={readOnlyDeleteModules.includes(
                                              item.label
                                            )}
                                            name={`${item.value}_cancel`}
                                            optionLabel=""
                                            noGap={true}
                                            size={"small"}
                                          />
                                        </td>
                                        <td>
                                          <FormCheckbox
                                            disabled={readOnlyRejectModules.includes(
                                              item.label
                                            )}
                                            name={`${item.value}_approve`}
                                            optionLabel=""
                                            noGap={true}
                                            size={"small"}
                                          />
                                        </td>
                                        {/* <td>
                                          <FormCheckbox
                                            name={`${item.value}_reject`}
                                            optionLabel=""
                                            noGap={true}
                                            size={"small"}
                                          />
                                        </td> */}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                          <Row className={undefined}>
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default UserViewDetails;
